<div class="h-100">
	<div class="row mb-2" *ngIf="siteId">
		<div class="col-2 d-flex align-items-center">
			<ng-select
				[(ngModel)]="tableFilter"
				[items]="tableFilterList"
				name="tags_filter"
				bindValue="id"
				bindLabel="name"
				[clearable]="false"
				(ngModelChange)="onFilterChange()"
				[ngStyle]="{'width': '170px'}"
			></ng-select>
		</div>
	</div>

	<details open *ngIf="!siteId">
		<summary>{{'g.filter_box' | translate}}</summary>
			<!-- filters -->
		<div class="filter-box white-field col-12">
			<div class="custom-row">
				<!-- status -->
				<div class="filter">
					<label for="warning">{{'warnings.warning_status' | translate}}</label>
					<ng-select
						[(ngModel)]="filter.status"
						[items]="tableFilterList"
						name="tags_filter"
						bindValue="id"
						bindLabel="name"
						[clearable]="false"
						[ngStyle]="{'width': '300px'}"
					></ng-select>
				</div>

				<!-- Warning Type -->
				<div class="filter">
					<label for="warning">{{'g.warning' | translate}}</label>
					<ng-select
						[(ngModel)]="filter.warnings"
						[items]="warningsList"
						name="tags_filter"
						bindValue="key"
						bindLabel="value"
						[clearable]="false"
						[multiple]="true"
						[ngStyle]="{'width': '300px'}"
					></ng-select>
				</div>

				<!-- Trigger Time (Date Range) -->
				<div class="filter">
					<label for="startDateFilter">{{'g.start_date' | translate}}</label>
					<div class="input-group p-0 mr-3">
						<input class="form-control" [ngClass]="{'text-danger': invalidDateRange}" placeholder="mm/dd/yyyy" name="startDateFilter" [(ngModel)]="filter.fromDate" (ngModelChange)="changeFilterDate()" bsDatepicker #startDateFilter="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
						<div class="input-group-append pointer" (click)="startDateFilter.toggle()">
							<span class="input-group-text"><i class="fa fa-calendar"></i></span>
						</div>
					</div>
				</div>
				<div class="filter">
					<label for="endDateFilter">{{'g.end_date' | translate}}</label>
					<div class="input-group p-0 mr-3">
						<input class="form-control" placeholder="mm/dd/yyyy" name="endDateFilter" [(ngModel)]="filter.toDate" (ngModelChange)="changeFilterDate()" bsDatepicker #endDateFilter="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
						<div class="input-group-append pointer" (click)="endDateFilter.toggle()">
							<span class="input-group-text"><i class="fa fa-calendar"></i></span>
						</div>
					</div>
				</div>
			</div>

			<div class="custom-row">
				<!-- Customer Name -->
				<div class="filter">
					<label for="warning">{{'g.customers' | translate}}</label>
					<ng-select
						[(ngModel)]="filter.customers"
						[items]="customerList"
						name="tags_filter"
						bindValue="key"
						bindLabel="value"
						[clearable]="false"
						[multiple]="true"
						[ngStyle]="{'width': '300px'}"
						(ngModelChange)="selectCustomer()"
					></ng-select>
				</div>
			</div>

			<div class="custom-row align-items-end">
				<!-- Device Serial Number -->
				<div class="filter">
					<label for="warning">{{'g.devices' | translate}}</label>
					<ng-select
						*ngIf="filter.customers"
						[(ngModel)]="selectedDevice"
						[items]="customerDevicesList"
						name="tags_filter"
						bindValue="key"
						bindLabel="value"
						[clearable]="false"
						[multiple]="true"
						[ngStyle]="{'width': '300px'}"
						(change)="addDevice()"
					></ng-select>
					<input class="form-control" *ngIf="!filter.customers" type="text" [(ngModel)]="selectedDevice" >
				</div>

				<div class="filter" *ngIf="!filter.customers">
					<button class="btn btn-primary" (click)="addDevice()">{{"g.add" | translate}}</button>
				</div>
			</div>
			<label class="mt-3" for="warning">{{'g.devices_list' | translate}}</label>
			<ul class="d-flex flex-wrap device-list">
				<li *ngFor="let device of this.filter.devices">{{device.value}}</li>
			</ul>
			 <div class="custom-row align-items-end">
				<div class="filter">
					<button class="btn btn-primary" (click)="fillWarningTable()">{{"g.search" | translate}}</button>
				</div>
			</div>
		</div>
	</details>

	<div class="row mb-2">
		<div>
			<ng-container *ngIf="tableFilter == 'period'">
				<div class="date-range main-black p-4 border-radius-bottom">
					<div class="input-group p-0 mr-3" *ngIf="showDateRange != 'to'">
						<div class="input-group-prepend">
							<span class="input-group-text">{{'g.start_date' | translate}}</span>
						</div>
						<input class="form-control" [ngClass]="{'text-danger': invalidDateRange}" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="fromDate" (ngModelChange)="datesChanged()" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
						<div class="input-group-append pointer" (click)="startDB.toggle()">
							<span class="input-group-text"><i class="fa fa-calendar"></i></span>
						</div>
					</div>

					<div class="input-group p-0 mr-3" *ngIf="showDateRange != 'from'">
						<div class="input-group-prepend">
							<span class="input-group-text">{{'g.end_date' | translate}}</span>
						</div>
						<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="toDate" (ngModelChange)="datesChanged()" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
						<div class="input-group-append pointer" (click)="endDB.toggle()">
							<span class="input-group-text"><i class="fa fa-calendar"></i></span>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="white-box p-4" *ngIf="tableData.length !== 0">
		<sct-custom-table
			*ngIf="tableData.length"
			[columnsConfig]="columnConfig"
			[data]="tableData"
			[config]="tableConfig"
		></sct-custom-table>
	</div>
	<div class="white-box p-4" *ngIf="tableData.length == 0 && ( siteId || doneFilter )">{{'g.no_data_to_show' | translate}}</div>
</div>

<app-modal
	#infoDialog
	[title]="infoHeader"
	[hasCloseButton]= "false"
	[hasConfirmButton]="false"
	size="xl"
>
	<div class="header">
		<p><span>{{"warnings.customer_name" | translate}}:</span><span>{{" " + triggeredQVValues.customer_name}}</span></p>
		<p><span>{{"warnings.site_name" | translate}}:</span><span>{{" " + triggeredQVValues.site_name}}</span></p>
		<p><span>{{"warnings.device_sn" | translate}}:</span><span>{{" " + triggeredQVValues.serial_number}}</span></p>
		<p><span>{{"warnings.warning" | translate}}:</span><span>{{" " + triggeredQVValues.warning_text}}</span></p>
	</div>
	<table>
		<tr>
			<th>{{"warnings.field_name" | translate}}</th>
			<th>{{"warnings.trigger_value" | translate}}</th>
			<th>{{"warnings.clear_value" | translate}}</th>
		</tr>
		<tr *ngFor="let item of triggeredQVValues['main']">
			<td>{{("warnings." + item.field_name) | translate}}</td>
			<td>{{item.trigger_value != null ? item.trigger_value : '-'}}</td>
			<td>{{item.clear_value != null ? item.clear_value : '-'}}</td>
		</tr>
		<tr *ngIf="!warningJustHasLiveEvents.includes(triggeredQVValues.warning)">
			<th colspan="3" *ngIf="triggeredQVValues['live_event']?.length" class="white-field">{{"warnings.live_event" | translate}}</th>
		</tr>
		<tr *ngFor="let item of triggeredQVValues['live_event']">
			<td>{{("warnings." + item.field_name) | translate}}</td>
			<td>{{item.trigger_value != null ? item.trigger_value : '-'}}</td>
			<td>{{item.clear_value != null ? item.clear_value : '-'}}</td>
		</tr>
			<tr>
			<th colspan="3" *ngIf="triggeredQVValues['live_rt']?.length" class="white-field">{{"warnings.live_rt" | translate}}</th>
		</tr>
		<tr *ngFor="let item of triggeredQVValues['live_rt']">
			<td>{{("warnings_rt." + item.field_name) | translate}}</td>
			<td>{{item.trigger_value != null ? item.trigger_value : '-'}}</td>
			<td>{{item.clear_value != null ? item.clear_value : '-'}}</td>
		</tr>
	</table>
	<p class="text-left" *ngIf="noQVData">
		{{'g.no_data_to_show' | translate}}
	</p>
</app-modal>



