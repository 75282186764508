<div class="sidenav text-white" *ngIf="leftPanelService.getVisibility()">
	<div class="d-flex justify-content-center side-menu-toggle" *ngIf="visibleSideMenu" [ngClass]="{'toggled': !sideMenuHidden}">
		<div class="align-self-center" (click)="toggleSideMenu()">
			<i class="fa pointer" [ngClass]="{'fa-chevron-right': sideMenuHidden, 'fa-chevron-left': !sideMenuHidden}"></i>
		</div>
	</div>
	<div class="container d-flex justify-content-center mt-4" *ngIf="!user.force_password_change">
		<div class="align-self-center">
			<div class="col left-panel-item home" routerLink="/">
				<fa-icon icon="home" size="sm" *ngIf="!hideHomeIcon"></fa-icon>
			</div>
			<div class="col left-panel-item" *ngIf="userHasAdminLogsTab()" appDropdown>
				<fa-icon icon="th-large" size="sm"></fa-icon>
				<ul class="dropdown-menu main-menu">
					<li class="nav-item dropdown dropdown-submenu p-0" appDropdown *ngIf="userHasAdminTab()">
						<a class="nav-link">{{'nav.admin_menu' | translate}} <i class="fa fa-caret-right float-right"></i></a>
						<ul class="dropdown-menu p-0">
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('search_users')">
								<a class="nav-link" routerLink="/admin-panel/users">{{'g.users' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('customer_management') && user.isSCT">
								<a class="nav-link" routerLink="/customers">{{'g.customers' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('enterprise_management')">
								<a class="nav-link" routerLink="/enterprise">{{'ent.enterprise' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('registration_requests')">
								<a class="nav-link" routerLink="/admin-panel/validate-registration-request">{{'access_func.registration_requests' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('show_warnings')">
								<a class="nav-link" routerLink="/admin-panel/time-lost-report">{{'nav.admin.time_lost_report' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="user.is_admin">
								<a class="nav-link" routerLink="/admin-panel/audit">{{'nav.admin.audit' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('sched_functions_control')">
								<a class="nav-link" routerLink="/admin-panel/admin-actions">{{'nav.admin_actions' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="this.usersService.hasAccessFunction('official_reporting') || this.usersService.hasAccessFunction('site_management')">
								<a class="nav-link" routerLink="/smart-rebates-sites">{{'nav.smart_rebates_sites' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('upload_fw_files')">
								<a class="nav-link" routerLink="/admin-panel/upload-fw-files">{{'nav.upload_fw_files' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="user.is_admin">
								<a class="nav-link" routerLink="/admin-panel/iccid-changes-report">{{'nav.iccid_changes_report'| translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('super_admin')">
								<a class="nav-link" routerLink="/admin-panel/sim-management">{{'sim.sim_management' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="!isProduction && !isStaging">
								<a class="nav-link" routerLink="/admin-panel/data-generate">{{'Generate Fake Data' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="user.is_admin">
								<a class="nav-link" routerLink="/admin-panel/debug-logs">{{'Debug Logs' | translate}}</a>
							</li>
						</ul>
					</li>
					<li class="nav-item dropdown dropdown-submenu p-0" appDropdown *ngIf="usersService.hasAccessPermission(null, 'noc')">
						<a class="nav-link">{{'nav.noc_tools' | translate}} <i class="fa fa-caret-right float-right"></i></a>
						<ul class="dropdown-menu p-0">
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/admin-panel/incorrect-event">{{'nav.get_incorrect_events' | translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/management/disable_external_rtc">{{'nav.disable_external_rtc' | translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/management/disable-xtra-file">{{'nav.disable_xtra_file' | translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/noc-tools/suggested-installation-date-report">{{'suggested_installation_date_report.title' | translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/admin-panel/connectivity-verification-log/0">{{'connectivity_verification.title' | translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/admin-panel/monitoring-devices">{{'nav.noc_monitoring' |translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/admin-panel/disconnected-device">{{'nav.disconnected_devices' | translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/admin-panel/sim-usage-management">{{'nav.sim_usage_management' | translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/admin-panel/edit-devices-owners">{{'devices.edit_device_owners' | translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="admin-panel/device-queue-changes">{{'nav.device_queue_changes' | translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="noc-tools/search-device-events-report">{{'nav.search_device_events' | translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/noc-tools/defective-devices">{{'nav.defective_devices_page' |translate}}</a>
							</li>
						</ul>
					</li>
					<li class="nav-item dropdown dropdown-submenu p-0" appDropdown *ngIf="userHasDeviceTab()">
						<a class="nav-link">{{'nav.device_management' | translate}} <i class="fa fa-caret-right float-right"></i></a>
						<ul class="dropdown-menu p-0">
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('site_management')">
								<a class="nav-link" routerLink="/management/firmware-update">{{'settings.push_firmware' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('add_device')">
								<a class="nav-link" routerLink="/admin-panel/new-device">{{ 'g.add_device' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="user.specialSitesAccess && usersService.hasAccessFunction('device_management')">
								<a class="nav-link" routerLink="/admin-panel/cm-devices">{{ 'nav.cm_devices' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('device_management')">
								<a class="nav-link" routerLink="/management/blocked-fw-reports">{{ 'nav.blocked_fw_reports' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('device_management')">
								<a class="nav-link" routerLink="/management/device-management-requests">{{ 'nav.manage_device_requests' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessPermission(null, 'noc')">
								<a class="nav-link" routerLink="/admin-panel/warnings">{{'nav.warnings' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('reporting_module')">
								<a class="nav-link" routerLink="/admin-panel/device-report">{{'nav.devices_report' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('device_management')">
								<a class="nav-link" routerLink="/admin-panel/device-location-mismatch-report">{{'nav.devices_location_mismatch' | translate}}</a>
							</li>
						</ul>
					</li>
					<li class="nav-item dropdown dropdown-submenu p-0" appDropdown *ngIf="userHasLogsTab()">
						<a class="nav-link">{{'nav.admin.logs' | translate}} <i class="fa fa-caret-right float-right"></i></a>
						<ul class="dropdown-menu p-0">
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('access_error_logs')">
								<a class="nav-link" routerLink="/admin-panel/logs/error-logs">{{ 'nav.admin.error_logs' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('access_action_logs')">
								<a class="nav-link" routerLink="/admin-panel/logs/action-logs">{{ 'nav.admin.action_log' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('access_rejection_logs')">
								<a class="nav-link" routerLink="/admin-panel/logs/rejected-device-connection">{{ 'nav.admin.rej_device_conn' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('access_error_logs')">
								<a class="nav-link" routerLink="/admin-panel/logs/unused-logs">{{ 'nav.admin.unused_logs' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('admin_contact_us')">
								<a class="nav-link" routerLink="/admin-panel/contact-us">{{'contact_us.title' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('apis_logs')">
								<a class="nav-link" routerLink="/admin-panel/apis-logs">{{'apis_logs.title' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessPermission(null, 'noc')">
								<a class="nav-link" routerLink="/admin-panel/logs/update-firmware-logs">{{'update_firmware_log.title' | translate}}</a>
							</li>
						</ul>
					</li>
					<li class="nav-item dropdown dropdown-submenu p-0" appDropdown *ngIf="usersService.hasAccessFunction('sched_functions_control')">
						<a class="nav-link">{{'nav.admin.schedule' | translate}} <i class="fa fa-caret-right float-right"></i></a>
						<ul class="dropdown-menu p-0">
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/admin-panel/sched/sched-control">{{ 'nav.admin.sched_control' | translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/admin-panel/sched/sched-monitor">{{ 'nav.admin.sched_monitor' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="!isProduction">
								<a class="nav-link" routerLink="/admin-panel/sched/sched-run">{{ 'nav.admin.sched_run' | translate}}</a>
							</li>
						</ul>
					</li>
					<li class="nav-item dropdown dropdown-submenu p-0" appDropdown *ngIf="usersService.hasAccessFunction('permissions_management')">
						<a class="nav-link">{{'nav.permissions_management' | translate}} <i class="fa fa-caret-right float-right"></i></a>
						<ul class="dropdown-menu p-0">
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/permissions/user">{{'nav.user_permissions_management' | translate}}</a>
							</li>
							<li class="nav-item p-0">
								<a class="nav-link" routerLink="/permissions/enterprise">{{'nav.enterprise_permissions_management' | translate}}</a>
							</li>
						</ul>
					</li>
					<li class="nav-item dropdown dropdown-submenu p-0" appDropdown *ngIf="usersService.hasAccessFunction('shipment_management') || usersService.hasAccessFunction('cm_check_warnings')">
						<a class="nav-link">{{'nav.shipment_management' | translate}} <i class="fa fa-caret-right float-right"></i></a>
						<ul class="dropdown-menu p-0">
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('shipment_management')">
								<a class="nav-link" routerLink="/management/receiving-shipment">{{'nav.receiving_shipment' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('cm_check_warnings') || usersService.hasAccessFunction('shipment_management')">
								<a class="nav-link" routerLink="/management/shipment-quality">{{'nav.shipment_quality' | translate}}</a>
							</li>
							<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('shipment_management')">
								<a class="nav-link" routerLink="/management/shipping-out">{{'nav.shipping_out' | translate}}</a>
							</li>
						</ul>
					</li>
					<li class="nav-item p-0" *ngIf="!isChargLinkDomain && user.has_access_to_any_study">
						<a class="nav-link" routerLink="/management/studies">{{'studies.power_studies' | translate}}</a>
					</li>
					<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('shipment_management') || usersService.hasAccessFunction('view_orders') || usersService.hasAccessFunction('create_orders')">
						<a class="nav-link" routerLink="/orders/manage">{{'nav.manage_orders' | translate}}</a>
					</li>
					<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('shipment_management')">
						<a class="nav-link" routerLink="/management/rma-processing">{{'nav.rma' | translate}}</a>
					</li>
					<li class="nav-item p-0" *ngIf="usersService.hasAccessFunction('registration_reports')">
						<a class="nav-link" routerLink="/registration-reports">{{'nav.registration_reports' | translate}}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
