<h2 class="text-start">{{ "nav.blocked_fw_reports" | translate }}</h2>
<sct-custom-table
	*ngIf="tableData.length"
	[columnsConfig]="columnConfig"
	[data]="tableData"
	[config]="tableConfig"
	(updateSelectedRows)="updateSelectedDevices($event)"
></sct-custom-table>

<app-modal
	[hasConfirmButton]="true"
	#unblockConfirmationPopup title="{{'g.confirmation' | translate}}"
	size="md"
>
	<p>{{'sim.unblock_sims_confirmation' | translate}}</p>
	<ul>
		<li *ngFor="let device of selectedDevices">{{device.serial_number}}</li>
	</ul>
</app-modal>
