<div>
	<sct-custom-table
		#sctCustomTable
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
		(backEndPaginationFunc)="getActionsHistory($event)"
	></sct-custom-table>
	<div class="text-danger" *ngIf="invalidDateRange; else noData"><b>{{'g.start_lg_end' | translate}}</b></div>
	<ng-template #noData>
		<div *ngIf="tableData && !tableData.length">
			<strong>{{'g.no_results_found' | translate}}</strong>
		</div>
	</ng-template>
</div>