import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import * as lodash from 'lodash-es';
import { DeviceService } from '../../device.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { UsersService } from 'src/app/users/users.service';
import { ColumnsConfig, CustomTableBackendData, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { SCTCustomTable } from 'src/app/shared/custom-table/custom-table.component';

@Component({
	selector: 'app-config-track',
	templateUrl: './config-track.component.html'
})
export class ConfigTrackComponent implements OnInit {
	@Input() device: any = {};
	@Input() currentSite: any;
	@Input() dateRange: {
		fromDate: Date,
		toDate: Date
	} = {
		fromDate: new Date(),
		toDate: new Date()
	};
	@Input() invalidDateRange: boolean = false;
	@ViewChild("sctCustomTable", { static: true }) sctCustomTable!: SCTCustomTable;
	@ViewChild("trackModal") trackModal;
	@ViewChild("compareModal") compareModal;

	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	recordsLimit = 3;
	configsToCompare: any;
	gridColumns: any;
	trackModalData: any = {};
	compareModalData: any = {};
	arrayFields: string[] = [];
	objectFields: string[]	= ['mobile_master', 'mobile_network', 'router_network', 'online_network'];
	nestedObjFields: string[] = ['mp_cal_high_range', 'mp_cal_low_range'];

	linksFields: string[] = ['last_saved_user_id'];
	compareSkipFields: string[] = ['last_saved_user_id_link'];


	columnConfig: ColumnsConfig[] = [];
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: true,
		fileName: 'config-track',
		hasSelectionColumn: true,
		hasPagination: true,
		pageSize: 3,
		isBackendPagination: true
	};

	firstTime = true;

	constructor(
		private deviceService: DeviceService,
		private commonService: CommonService,
		private translateService: TranslateService,
		private usersService: UsersService,
	) { }

	ngOnInit() {
		this.columnConfig = [
			{ key: 'id', name: this.translateService.instant('g.id'), type: 'number'},
			{ key: 'memory_signature', name: this.translateService.instant('devices.memory_signature'), type: "number"},
			{ key: 'insertion_time', name: this.translateService.instant('g.insertion_time'), type: "date"},
			{ key: 'show_configs', name: this.translateService.instant('config.configs'), type: "action_link", hasSort: false, hasFilter: false},
		];
	}

	ngAfterViewInit() {
		this.getDeviceConfigTrack({currentPage: 1});
	}

	ngOnChanges(changes) {
		if (
			(changes.device && changes.device.previousValue && changes.device.previousValue.mac_address != changes.device.currentValue.mac_address) ||
			changes.dateRange
		) {
			if (!this.firstTime)
				this.getDeviceConfigTrack({currentPage: 1});

			this.firstTime = false;
		}
	}

	onTrackCellClicked(cycle) {
		this.trackModalData = this.formatChanges(cycle.configs);
		this.trackModal.show();
	}

	formatChanges(configs) {
		let zoneID = (this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id);
		return this.deviceService.formatQueuedChanges(this.device, configs, 'plainObject', zoneID);
	}

	getDeviceConfigTrack(paginationData) {
		paginationData = structuredClone(paginationData);
		if(!this.device.mac_address || this.invalidDateRange || !this.sctCustomTable)
			return;

		if(paginationData.currentPage == 1)
			this.sctCustomTable.backendPaginationInit();

		const limit = this.sctCustomTable.config.pageSize;

		let fromDateRange = this.dateRange.fromDate;
		let toDateRange = this.dateRange.toDate;
		fromDateRange = new Date(new Date(fromDateRange).setHours(0, 0, 0, 0));
		toDateRange = new Date(new Date(toDateRange).setHours(23, 59, 59, 999));

		let zoneDiff = new Date().getTimezoneOffset() * -1;
		let fromDate: any = new Date(new Date(fromDateRange).getTime() + (zoneDiff * 60 * 1000));
		let toDate: any = new Date(new Date(toDateRange).getTime() + (zoneDiff * 60 * 1000));
		fromDate = moment(fromDate).utc().startOf('day').unix();
		toDate = moment(toDate).utc().endOf('day').unix();
		const zoneId = this.device.config_info.zone_id ? this.device.config_info.zone_id : this.currentSite.zone_id;

		const options = {
			currentPage: paginationData.currentPage,
			filters: paginationData.filters,
			sort: paginationData.sort,
			limit,
			fromDate,
			toDate,
		};

		if (options.filters?.insertion_time?.from) {
			const fromDate = new Date(options.filters.insertion_time.from).setHours(0, 0, 0, 0) / 1000;
			options.filters.insertion_time.from = this.commonService.getUTCTimestampFromZone(zoneId, new Date(fromDate));
		}

		if (options.filters?.insertion_time?.to) {
			const toDate = new Date(options.filters.insertion_time.to).setHours(23, 59, 59, 999) / 1000;
			options.filters.insertion_time.to = this.commonService.getUTCTimestampFromZone(zoneId, new Date(toDate));
		}

		this.deviceService.getDeviceConfigTrack(this.device.mac_address, options).subscribe((response: CustomTableBackendData) => {
			const tableData = [];
			this.tableData = [];

			response.data.forEach((record) => {
				record.insertion_time = this.commonService.getZoneTimestampFromUTC((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), record.insertion_time);
				record.insertion_time = moment(record.insertion_time*1000).utc().format('MM/DD/YYYY HH:mm:ss');

				switch (record.configs.last_saved_user_id) {
					case 0:
					case 0xFFFFFFFF:
						record.configs.last_saved_user_id = this.translateService.instant('g.system');
						break;
					default:
						if (this.usersService.hasAccessFunction('edit_user_admin'))
							record.configs.last_saved_user_id_link = `/user/edit/${record.configs.last_saved_user_id}`;

						record.configs.last_saved_user_id = `${record.configs.last_saved_user_id} - ${record.configs.last_saved_user_name}`;
						delete record.configs.last_saved_user_name;
						break;
				}

				this.formatBooleanFields(record.configs);

				tableData.push({
					id: { value: record.id },
					memory_signature: { value: record.memory_signature },
					show_configs: { value: this.translateService.instant('config.show_configs'), action: () => { this.onTrackCellClicked(record) } },
					insertion_time: { value: record.insertion_time },
					configs: { value: record.configs },
					mis_capacity_time: { value: record.mis_capacity_time },
					mis_voltage_time: { value: record.mis_voltage_time },
				});
			});

			this.tableData = tableData;
			this.sctCustomTable.updatePagination(response.totalDataCount)
		});
	}

	gridEvent(event: any) {
		if(event.length == 2) {
			this.configsToCompare = event.map((item: any) => {
				const newItem = {};
				for (const key in item) {
					newItem[key] = item[key].value || item[key];
				}
				return newItem;
			});
		} else {
			this.configsToCompare = null;
		}
	}

	formatBooleanFields(recordConfigs) {
		for(let field in recordConfigs) {
			if(this.deviceService.deviceBooleanFields.includes(field))
				recordConfigs[field] = !!recordConfigs[field];
		}
	}

	compareConfigs() {
		let oldConfig		= {};
		let oldValsIdx		= 1;
		let newConfig		= lodash.cloneDeep(this.configsToCompare[0].configs);
		let ignoreFields	= ['memory_signature'];
		let fieldsToShowBothConfig = ['last_saved_user_id', 'last_saved_user_id_link'];

		if(this.configsToCompare[1].configs.memory_signature > this.configsToCompare[0].configs.memory_signature) {

			newConfig	= lodash.cloneDeep(this.configsToCompare[1].configs);
			oldValsIdx	= 0;
		}

		for(let key in newConfig) {
			if(ignoreFields.includes(key))
				continue;

			const handleNestObj = (tempOld, newConfig) => {
				if(tempOld && newConfig) {
					let hasDifference = false;
					let tempObj = {};
					for(let subKey in tempOld) {
						if(tempOld[subKey] != newConfig[subKey]) {
							tempObj[subKey] = tempOld[subKey];
							hasDifference = true;
						} else {
							delete newConfig[subKey];
						}
					}
					if(hasDifference) {
						oldConfig[key] = lodash.cloneDeep(tempObj);
					}
				}
			}
			let tempOld = this.configsToCompare[oldValsIdx].configs[key];
			if(this.objectFields.includes(key)) {
				handleNestObj(tempOld, newConfig[key]);

			} else if(this.arrayFields.includes(key)) {

				if(tempOld && newConfig[key] && !this.commonService.arrayCompare(tempOld, newConfig[key]))
					oldConfig[key] = tempOld;

			}else if (this.nestedObjFields.includes(key)) {
				handleNestObj(oldConfig[key], newConfig[key]);
			}
			else if(fieldsToShowBothConfig.includes(key) || tempOld != newConfig[key]) {

				oldConfig[key] = tempOld;
			}
		}

		this.compareModalData = {
			'old': this.formatChanges(oldConfig),
			'new': this.formatChanges(newConfig)
		};

		this.compareModal.show();
	}

	isObject(value) {
		return typeof value == 'object' && value && Object.keys(value).length > 0;
	}

	hideModals() {
		this.compareModal.hide();
		this.trackModal.hide();
	}
}
