import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { ApisLogsService } from './apis-logs.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { ColumnsConfig, CustomTableBackendData, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';
import { SCTCustomTable } from 'src/app/shared/custom-table/custom-table.component';
import * as moment from 'moment';

@Component({
	selector: 'app-apis-logs',
	templateUrl: './apis-logs.component.html',
})
export class ApisLogsComponent implements OnInit {
	@ViewChild("sctCustomTable", { static: true }) sctCustomTable!: SCTCustomTable;

	logs: any[] = [];

	columnConfig: ColumnsConfig[] = [];
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 100,
		isBackendPagination: true
	};

	constructor(
		public userService: UsersService,
		private router: Router,
		private apisLogsService: ApisLogsService,
		private sideMenuService: SideMenuService,
		private translateService: TranslateService,
	) { }

	ngOnInit() {
		if (!this.userService.hasAccessFunction('apis_logs'))
			return this.router.navigate(['/unauthorized']);

		this.columnConfig = [
			{ key: 'created_at', name: this.translateService.instant('users.create_date'), type: "date"},
			{ key: 'user', name: this.translateService.instant('login_register.user_name'), type: "string", filterType: 'dropdown', items: [], hasSort: false},
			{ key: 'end_point', name: this.translateService.instant('apis_logs.endpoint'), type: "string"},
			{ key: 'success', name: this.translateService.instant('g.success'), type: "boolean"},
			{ key: 'action', name: this.translateService.instant('g.view'), type: "action_link", hasFilter: false, hasSort: false},
		];

		this.sideMenuService.hide();
		return this.getLogs({currentPage: 1, isFirstTime: true});
	}

	getLogs(paginationData) {
		if(!this.sctCustomTable)
			return;

		if(paginationData.currentPage == 1)
			this.sctCustomTable.backendPaginationInit();

		const limit = this.sctCustomTable.config.pageSize;

		const tableData = [];

		const options = {
			currentPage: paginationData.currentPage,
			filters: paginationData.filters,
			sort: paginationData.sort,
			limit,
			getCount: paginationData.isFirstTime
		};

		if(options.filters?.user) {
			options.filters.user_id = options.filters.user;
			delete options.filters.user;
		}
		if(options.sort?.field == 'user')
			options.sort.field = 'user_id';

		this.apisLogsService.getLogs(options).subscribe((logs: CustomTableBackendData) => {
			logs.data.forEach((log: any) => {
				tableData.push({
					created_at: { value: moment(log.created_at*1000).utc().format('MM/DD/YYYY HH:mm:ss') },
					user: { value: log.user },
					user_id: { value: log.user_id },
					end_point: { value: log.end_point },
					success: { value: log.success },
					action: {value: this.translateService.instant('g.view'), action: (log)=>{this.viewLog(log)}},
					request: log.request,
					response: log.response,
				});
			});
			this.tableData = tableData;
			this.sctCustomTable.updatePagination(logs.totalDataCount, logs.overallItemsCount);
			if(paginationData.isFirstTime)
				this.columnConfig[1].items = logs.items.usersList;
		});
	}

	viewLog(log: any) {
		if (log.response?.err)
			log.response.error = this.apisLogsService.getLogError(log.response.err);

		const data = { request: log.request, response: log.response };

		const document = window.open('', '')?.document;
		if (!document)
			return;

		document.open();
		document.write([
			'<!DOCTYPE html>', '<html>', '<head>', '</head>', '<body>', '<pre>',
			JSON.stringify(data, null, 4),
			'</pre>', '</body>'
		].join(""));

		document.title = `Log ${log.id}`;
		document.close();
	}
}

