import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash-es';

@Injectable({
	providedIn: 'root'
})
export class EnterpriseService {

	route = '/enterprise/';

	constructor(
		private httpClient: HttpClient
	) { }
	
	getEnterprises(filters?) {
		return this.httpClient.post(this.route + 'getEnterprises', {filters}, {
			observe: "body"
		});
	}

	getEnterprise(enterpriseId, filters?) {
		return this.httpClient.post(this.route + 'getEnterprise', {enterpriseId, filters}, {
			observe: "body"
		});
	}

	deleteEnterprise(enterpriseId, options) {
		return this.httpClient.post(this.route + 'deleteEnterprise', {enterpriseId, options}, {
			observe: "body"
		});
	}

	saveEnterprise(enterprise) {
		return this.httpClient.post(this.route + 'saveEnterprise', enterprise, {
			observe: "body"
		});
	}

	getGroupTypes() {
		return [
			{val: "sct", text: "SCT"},
			{val: "dealer", text: "Dealer"},
			{val: "service_dealer", text: "Service Dealer"},
			{val: "customer", text: "Customer"},
			{val: "truck_dealer", text: "Truck Dealer"},
			{val: "battery_oem", text: "Battery OEM"}
		]
	}
	
	getGroupTypeText(val) {
		if (!val) return "";
		let groups = this.getGroupTypes();
		let group = _.find(groups, function(o) { return o.val ==val});
		return group.text;
	}

	getAdminUsers() {
		return this.httpClient.post('/user/getAdminUsers', {}, {
			observe: "body"
		});
	}
}