<div class="row mt-3 mb-3 col-12 justify-content-md-center">
	<div class="col-md-12">
		<div class="d-flex justify-content-between">
			<h2>{{'force_read_rt.title' | translate}}</h2>
		</div>
		<div class="form-field mb-2">
			<label for="force_read_rt_time">{{'force_read_rt.sub_title' | translate}}</label>
			<input type="number" class="form-control col-4" [(ngModel)]="force_read_rt_time" [max]="maxRTHours" >
		</div>
		<h6 *ngIf="forceReadRTUntil" class="mt-2">{{'force_read_rt.reading_time_until' | translate}}: <b>{{forceReadRTUntil}} ({{'g.utc' | translate}})</b></h6>
		<div class="my-4">
			<button type="submit" class="btn btn-primary" [disabled]="force_read_rt_time == null" (click)="saveRTDate()">{{'g.submit' | translate}}</button>
		</div>
	</div>
	<div class="col-md-12">
		<mat-icon class="pointer blue-hover button-end" [matMenuTriggerFor]="options" *ngIf="usersService.userHasNOCAccess()">
			cloud_upload
		</mat-icon>
	</div>
</div>

<div class="white-box mt-4 p-4 border-radius">
	<sct-custom-table
		*ngIf="tableData.length"
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
	></sct-custom-table>
	<div *ngIf="tableData.length === 0">{{'g.no_data_available_for_the_selected_day' | translate}}</div>

	<div *ngIf="showChart" class="mt-5">
		<div class="row">
			<div class="col-md-12">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" value="" id="defaultCheck1" [(ngModel)]="visibilityCheckboxes.voltage" (change)="drawRTrecordsChart()">
					<label class="form-check-label" for="defaultCheck1">
						Show Voltage
					</label>
				</div>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" value="" id="defaultCheck2" [(ngModel)]="visibilityCheckboxes.current" (change)="drawRTrecordsChart()">
					<label class="form-check-label" for="defaultCheck2">
						Show Current
					</label>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="btn btn-light pull-right mr-3">
					<button class="btn fa fa-print fa-lg" printTitle="{{'nav.rt_records' | translate}}" [useExistingCss]="true" printSectionId="voltage-and-current-chart" ngxPrint title="{{'g.print' | translate}}"></button>
				</div>
			</div>
		</div>
		<div id="voltage-and-current-chart" class="row">
			<h2 class="showOnPrint">{{currentSite.customer_name}} / {{currentSite.name}}</h2>
			<h3 class="showOnPrint">{{'nav.rt_records' | translate}}</h3>
			<h4 class="showOnPrint">{{fromDateFormatted}}</h4>
			<h4 class="showOnPrint">{{device.mac_address}} ({{device.serial_number}})</h4>
			<sct-charts
				[data]="chartObject.data"
				[columnNames]="chartObject.columnNames"
				[options]="chartObject.config"
				[formatter]="chartObject.formatter"
				[formatterType]="chartObject.formatterType"
				[title]="'devices.voltage_and_current_chart' | translate"
				[type]="'LineChart'"
				[height]="500"
			></sct-charts>
		</div>
	</div>
</div>

<app-modal
		[hasConfirmButton]="true"
		[hasCloseButton]="true"
		[closeOnConfirm]="false"
		#fetchRtModal
		[confirmBtnText]="'force_read_rt.fetch_rt_range' | translate"
		[title]="(byId ? 'force_read_rt.fetch_rt_by_id_range' : 'force_read_rt.fetch_rt_time_range') | translate"
		size="lg"
	>
		<!-- by id -->
		<div *ngIf="byId; else byTime">
			<p class="notValidOption" *ngIf="fetchRtBy.id.error">{{fetchRtBy.id.error}}</p>
			<div class="row col-md-12">
				<div class="col-md-6">
					<span>{{'force_read_rt.start_rt_id' | translate}}</span>
					<input type="number" class="form-control" [(ngModel)]="fetchRtBy.id.fromId">
				</div>
				<div class="col-md-6">
					<span>{{'force_read_rt.end_rt_id' | translate}}</span>
					<input type="number" class="form-control" [(ngModel)]="fetchRtBy.id.toId">
				</div>
			</div>
		</div>

		<!-- by time -->
		<ng-template #byTime>
			<p class="notValidOption" *ngIf="fetchRtBy.timeRange.error">{{fetchRtBy.timeRange.error}}</p>
			<div class="row col-md-12">
				<div class="col-md-6">
					<span>{{'g.from_time' | translate}}</span>
					<input type="datetime-local" class="form-control" [(ngModel)]="fetchRtBy.timeRange.fromTimeLocal" (ngModelChange)="formatTime('fromTime', $event)">
				</div>
				<div class="col-md-6">
					<span>{{'g.to_time' | translate}}</span>
					<input type="datetime-local" class="form-control" [(ngModel)]="fetchRtBy.timeRange.toTimeLocal" (ngModelChange)="formatTime('toTime', $event)">
				</div>
			</div>
		</ng-template>
</app-modal>

<mat-menu #options="matMenu" yPosition="below" [overlapTrigger]="false">
	<ng-template matMenuContent>
		<span mat-menu-item (click)="openFetchRTPopup(true)">{{"force_read_rt.fetch_rt_by_id_range" | translate}}</span>
		<span mat-menu-item (click)="openFetchRTPopup(false)">{{"force_read_rt.fetch_rt_time_range" | translate}}</span>
		<span mat-menu-item [routerLink]="['/noc-tools', 'rt-requests-history', device.mac_address]">{{'rt_history.history_fetch_rt' | translate}}</span>
	</ng-template>
</mat-menu>
