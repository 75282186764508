import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { EnterpriseService } from './enterprise.service';
import { Router } from "@angular/router";
import { NotificationMessageService } from '../shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { UsersService } from '../users/users.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { ColumnsConfig, TableConfig, TableData } from '../shared/custom-table/custom-table-interface';

@Component({
	selector: 'app-enterprise',
	templateUrl: './enterprise.component.html',
	styleUrls: ['./enterprise.component.css']
})
export class EnterpriseComponent implements OnInit, AfterViewInit {
	@ViewChild("confirmModal") confirmModal;
	@ViewChild("linkedDevicesModal") linkedDevicesModal;
	@ViewChild("deleteAutoCustomerProcess") deleteAutoCustomerProcess;

	// table data
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		fitScreen: true
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'name', name: this.translate.instant('g.name'), type: "string"},
		{ key: 'admins', name: this.translate.instant('g.admins'), type: 'string' },
		{ key: 'group_type', name: this.translate.instant('g.group_type'), type: "string" },
		{ key: 'actions', name: this.translate.instant('g.actions'), type: "dropdown" },
	];

	enterprise: any[] = [];
	currentUser: any;
	admins = {};
	filters = {
		searchString: '',
		isPartial: false,
		groupType: []
	};
	groupTypes;

	constructor(
		public enterpriseService: EnterpriseService,
		private router: Router,
		private notificationMessage: NotificationMessageService,
		public translate: TranslateService,
		public usersService: UsersService,
		private sideMenuService: SideMenuService
	) { }

	ngOnInit() {
		this.currentUser = this.usersService.getCurrentUser();
		if(!this.usersService.hasAccessFunction('enterprise_management') || !this.currentUser.isSCT)
			return this.router.navigate(['/unauthorized']);

		this.sideMenuService.hide();
		this.groupTypes = this.enterpriseService.getGroupTypes();
		this.getEnterprises();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			if(result[1][0] == 'deleteEnterprise') {
				return this.deleteEnterprise(result[1][1], false, false);
			}
		});
	}

	getEnterprises() {
		this.enterpriseService.getEnterprises(this.filters || {}).subscribe(
			(data: any) => {
				this.enterprise = data.sort((item1, item2) => item1.name.toLowerCase() > item2.name.toLowerCase() ? 1 : -1);

				let adminIds = _.pluck(data, "admins");
				this.getAdminNames([].concat.apply([], adminIds));
			}
		);
	}

	showDeleteEnterprisePopup(id) {
		this.confirmModal.message = 'g.del_enterprise_prompt';
		this.confirmModal.passData = ['deleteEnterprise', id];
		this.confirmModal.show();
	}

	getAdminNames(adminIds) {
		this.usersService.getUsersInfo(adminIds).subscribe(data=> {
			this.admins = _.indexBy(data, "id");
			this.tableData = [];

			for(const enterprise of this.enterprise) {
				const admins = enterprise.admins.filter(id => this.admins[id]).map(id => `${this.admins[id].first_name} ${this.admins[id].last_name}`);

				this.tableData.push({
					name: {value: enterprise.name},
					admins: {value: admins.join('\n')},
					group_type: {value: this.enterpriseService.getGroupTypeText(enterprise.group_type)},
					actions: {value: null, options: [
						{text: this.translate.instant('g.edit'), action: ()=> {this.goToEditEnterprise(enterprise.id)}},
						{text: this.translate.instant('g.delete'), action: ()=> {this.showDeleteEnterprisePopup(enterprise.id)}},
					]},
				})
			}
		});
	}

	goToEditEnterprise(id) {
		this.router.navigate(['/enterprise', 'edit', id]);
	}

	deleteEnterprise(id, forceDelete, deleteAutoGeneCustomer) {
		const options = {
			forceDelete: forceDelete,
			deleteAutoGeneCustomer: deleteAutoGeneCustomer
		}
		this.enterpriseService.deleteEnterprise(id, options).subscribe((data: any) => {
			this.handleDeleteResponse(id, data);
		});
	}

	deleteEnterpriseCustomer(id, deleteAutoGeneCustomer) {
		const options = {
			forceDelete: true,
			deleteAutoGeneCustomer: deleteAutoGeneCustomer
		}
		this.enterpriseService.deleteEnterprise(id, options).subscribe((data: any) => {
			this.handleDeleteResponse(id, data);
		});
	}

	handleDeleteResponse(id, res) {
		switch(res?.api_status) {
			case 1:
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.getEnterprises();
				break;
			case 2:
				this.deleteAutoCustomerProcess.message = 'ent.enterprise_has_customers';
				this.deleteAutoCustomerProcess.passData = {enteroriseId: id};
				this.deleteAutoCustomerProcess.show();
				break;
			case 3:
				this.notificationMessage.setMessage('translate|ent.enterprise_has_users', {clearOnXTimeNavigate: 1});
				break;
			case 4:
				this.notificationMessage.setMessage('translate|ent.enterprise_has_other_sites', {clearOnXTimeNavigate: 1});
				break;
			case 5:
				this.notificationMessage.setMessage('translate|ent.auto_generated_has_devices_sites', {clearOnXTimeNavigate: 1});
				break;
			default:
				this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
		}
	}

	search() {
		this.getEnterprises();
	}
}
