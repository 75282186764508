import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { UsersService } from 'src/app/users/users.service';
import { SitesService } from 'src/app/sites/sites.service';
import * as _ from 'underscore';
import * as moment from 'moment';
import { OrdersService } from '../orders/orders.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { CommonService } from 'src/app/shared/services/common.service';
import { AttachmentsService } from 'src/app/shared/attachments/attachments.service';
import { Subscription } from 'rxjs';
import { TableData, ColumnsConfig } from 'src/app/shared/custom-table/custom-table-interface';

@Component({
	selector: 'app-shipping-out',
	templateUrl: './shipping-out.component.html'
})
export class ShippingOutComponent implements OnInit {

	currentUser: any = [];
	searchString: string = '';
	searchBy: string = 'mac'; // sn: serial number, mac: mac address
	searchResult: any = null;
	sites: any = [];
	customerEnterprises = <any>[];
	enterpriseGroups: any[] = [
		'dealer',
		'customer',
		'battery_oem',
	];

	DEVICE_TYPES = {
		iotah: 'IoTAh',
		charglink: 'ChargLink',
		study: 'Study',
	};

	selectedDevices: any = [];
	selectedDeviceTypes = {
		[this.DEVICE_TYPES.iotah]: [],
		[this.DEVICE_TYPES.charglink]: [],
		[this.DEVICE_TYPES.study]: [],
	}
	selectedGroup: any;
	selectedEnterpriseList: any;

	selectedCustomerList: any;
	selectedEnterpriseSitesList: any;
	selectedSiteList: any;

	shipOutToEnterprise: number;
	shipOutToCustomer: number;
	shipOutToSite: number;
	smartRebatesGroup: boolean = false;
	site: any = {};
	newSite = {id: 0, name: this.translateService.instant('site.new_site')};

	po_number: string;
	purchaseOrderMaxLength: 20;
	poNumbersDropdown: string[] = [];
	allPoNumbers: any = {};
	motiveEnergyData: any;
	smartRebatesSelectedErr = "";
	disableSmartRebateOption: boolean = false;
	showUploadFileEle = true;
	searchBulk = false;
	maxDevicesCountPerFile = 50;
	multiSearchDeviceSubscription!: Subscription;
	bulkSearchNotFoundDevices: string[] = [];

	@ViewChild('selectedFilesDialog') selectedFilesDialog: ModalComponent;
	selectedFiles: any = [];
	readonly maxFilesCount: number = 5;
	readonly mbBytes: number = 1048576; // 1 MB in bytes
	readonly maxSize: number = 10 * this.mbBytes;
	readonly allowedUploadFilesExt: string[] = this.attachmentsService.allowedUploadFilesExt;

	@ViewChild('labelImport')
	labelImport: ElementRef;

	isPowerStudiesDevices: boolean = false;
	columns:ColumnsConfig[] = [
		{key: 'file_name', name: this.translateService.instant('ready_to_ship.file_name'), type: 'string'},
		{key: 'action', name: this.translateService.instant('g.action'), type: 'icon'}
	];

	constructor(
		private router: Router,
		private chRef: ChangeDetectorRef,
		private translateService: TranslateService,
		public usersService: UsersService,
		private sideMenuService: SideMenuService,
		private notificationMessage: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
		public enterpriseService: EnterpriseService,
		public sitesService: SitesService,
		public ordersService: OrdersService,
		public attachmentsService: AttachmentsService,
		private commonService: CommonService,
		private el: ElementRef,
		private renderer: Renderer2,
	) { }

	ngOnInit() {
		this.currentUser = this.usersService.getCurrentUser();
		if(!this.usersService.hasAccessFunction('shipment_management'))
			this.router.navigate(['unauthorized']);
		this.sideMenuService.hide();
		this.site.installation_date = new Date(new Date().setHours(0, 0, 0, 0));
		this.getOrderPoNumbers();
		this.checkSmartRebatesShippingOption();
	}
	
	getOrderPoNumbers() {
		this.ordersService.getOrderPoNumbers().subscribe((data: any) => {
			const orders: any = {};
			const list: any = [];

			for (const order of data) {
				const poNumber = order.po_number;

				const deviceList = [
					order.remaining_iotah_count ? `${order.remaining_iotah_count} ${this.translateService.instant('g.iotah')}` : null,
					order.remaining_charglink_count ? `${order.remaining_charglink_count} ${this.translateService.instant('g.charglink')}` : null,
					order.remaining_study_count ? `${order.remaining_study_count} ${this.translateService.instant('g.study')}` : null,
				].filter(Boolean);

				if(!deviceList.length)
					continue;

				const result = deviceList.length > 1
					? deviceList.slice(0, -1).join(', ') + ' and ' + deviceList.slice(-1)
					: deviceList[0];

				orders[poNumber] = order;
				list.push({
					value: poNumber,
					label: `${poNumber} (${this.translateService.instant('orders.remaining', { remainingDevicesCount: result })})`
				});
			}
			this.allPoNumbers = orders;
			this.poNumbersDropdown = this.commonService.sortDataAlphabetically(list, 'value');
		});
	}

	getDevices() {
		this.notificationMessage.closeNotification();
		this.deviceManagementService.searchDevices(this.searchString, this.searchBy, false, false, 'shipping-out').subscribe((data: any) => {
			this.searchResult = data.devices || [];
			this.sites = _.indexBy(data.sites || [], 'id');

			if (this.searchResult.length > 0 && _.findWhere(this.selectedDevices, {mac_address: this.searchResult[0].mac_address}))
				this.searchResult = [];

			this.bulkSearchNotFoundDevices = [];
		});
	}

	checkOrderDevicesAvailability(device: any) {
		let errorMsg = '';

		const deviceType = this.getDeviceType(device);
		this.selectedDeviceTypes[deviceType].push(device);

		if (this.selectedDeviceTypes[deviceType].length > this.getOrderAvailableDevices(this.po_number, deviceType)) {
			this.selectedDeviceTypes[deviceType].pop();
			errorMsg = this.translateService.instant('orders.reached_order_limit', { deviceType });
		}

		return errorMsg;
	}

	getDeviceType(device: any) {
		let deviceType = null;

		switch (true) {
			case device.is_study_device:
				deviceType = this.DEVICE_TYPES.study;
				break;
			case !!device.is_charglink:
				deviceType = this.DEVICE_TYPES.charglink;
				break;
			default:
				deviceType = this.DEVICE_TYPES.iotah;
				break;
		}

		return deviceType;
	}

	prepareForShipment(device, customerId) {
		const orderAvailabilityErrorMsg = this.checkOrderDevicesAvailability(device);
		if (orderAvailabilityErrorMsg)
			return this.notificationMessage.setMessage(orderAvailabilityErrorMsg, { clearOnXTimeNavigate: 1 });

		device._customer_id = customerId;
		this.selectedDevices.push(device);

		this.searchString = '';
		this.searchResult = this.searchResult.filter((item: { mac_address: any; }) => item.mac_address != device.mac_address);
	}

	removeFromShipment(device: any) {
		const macAddress = device.mac_address;
		this.selectedDevices = this.selectedDevices.filter((device) => {
			return device.mac_address != macAddress;
		});

		const deviceType = this.getDeviceType(device);
		this.selectedDeviceTypes[deviceType] = this.selectedDeviceTypes[deviceType].filter((device) => {
			return device.mac_address != macAddress;
		});
	}

	shipOutDevices() {
		if(this.selectedDevices.length == 0 || !this.po_number) return;

		const validation: any = this.filesValidation();
		if (Object.keys(validation).length) {
			let message = [];

			if (validation.max_count)
				return this.notificationMessage.setMessage(this.translateService.instant('ready_to_ship.files_max_count_err', { max_files_count: this.maxFilesCount }), {clearOnXTimeNavigate: 1});

			if (validation.extension)
				message.push(`${this.translateService.instant('ready_to_ship.extension_err')}: ${this.allowedUploadFilesExt.join(', ')}`);
			if (validation.size)
				message.push(this.translateService.instant('ready_to_ship.size_err', { max_size: this.maxSize / this.mbBytes }));

			return this.notificationMessage.setMessage(message.join(this.translateService.instant('g.and')), {clearOnXTimeNavigate: 1});
		}
	
		let deviceIds = _.pluck(this.selectedDevices, 'mac_address');

		let isNewSite = false;

		let options: any = {};
		if(this.shipOutToCustomer && (this.shipOutToSite || this.shipOutToSite === 0)) {
			options.siteInfo = {customer_id: this.shipOutToCustomer};
			if(this.shipOutToSite)
				options.siteInfo.site_id = this.shipOutToSite;
			else {
				// New Site
				let site = Object.assign({}, this.site);
				let insDate = new Date(site.installation_date);
				site.installation_date = Math.floor((-insDate.getTimezoneOffset() * 60000 + insDate.getTime()) / 1000);
				options.siteInfo.newSite = site;
				isNewSite = true;
			}
		}
		
		options.po_number = this.po_number;
		options.is_power_studies_devices = this.isPowerStudiesDevices;

		const formData = new FormData();
		for (const file of this.selectedFiles)
			formData.append("files", file.row);

		formData.append('devicesIds', JSON.stringify(deviceIds));
		formData.append('enterpriseId', JSON.stringify(this.shipOutToEnterprise));
		formData.append('options', JSON.stringify(options));
	
		this.deviceManagementService.shipOutDevices(formData).subscribe((data: any) => {
			if(data.api_status) {
				let message = 'globalErrMsg';
				switch (data.api_status) {
					case 2:
						message = 'translate|common.device_not_sct_inventory';
					break;
					case 3:
						message = 'translate|ready_to_ship.enterprise_no_inventory';
					break;
					case 4:
						message = 'translate|site.site_name_already_exist';
					break;
					case 5:
						message = 'translate|ready_to_ship.invalid_po_number';
					case 6:
						message = 'translate|orders.reached_order_count';
					case 7:
						message = 'translate|site.reserved_site_name';
						const form = this.el.nativeElement;
						const ele = form.querySelectorAll('[name=name]');
						if(ele && ele[0])
							this.renderer.addClass(ele[0], 'invalid-input');
					break;
					case 8:
						message = 'translate|ready_to_ship.disconnected_devices';
					break;
					case 9:
						message = 'translate|ready_to_ship.charglink_devices';
					break;
				}
				return this.notificationMessage.setMessage(message, {clearOnXTimeNavigate: 1});
			}

			this.resetValues();
			this.getOrderPoNumbers();
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			if(isNewSite)
				this.onGroupChanged();
		});
	}

	resetValues() {
		this.selectedDevices = [];
		this.selectedFiles = [];
		this.po_number = null;
		this.searchString = '';
		this.searchResult = [];
		this.bulkSearchNotFoundDevices = [];
		this.showUploadFileEle = true;
		this.labelImport.nativeElement.value = '';
		this.labelImport.nativeElement.innerText = this.translateService.instant('shipment.import_devices_csv');
		this.selectedDeviceTypes = {
			[this.DEVICE_TYPES.iotah]: [],
			[this.DEVICE_TYPES.charglink]: [],
			[this.DEVICE_TYPES.study]: [],
		}
	}

	onGroupChanged() {
		if (this.selectedGroup != 'smart_rebates') {
			this.smartRebatesSelectedErr = '';
			this.smartRebatesGroup = false;
		}

		let selectedGroup = [this.selectedGroup];
		if(this.selectedGroup == 'dealer')
			selectedGroup.push('service_dealer');

		this.clearSelections(3);
		this.enterpriseService.getEnterprises({groupType: selectedGroup, source: 'shipment'}).subscribe((res: any) => {
			this.selectedEnterpriseList = this.commonService.sortDataAlphabetically(res, 'name');
		});
	}

	handleSmartRebatesGroup() {
		this.smartRebatesGroup = true;
		this.motiveEnergyData = this.commonService.getMotiveEnergyCustomerData();
		this.enterpriseService.getEnterprises({source: 'shipment'}).subscribe((enterpriseData: any[]) => {
			this.selectedEnterpriseList = enterpriseData;
			this.shipOutToEnterprise = this.motiveEnergyData.motiveEnergyEnterprise;

			this.onEnterpriseChanged();
		});
	}

	onEnterpriseChanged() {
		this.clearSelections(2);
		this.sitesService.getEnterpriseSitesInfo(this.shipOutToEnterprise).subscribe((res: any) => {
			const sites = res.sites || [];
			if(sites.length) {
				this.selectedCustomerList = _.chain(sites).map(({customer_id, customer_name}) => ({id: customer_id, name: customer_name})).uniq((obj) => {return obj.id;}).value();
				this.selectedCustomerList = this.commonService.sortDataAlphabetically(this.selectedCustomerList, 'name');
				this.selectedEnterpriseSitesList = sites;

				if(this.smartRebatesGroup) {
					this.selectedSiteList = sites;
					this.shipOutToCustomer = this.motiveEnergyData.motiveEnergyCustomer;
					this.shipOutToSite = this.motiveEnergyData.motiveEnergySite;
				}
			}
		});
	}

	getCustomerInfo() {
		this.sitesService.getCustomerSites(this.shipOutToCustomer, {get_customers: true}).subscribe((response: any) => {
			this.handleCustomerEnterprises(response.customerInfo[0])
		});
	}

	handleCustomerEnterprises(customer) {
		this.site.customer_enterprises = customer.customer_enterprises || [];
		this.customerEnterprises = Array.isArray(customer.customer_enterprises) 
			? this.selectedEnterpriseList.filter((item) => customer.customer_enterprises.includes(item.id))
			: [];

		this.selectedEnterpriseList = this.selectedEnterpriseList.filter((item) => !this.site.customer_enterprises.includes(item.id));
	}

	onCustomerChanged() {
		this.clearSelections(1);
		this.getCustomerInfo();
		if(this.shipOutToCustomer) {
			if (this.isPowerStudiesDevices) {
				const powerStudySiteId = 99999;
				this.selectedSiteList = [{ id: powerStudySiteId, name: this.translateService.instant('studies.power_studies_devices') }];
				this.shipOutToSite = powerStudySiteId;
			} else {
				this.selectedSiteList = _.where(this.selectedEnterpriseSitesList, {customer_id: this.shipOutToCustomer});
				this.selectedSiteList = this.commonService.sortDataAlphabetically(this.selectedSiteList, 'name');
				if (this.usersService.hasAccessFunction('site_management') && this.currentUser.isSCT)
					this.selectedSiteList.unshift(this.newSite);
			}
		}
	}

	updateSiteCoords(coords) {
		this.site.latitude = coords.lat;
		this.site.longitude = coords.lng;
		this.site.address = coords.address;
		this.site.country_id = coords.country;
		this.site.state_id = coords.state;
		this.chRef.detectChanges();
	}

	clearSelections(level) {
		this.selectedSiteList = null;
		this.shipOutToSite = null;
		if(level > 1) {
			this.selectedCustomerList = null;
			this.shipOutToCustomer = null;
		}
		if(level > 2) {
			this.selectedEnterpriseList = null;
			this.shipOutToEnterprise = null;
		}
	}

	selectFiles(events: any) {
		const existsFile = [];
		let files: any = Object.values(events.target.files);
		if (!files.length)
			return;

		const alreadyFilesNames = this.selectedFiles.map((file: any) => file.row.name);
		const duplicateFiles = [];
		for (const file of files) {
			if (alreadyFilesNames.includes(file.name))
				duplicateFiles.push(file.name);
		}

		if (duplicateFiles.length)
			return this.notificationMessage.setMessage('translate|ready_to_ship.files_already_selected', { clearOnXTimeNavigate: 1 });

		files = this.selectedFiles.map((file) => file.row).concat(files.filter((file: any) => !alreadyFilesNames.includes(file.name)));
		files.forEach((row) => {
			let rowData: TableData = {
				row,
				file_name: {value: row.name},
				action: {icon: 'window-close', action: () => {this.removeSelectedFile(row.name)}}
			}
			existsFile.push(rowData);
		});
		this.selectedFiles = existsFile;
	}

	removeSelectedFile(fileName: string) {
		this.selectedFiles = this.selectedFiles.filter((file: any) => file.row.name !== fileName);
	}

	filesValidation() {
		let selectedFilesValidations = {};
		let totalSize = 0;

		if (this.selectedFiles.length > this.maxFilesCount)
			return selectedFilesValidations = { ...selectedFilesValidations, max_count: true };

		for (const file of this.selectedFiles) {
			const fileExtension = file.row.name.split('.').pop();
			if (!this.allowedUploadFilesExt.includes(fileExtension.toLowerCase()))
				selectedFilesValidations = { ...selectedFilesValidations, extension: true };

			totalSize += file.size;
		}

		if (totalSize > this.maxSize)
			selectedFilesValidations = { ...selectedFilesValidations, size: true };

		return selectedFilesValidations;
	}

	multiDevicesValidation(devicesCount: number, maxAllowedCount: number, poNumber: string) {

		// Check devices Count
		if (!devicesCount)
			return { err: this.translateService.instant('shipment.invalid_file_length') };

		// Devices in the file should not exceeded the max allowed number of devices
		if (devicesCount > maxAllowedCount)
			return { err: this.translateService.instant('shipment.csv_file_more_50_records') };

		// Check If the PO Number Selected
		if (!this.po_number)
			return { err: this.translateService.instant('orders.select_po_number') };

		// Check selected order availability
		if (devicesCount > this.getOrderAvailableDevices(poNumber, null))
			return { err: this.translateService.instant('shipment.devices_exceeded_po_remaining') };

		return {};
	}

	async prepareMultiDevicesForShipment(event: any) {
		
		// Get the file and check if the file exists
		const file = event?.target?.files[0];
		if (!file)
			return this.notificationMessage.setMessage('translate|ready_to_ship.no_selected_files', { clearOnXTimeNavigate: 1 });

		const fileExtension = file.name.split('.').pop();
		const allowedFilesExt = ['csv'];
		if (!allowedFilesExt.includes(fileExtension.toLowerCase())) {
			event.target.value = '';
			this.labelImport.nativeElement.innerText = this.translateService.instant('shipment.import_devices_csv');
			return this.notificationMessage.setMessage('translate|shipment.invalid_file_extension', { clearOnXTimeNavigate: 1 });
		}

		this.labelImport.nativeElement.innerText = file.name;

		const fileAsText = (await file.text()).trim();
		let csvData: string[] = fileAsText.split("\n").map((item: string) => item.replace('\r', '').trim()).filter((item: string) => item && item != '');
		csvData = [...new Set(csvData)];

		const validation = this.multiDevicesValidation(csvData.length, this.maxDevicesCountPerFile, this.po_number);
		if (validation?.err) {
			event.target.value = '';
			this.labelImport.nativeElement.innerText = this.translateService.instant('shipment.import_devices_csv');
			return this.notificationMessage.setMessage(validation?.err, { clearOnXTimeNavigate: 1 });
		}

		const deviceIds = [];
		for (const row of csvData) {
			const macSerialObj = this.commonService.adjustMacSearchString(row);
			if (macSerialObj.mac_address)
				deviceIds.push(macSerialObj.mac_address);
			else
				deviceIds.push(macSerialObj);
		}

		this.multiSearchDeviceSubscription = this.deviceManagementService.searchDevices(deviceIds, this.searchBy, false, false, 'shipping-out', true).subscribe((data: any) => {
			
			const devicesInfo = data.devices || [];

			// Check if devices not exists in the system
			if (devicesInfo.length != csvData.length) {
				const macAddresses: string[] = [];
				const serialNumbers: string[]= [];
				const mixMacsAndSerials: string[]= [];
				const mixSerialsAndMacs: string[]= [];

				for (const device of devicesInfo) {
					macAddresses.push(device.mac_address);
					serialNumbers.push(device.serial_number);
					mixMacsAndSerials.push(`${device.mac_address}${device.serial_number}`);
					mixSerialsAndMacs.push(`${device.serial_number}${device.mac_address}`);
				}

				const notFoundDevices = csvData.filter((deviceId: string) => {
					return !macAddresses.includes(deviceId) && !serialNumbers.includes(deviceId) && !mixMacsAndSerials.includes(deviceId) && !mixSerialsAndMacs.includes(deviceId);
				});

				if (notFoundDevices.length) {
					this.bulkSearchNotFoundDevices = notFoundDevices;
					event.target.value = '';
					this.labelImport.nativeElement.innerText = this.translateService.instant('shipment.import_devices_csv');
					this.searchResult = [];
					return;
				}
			}

			this.showUploadFileEle = false;
			this.searchResult = devicesInfo;

			this.sites = _.indexBy(data.sites || [], 'id');
			if (this.searchResult.length > 0) {
				for (const device of this.searchResult) {
					if (device.is_sct_inventory) {
						// Move the devices the right side from page
						this.prepareForShipment(device, this.sites[device.site_id]?.customer_id);
					}
				}
				event.target.value = '';
			}
		});
	}
	
	getOrderAvailableDevices(poNumber: string, type: string|null = null) {
		if (!type)
			return this.allPoNumbers[poNumber][`remaining_iotah_count`] + this.allPoNumbers[poNumber][`remaining_charglink_count`] + this.allPoNumbers[poNumber][`remaining_study_count`];

		return this.allPoNumbers[poNumber][`remaining_${type.toLocaleLowerCase()}_count`];
	}

	checkSmartRebatesShippingOption() {
		this.deviceManagementService.checkSmartRebatesShippingOption().subscribe((data: any) => {
			if (data?.err) {
				this.disableSmartRebateOption = true;
				switch(data.err) {
					case 'motive_enterprise_not_exist':
						this.smartRebatesSelectedErr = this.translateService.instant('shipment.motive_enterprise_promt_msg');
					break;
					case 'motive_customer_not_exist':
						this.smartRebatesSelectedErr = this.translateService.instant('shipment.motive_customer_promt_msg');
					break;
						case 'motive_site_not_exist':
							this.smartRebatesSelectedErr = this.translateService.instant('shipment.motive_site_promt_msg')
					break;
				}
			}
		});
	}

	updateSitesList(value: boolean) {
		this.isPowerStudiesDevices = value;
		this.onCustomerChanged();
	}

	ngOnDestroy() {
		if (this.multiSearchDeviceSubscription)
			this.multiSearchDeviceSubscription.unsubscribe();
	}
}
