<div>
	<h2>{{'nav.disable_xtra_file' | translate}}</h2>
	<div class="d-flex justify-content-end my-3" *ngIf="usersService.hasAccessPermission(null, 'noc', 'write')">
		<button class="btn btn-primary btn-sm mx-2" [disabled]="!selectedDevices.length"
			(click)="restartDeviceConfirm.show()">
			{{'devices.enable_xtra_file' | translate}}
		</button>

		<a class="btn btn-primary btn-sm"
			[routerLink]="['/management', 'devices-without-location']"
			target="_blank">
			{{'devices_without_location.title' | translate}}
		</a>
	</div>
	<sct-custom-table
		*ngIf="tableData.length; else noData"
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
		(updateSelectedRows)="updateSelectedDevices($event)"
	></sct-custom-table>
</div>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<app-modal #restartDeviceConfirm [title]="'devices.restart_required' | translate">
	<p>
		{{'devices.restart_device_confirm_message'| translate }}
	</p>
	<ul>
		<li>{{'devices.disable_xtra' | translate}}</li>
	</ul>
</app-modal>
