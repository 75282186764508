import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AdminService } from '../../admin.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { TableConfig, ColumnsConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import * as moment from 'moment';
@Component({
	selector: 'app-error-log',
	templateUrl: './error-log.component.html',
	styleUrls: ['./error-log.component.css']
})
export class ErrorLogComponent implements OnInit, AfterViewInit {

	filterOptions = ['Date', 'User ID', 'Server IP', 'Function Name'];
	getBy: string = 'Date';
	value: string;
	type: string = 'errors_log';
	fromDate: Date;
	toDate: Date;
	selectedErrors: Array<number> = [];
	logInfo: any = [];
	selectAll: Boolean;
	columnTable: ColumnsConfig[]= [
		{ key: 'id', name: 'id', type: 'id', hidden: true},
		{ key: 'error_id', name: this.translate.instant('g.id'), type: 'number', hasSort: true, hasFilter: true},
		{ key: 'error_time', name: this.translate.instant('g.time'), type: 'timestamp', dateFormat: 'MM/DD/YYYY hh:mm:ss A', hasSort: true, hasFilter: true},
		{ key: 'function_name', name: this.translate.instant('error_log.function_name'), type: 'string', hasSort: true, hasFilter: true},
		{ key: 'main_caller', name: this.translate.instant('error_log.main_caller'), type: 'string', hasSort: true, hasFilter: true},
		{ key: 'user_id', name: this.translate.instant('users.user_id'), type: 'number', hasSort: true, hasFilter: true},
		{ key: 'server_ip', name: this.translate.instant('error_log.server_ip'), type: 'string', hasSort: true, hasFilter: true},
		{ key: 'is_sched', name: this.translate.instant('error_log.is_sched'), type: 'boolean', hasSort: true, hasFilter: true},
		{ key: 'error', name: this.translate.instant('error_log.error'), type: 'string', hasSort: true, hasFilter: true},
		{ key: 'options', name: this.translate.instant('g.options'), type: 'dropdown', hasSort: true, hasFilter: true},
	];
	
	tableConfig: TableConfig = {
		hasPagination: true,
		pageSize: 10,
		hasSelectionColumn: true,
		fitScreen: true,
		draggable: true
	};
	selectedLogsIds: any;

	@ViewChild('dataTable') dataTable;
	@ViewChild("confirmModal") confirmModal;

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService,
		private router: Router,
		private usersService: UsersService,
		private translate: TranslateService
	) {
	}

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('access_error_logs'))
			return this.router.navigate(['/unauthorized']);
		this.getLoggingInfo();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			if(result[1][0] == 'delete') {
				return this.delete(result[1][1], result[1][2]);
			}

			if(result[1][0] == 'deleteLogs') {
				return this.deleteLogs();
			}
		});
	}

	confirmDeleteLogs(action, data1?, data2?) {
		this.confirmModal.passData = [action, data1, data2];
		this.confirmModal.message = 'g.confirm_delete_message';
		this.confirmModal.show();
	}

	setTab(tab){
		switch (tab) {
			case 'errors':
				this.filterOptions = ['Date', 'User ID', 'Server IP', 'Function Name'];
				this.type = 'errors_log';
				break;
			case 'sendPushNotification':
				this.filterOptions = ['Date', 'User ID', 'Server IP'];
				this.type = 'push_notification_error_log';
				break;
		}
		this.getBy = 'Date';
		this.resetSelects();
		this.getLoggingInfo();
	}

	getLoggingInfo() {
		this.logInfo = [];
		let options = {
			fromDate: "",
			toDate: "",
			value: "",
			type: this.type,
			getBy: this.getBy
		};
		if(this.getBy == 'Date') {
			
			if(!this.fromDate || !this.toDate) {
				this.fromDate = new Date(new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0));
				this.toDate = new Date(new Date().setHours(23,59,59,999));
			}

			options.fromDate  = Math.floor(this.fromDate.getTime() / 1000)+'';
			options.toDate    = Math.floor(this.toDate.getTime() / 1000)+'';
		} else if (this.getBy == 'User ID') {
			options.value = (parseInt(this.value) || 0) + "";
		} else {
			options.value = this.value;
		}
		
		this.adminService.getLoggingInfo(options).subscribe(
			(data: any) => {
				const logsInfo = data.slice();
				[...logsInfo].forEach((row) => {
					let rowData: TableData = {
						row,
						id: {value: row.id},
						error_id: {value: row.id},
						error_time: {value: moment(row.error_time).local().format('MM/DD/YYYY hh:mm:ss A')},
						function_name: {value: row.function_name},
						main_caller: {value: row.main_caller},
						user_id: {value: row.user_id},
						server_ip: {value: row.server_ip},
						is_sched: {value: row.is_sched, trueText: this.translate.instant('g.yes'), falseText: this.translate.instant('g.no')},
						error: {value: row.error && row.error.length > 50? row.error.slice(0, 50) : row.error },
						options: {value: null, options: 
									[ 
										{ text: this.translate.instant('g.view'), action: (data) => {this.viewErrorDetailsInfo(data.row)}},
										{ text: this.translate.instant('g.delete'), action: (data) => {this.deleteErrorRecord(data.row)}}
									]
								}
					}
						this.logInfo.push(rowData);
				});
			}
		);
	}

	delete(type, id) {
		this.adminService.deleteLog(id, type).subscribe(
			(isDeleted: any) => {
				if(isDeleted.result) {
					let logInfo = this.logInfo.slice();
					for(let i in logInfo) {
						if (logInfo[i].id == id) {
							logInfo.splice(+i, 1);
							this.logInfo = logInfo.slice();
							break;
						}
					}
					this.notificationMessage.setMessage(this.translate.instant('g.deleted_successfully'),{clearOnXTimeNavigate: 1, type: 'success'});
				}
			}
		);
	}

	deleteLogs() {
		this.adminService.deleteLog(this.selectedLogsIds, this.type).subscribe(
			(isDeleted: any) => {
				if(isDeleted.result) {
					this.notificationMessage.setMessage(this.translate.instant('g.deleted_successfully'),{clearOnXTimeNavigate: 1, type: 'success'});
					this.dataTable.clearSelection();
					this.getLoggingInfo();
				}
			}
		);
	}

	viewErrorDetailsInfo(record) {
		let url = this.router.createUrlTree([record.id], {relativeTo: this.route});
		return window.open('#'+url.toString(), "_blank");
	}

	deleteErrorRecord(record) {
		return this.confirmDeleteLogs('delete', this.type, record.id);
	}

	resetSelects(){
		this.value = '';
	}
}