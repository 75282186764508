<h2>{{'apis_logs.title' | translate}}</h2>

<div class="mt-3 mb-2 ml-2 mr-2">
	<sct-custom-table
		#sctCustomTable
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
		(backEndPaginationFunc)="getLogs($event)"
	></sct-custom-table>
</div>
