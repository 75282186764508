<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<sct-custom-table *ngIf="log.length; else noData"
		[data]="log"
		[columnsConfig]="column"
		[config]="tableConfig">
	</sct-custom-table>

</div>

<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>