import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { TableConfig, TableData, ColumnsConfig } from 'src/app/shared/custom-table/custom-table-interface';
import * as moment from 'moment';
@Component({
	selector: 'app-sched-monitor',
	templateUrl: './sched-monitor.component.html',
})
export class SchedMonitorComponent implements OnInit {

	schedFunctions = <any>[];
	columnConfig: ColumnsConfig[] = [
		{ key: 'function_name', name: this.translateService.instant('sched_monitor.function_name'), type: 'string', hasSort: true, hasFilter: true},
		{ key: 'server', name: this.translateService.instant('sched_monitor.server'), type: 'string', hasSort: true, hasFilter: true},
		{ key: 'status', name: this.translateService.instant('sched_monitor.function_status'), type: 'string', hasSort: true, hasFilter: true},
		{ key: 'last_call', name: this.translateService.instant('sched_monitor.last_call_time'), type: 'date', dateFormat: 'MM/DD/YYYY hh:mm:ss A', hasSort: true, hasFilter: true},
		{ key: 'next_call', name: this.translateService.instant('sched_monitor.next_time'), type: 'date', dateFormat: 'MM/DD/YYYY hh:mm:ss A', hasSort: true, hasFilter: true},
		{ key: 'frequency', name: this.translateService.instant('sched_monitor.frequency'), type: 'number', hasSort: true, hasFilter: true},
		{ key: 'frequency_seconds', name: this.translateService.instant('sched_monitor.frequency_seconds'), type: 'number', hasSort: true, hasFilter: true},
		{ key: 'schedule_time', name: this.translateService.instant('sched_monitor.secdule_time'), type: 'string', hasSort: true, hasFilter: true},
		{ key: 'email_sent_time', name: this.translateService.instant('sched_monitor.email_sent_time'), type: 'date', dateFormat: 'MM/DD/YYYY hh:mm:ss A', hasSort: true, hasFilter: true},
	];

	config: TableConfig = {
		hasExport: false,
	};

	constructor(
		private router: Router,
		private usersService: UsersService,
		private adminService: AdminService,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('sched_functions_control'))
			return this.router.navigate(['/unauthorized']);

		this.schedFunctions = [];
		this.adminService.getCronJobsStatus().subscribe(
			(data: any) => {
				[...data ].forEach((row) => {
					let rowData: TableData = {
						row,
						function_name: {value: row.name},
						server: {value: row.server},
						status: {value: row.runningStatus},
						last_call: {value: (!row.lastCallTime? '-' : moment(row.lastCallTime*1000).local().format('MM/DD/YYYY hh:mm:ss A'))},
						next_call: {value: moment(row.nextCallTime*1000).local().format('MM/DD/YYYY hh:mm:ss A')},
						frequency: {value: row.frequency},
						frequency_seconds: {value: row.frequencyInSec},
						schedule_time: {value: row.time},
						email_sent_time: {value: (!row.emailSentTime? '-' :moment(row.emailSentTime*1000).local().format('MM/DD/YYYY hh:mm:ss A'))}
					};
					this.schedFunctions.push(rowData);
				});
			}
		);
	}
}
