<app-modal #setSnModal title="{{'actions.set_serial_number' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false">
	<label for="serial_number">{{'g.serial_number' | translate}}</label>
	<input type="text" class="form-control mr-2" name="newSerialNumber" [(ngModel)]="newSerialNumber">
	<p *ngIf="snIsInvalid" class="text-danger">{{'g.invalid_serial_number' | translate}}!</p>
	<p class="mt-2 text-danger">{{'actions.change_sn_message' | translate}}</p>
	<div class="modal-footer">
		<button class="btn btn-default mr-2" (click)="closeSetSnModal()">{{'g.cancel' | translate}}</button>
		<button class="btn btn-primary" (click)="setDeviceSN()" [disabled]="!newSerialNumber">{{'g.ok' | translate}}</button>
	</div>
</app-modal>

<app-modal #updatePasswordModal title="{{'settings.update_password' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="mb-5">
		<div class="row">
			<div class="col-md-9">
				<input type="password" class="form-control" [(ngModel)]="setPasswordModal.password" name="setPasswordModal.password" placeholder="{{'users.new_password' | translate}}" minlength="0" maxlength="15" (ngModelChange)="setPasswordModal.isValid = true" show-password/>
				<div class="text-danger mt-2" *ngIf="!setPasswordModal.isValid">{{'g.invalid_password' | translate}}!</div>
			</div>
			<div class="col-md-3">
				<password-strength [password]="setPasswordModal.password"></password-strength>
			</div>
		</div>
		<small>{{'password_field.format' | translate}}</small>
	</div>
	<div class="modal-footer">
		<button class="btn btn-default mr-2" (click)="closeUpdatePasswordModal()">{{'g.cancel' | translate}}</button>
		<button class="btn btn-primary" (click)="updatePassword()" [disabled]="!setPasswordModal.isValid">{{'g.ok' | translate}}</button>
	</div>
</app-modal>

<app-modal #pushFWModal title="{{'actions.push_firmware' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false">
	<label for="serial_number">{{'devices.fw_version' | translate}}</label> {{commonUtil.toFixedWithoutRounding(device['config_info']?.fw_version || 0, isAdmin ? 4 : 2)}} <span *ngIf="deviceFWVersionToPush" class="text-muted">, {{'settings.fw_version_to_push' | translate}}: {{commonUtil.toFixedWithoutRounding(deviceFWVersionToPush || 0, isAdmin ? 4 : 2)}}</span>
	<select class="form-control mr-2" name="firmwareVersionToPush" [(ngModel)]="firmwareVersionToPush">
		<option *ngFor="let version of filterVersions(deviceFWVersionToPush)" [ngValue]="version">{{version}}</option>
	</select>
	<div class="modal-footer">
		<button class="btn btn-default mr-2" (click)="closePushFWModal()">{{'g.cancel' | translate}}</button>
		<button class="btn btn-primary" (click)="pushFirmwareUpdate()" [disabled]="!firmwareVersionToPush">{{'settings.push_firmware' | translate}}</button>
	</div>
</app-modal>

<app-modal #lastPMModal title="{{'actions.set_last_maintenance_date' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="mb-5">
		<input
			class="form-control"
			[ngClass]="{'invalid-input' : !lastPMModalVars.isValid}"
			name="last_pm_date"
			placeholder="mm/dd/yyyy"
			[(ngModel)]="lastPMModalVars.lastPMDate"
			bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [minDate]="lastPMModalVars.min" [maxDate]="lastPMModalVars.max"
			(ngModelChange)="lastPMModalVars.isValid=true"
			check-permission="basic_setup"
			calender
		/>
		<p class="text-danger mt-2" *ngIf="!lastPMModalVars.isValid">{{'g.invalid_input' | translate}}!</p>
	</div>
	<div class="modal-footer">
		<button class="btn btn-default mr-2" (click)="closeLastPMModal()">{{'g.cancel' | translate}}</button>
		<button class="btn btn-primary" (click)="setLastPMDate()" [disabled]="!lastPMModalVars.isValid">{{'g.ok' | translate}}</button>
	</div>
</app-modal>

<div class="btn-group mx-3" dropdown>
	<fa-icon icon="cog" size="lg" dropdownToggle class="pointer"></fa-icon>
	<ul *dropdownMenu class="dropdown-menu pull-menu-left device-actions-menue" role="menu">
		<li role="menuitem" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'restart_device')">
			<a class="dropdown-item pointer" (click)="sendCommand('restartDevice')">{{'actions.restart_device' | translate}}</a>
		</li>
		<li role="menuitem">
			<a class="dropdown-item pointer" (click)="sendCommand('indicatorLight')">{{'actions.indicator_light' | translate}}</a>
		</li>
		<li role="menuitem" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'serial_number', 'write')">
			<a class="dropdown-item pointer"  (click)="openSetSnModal()">{{'actions.set_serial_number' | translate}}</a>
		</li>
		<li role="menuitem" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'factory_reset')">
			<a class="dropdown-item pointer" (click)="confirmActionModal.message = this.confirmActions.resetDataHistory; confirmActionModal.passData = ['resetDataHistory', {'restart': true}]; confirmActionModal.show();">{{'actions.reset_data_history' | translate}}</a>
		</li>
		<li role="menuitem" *ngIf="false && usersService.hasAccessPermission(enterprisePermissions, 'update_firmware_from_file') && usersService.hasAccessPermission(enterprisePermissions, 'fw_version') && device['config_info']?.fw_version">
			<a class="dropdown-item pointer"  (click)="openPushFWModal()">{{'actions.push_firmware' | translate}}</a>
		</li>
		<li role="menuitem" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'factory_reset', 'write')">
			<a class="dropdown-item pointer" (click)="confirmActionModal.message = this.confirmActions.factoryReset; confirmActionModal.passData = ['factoryReset', {}]; confirmActionModal.show();">{{'actions.factory_reset' | translate}}</a>
		</li>
		<li role="menuitem" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'device_password', 'write')">
			<a class="dropdown-item pointer" (click)="updatePasswordModal.show(); setPasswordModal.password='';">{{'settings.update_password' | translate}}</a>
		</li>
		<li role="menuitem" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_data', 'write') && !isProduction">
			<a class="dropdown-item pointer" (click)="goToPage('set-adc')">{{'nav.set_adc' | translate}}</a>
		</li>
		<li role="menuitem" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'basic_setup', 'write') && !device.config_info.is_charglink">
			<a class="dropdown-item pointer" (click)="openLastPMModal()">{{'actions.set_last_maintenance_date' | translate}}</a>
		</li>
		<li role="menuitem" *ngIf="deviceService.showManageDeviceOption(device.config_info, siteInfo); else requestManage">
			<a class="dropdown-item pointer" [routerLink]="['/device', 'management', device.mac_address]">{{'devices.manage_device' | translate}}</a>
		</li>
		<li *ngIf="usersService.hasAccessFunction('reset_rtc_lost_detection') && device.config_info.lost_rtc_time && device.config_info.last_cm_date_tested < device.config_info.lost_rtc_time">
			<a class="dropdown-item pointer" (click)="resetRTCLostDetectionModal.passData = [{'reset': true}]; resetRTCLostDetectionModal.show()">{{'actions.reset_rtc_lost_detection' | translate}}</a>
		</li>
		<li *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'debug_data') && device.config_info.client_socket_type == commonData.CLIENT_SOCKET_TYPE.CELLULAR">
			<a class="dropdown-item pointer" [routerLink]="['/', customerId, siteInfo?.id, device.mac_address, 'cellular-settings']" [queryParams]="{ liveInfo: true }">{{'actions.query_cellular_info' | translate}}</a>
		</li>
		<li role="menuitem" *ngIf="usersService.hasAccessFunction('super_admin', 'write')">
			<a class="dropdown-item pointer" (click)="resetCellularOEM();">{{'nav.reset_cellular_oem' | translate}}</a>
		</li>
		<li *ngIf="showConnectCellularOption">
			<a class="dropdown-item pointer" (click)="addDeviceToWakeUpList()">{{'sim.connect_cellular' | translate}}</a>
		</li>
		<li role="menuitem" *ngIf="usersService.hasAccessPermission(enterprisePermissions, 'noc', 'write') && device['config_info']?.has_rtc_time_drift_warning">
			<a class="dropdown-item pointer" (click)="clearRTCTimeDriftWarning()">{{'settings.clear_rtc_time_drift_warning' | translate}}</a>
		</li>
		<li role="menuitem" *ngIf="showHideCalibrationPointsButton()">
			<a class="dropdown-item pointer" (click)="confirmSetCalibrationPoints.show()" [class.disabled]="isCalStatusQueued || changesStack.cal_status">{{ 'devices.disable_calibration_points' | translate }}</a>
		</li>
		<ng-template #requestManage>
			<li role="menuitem" *ngIf="!siteSpecial || siteInfo.name == commonUtil.powerStudiesDevicesSiteName">
				<a class="dropdown-item pointer" [routerLink]="['/device', 'management-request', device.mac_address]">{{'devices.request_manage_device' | translate}}</a>
			</li>
		</ng-template>
	</ul>
</div>

<app-modal #confirmActionModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>

<app-modal #resetRTCLostDetectionModal title="{{'devices.confirm_reset_rtc_lost_detection' | translate}}" [closeBtnText]="'g.cancel' | translate">
	{{'settings.reset_rtc_lost_detection_prompt' | translate}}
</app-modal>

<app-modal #confirmSetCalibrationPoints title="{{'g.confirm_action' | translate}}">
	<p>
		{{('sim.confirmation_massage_1' | translate) + " " + ('devices.disable_calibration_points' | translate).toLowerCase() +"?"}}
	</p>
</app-modal>
