<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<div class="mt-5">
		<h2>{{'nav.registration_reports' | translate}}</h2>
	</div>

	<sct-custom-table 
		*ngIf="sites.length; else noData"
		[columnsConfig]="columnConfig"
		[data]="sites">
	</sct-custom-table>
</div>

<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>