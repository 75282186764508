import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as lodash from 'lodash-es';
import { CommonService } from 'src/app/shared/services/common.service';
import * as moment from 'moment';
import { CustomHttpParams } from 'src/app/shared/loader/custom-httpParam';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import { SitesService } from 'src/app/sites/sites.service';
import { BehaviorSubject } from 'rxjs';
import objectMappers from 'src/app/shared/services/mapper.json';

export interface DeviceCommand {
	id: number;
	mac_address: string;
	user_id: string;
	commands: {
		cmds: string[];
	};
	changes: {
		[key: string]: any;
	};
	time: string;
	wait_time: number;
	command_info: Record<string, any>;
	serial_number: string;
	siteId: number;
	customerId: number;
	user_name: string;
	customer_name: string;
	site_name: string;
	expiry_date: number;
	last_connect_time: number
}

@Injectable({
	providedIn: 'root'
})
export class DeviceService {
	private updateConfigDataSbj = new BehaviorSubject<{isDone: boolean, data: Object}>({isDone: false, data: {}});
	updateConfigData = this.updateConfigDataSbj.asObservable();
	route = '/device/';

	// event related params
	notDisplayedEventFields = [
		"event_flags",
		"global_event_flags",
		"my_zone_id",
		"isValidCRC16",
		"is_ok_record",
		"previous_non_same_event_type",
		"end_timestamp_utc",
		"max_temperature_recorded_timestamp_utc",
		"timestamp_utc",
		"safe_close",
		'end_soc',
		'soc_set',
		'start_soc',
		'calc_duration',
		'bv_timestamp_utc'
	];
	eventCalculatedFieldsMapper = {
		inuse_as: "inuse_ahr",
		inuse_ws: "inuse_kwhr",
		charge_as: "charge_ahr",
		charge_ws: "charge_kwhr"
	}
	eventsDefaultFields = [
		"sequence_id",
		"timestamp",
		"event_type",
		"end_timestamp",
		"event_duration",
		"inuse_ahr",
		"inuse_kwhr",
		"charge_ahr",
		"charge_kwhr",
		"billed_kwh",
		"accurate_inuse_seconds",
		"start_voltage",
		"min_voltage",
		"end_voltage",
		"event_max_current",
		"event_min_current",
		"event_average_current",
		"insertion_time",
		"accurate_charge_seconds",
		'latitude',
		'longitude',
		"system_start",
		"fw_update",
		"set_rtc",
		"lift_time",
		"travel_time"
	];
	eventsNocAccessFields = [
		'billed_kwh',
		'event_min_current'
	];
	eventsAdminFields = [
		'latitude',
		'longitude',
	];
	eventTypes = {
		1: 'charge',
		2: 'idle',
		3: 'inuse',
	};
	justIotahDevicesEventFields: string[] = [
		'billed_kwh',
		'lift_time',
		'travel_time',
		'accurate_inuse_seconds',
	];

	batteryTypes = {
		1: 'lead',
		3: 'lithuim_ion',
		4: 'n/a',
	};

	eventTypesByName = {
		charge: 1,
		idle: 2,
		inuse: 3
	};
	batteryTypesByName = {
		lead: 0,
		lithuim_ion: 2,
		other: 3
	};

	deviceBooleanFields = [
		'in_test_mode', 'setup_done', 'setup_reset_data', 'replacement_part', 'enable_rt', 'has_water_level',
		'has_hall_effect', 'has_temperature_sensor', 'has_current_low_range', 'enable_multicast', 'enable_wifi_sta',
		'enable_wifi_ap_on_start', 'enable_serversocket', 'is_online_enabled', 'enable_cellular', 'is_charglink',
		'disable_xtra', 'disable_external_rtc'
	];
	deviceDateFields = [
		'installation_date',
		'setup_time',
		'last_pm_date',
		'mis_voltage_time',
		'mis_capacity_time',
	];
	deviceTimeReferenceFields = [
		'last_update_location_time',
	];
	deviceZoneFields = [
		'zone_id',
	];

	objectFields = [
		'mobile_master',
		'mobile_network',
		'router_network',
		'online_network',
		'mp_cal_high_range',
		'mp_cal_low_range'
	];

	deviceFieldsLists = {
		battery_type: {
			'0': this.translate.instant('events.battery_type_0'),
			'2': this.translate.instant('events.battery_type_2'),
		},
		priority: {
			'0': this.translate.instant('priority_options.0'),
			'1': this.translate.instant('priority_options.1'),
			'2': this.translate.instant('priority_options.2'),
			'3': this.translate.instant('priority_options.3'),
			'10': this.translate.instant('priority_options.10'),
		},
	};

	wifiAuthMode = {
		"open": "Open",
		"psk": "PSK",
		"wep": "WEP",
		"wpa2_enterprise": "WPA2 ENTERPRISE",
		"wpa2_psk": "WPA2 PSK",
		"wpa_wpa2_psk": "WPA/WPA2 PSK"
	};

	multiSettingsFields = {
		deviceInfo: [
			'installation_date_fmt', 'truck_manufacturer', 'truck_manufacturing_year', 'truck_type', 'last_pm_date_fmt'
		],
		deviceSettings: [
			'current_idle_to_charge', 'current_idle_to_inuse', 'current_charge_to_idle', 'current_charge_to_inuse', 'current_inuse_to_charge',
			'current_inuse_to_idle', 'remote_flash_timer', 'dead_man_current_limit', 'charge_to_idle_timer', 'charge_to_inuse_timer',
			'inuse_to_charge_timer', 'inuse_to_idle_timer', 'idle_to_charge_timer', 'idle_to_inuse_timer', 'zone_id', 'enable_rt', 'rt_log_frequency',
			'has_water_level', 'has_hall_effect', 'has_temperature_sensor', 'has_current_low_range', 'disable_external_rtc', 'disable_xtra'
		],
		batterySettings: [
			'vpc', 'battery_capacity', 'battery_type', 'number_of_cells'
		],
		cal: [
			'current_drift_adc_low_range', 'voltage_drift_adc', 'voltage_scale_adc', 'current_drift_adc', 'current_scale_adc', 'current_scale_adc_low_range'
		],
		networkingSettings: [
			'soft_ap_exit', 'soft_ap_exit_no_setup', 'cellular_region', 'cell_access_point_name', 'lte_cellular_bands', 'nbiot_cellular_bands', 'cellular_reconnect_time', 'metered_flag'
		],
		networking: [
			'online_network.ssid', 'online_network.bssid', 'online_network.password', 'online_network.priority', 'online_network.type'
		],
		billedKWHrsSettings: [
			'charger_efficiency', 'battery_efficiency', 'return_factor'
		],
		maintenanceSchedule: [
			'report_ahr_opt', 'report_kwhr_opt', 'report_usage_hours_opt', 'report_period_opt',
			'report_ahr', 'report_kwhr', 'report_usage_hours', 'report_period',
		],
	};

	cellularBandsFields = ['lte_cellular_bands', 'nbiot_cellular_bands'];

	waitTimeout = 45;

	deviceWarranty = 1; //In years

	oneDayDisconnect: number = 24 * 60 * 60;
	threeDaysDisconnect: number = 3 * (24 * 60 * 60);
	sevenDayDisconnect: number = 7 * (24 * 60 * 60);
	thirtyDayDisconnected: number = 30 * (24 * 60 * 60);
	sixMonths: number = this.oneDayDisconnect * 180;

	readonly deviceCommandsResponse = 2;

	readonly rt_log_frequency_units = {
		seconds: 1,
		minutes: 2
	};

	constructor(
		private httpClient: HttpClient,
		private translate: TranslateService,
		private commonUtil: CommonService,
		private usersService: UsersService,
		private sitesService: SitesService,
	) { }

	formatDeletedMACandSN(text: string){
		let res = this.translate.instant('g.deleted')+': ';
		let tmp1 = text.substr(3);
		let tmp2 = tmp1.split('-');
		res += tmp2[0] + ' (' + moment(+tmp2[1] * 1000).utc().format('MM/DD/YYYY hh:mm:ss A') + ')'
		return res;
	}

	updateDeviceConfig(data) {
		this.updateConfigDataSbj.next({isDone : true, data: data})
	}

	formatDeviceFields(device) {
		let zoneDiff = new Date().getTimezoneOffset();
		for(let field of this.deviceDateFields) {
			if(device[field] !== undefined) {
				device[field] += (zoneDiff * 60);
				device[field+'_fmt'] = moment(device[field]*1000).format('MM/DD/YYYY');
			}
		}
		for(let field of this.deviceTimeReferenceFields) {
			if(device[field] !== undefined) {
				device[field+'_fmt'] = `${moment(device[field]*1000).format('MM/DD/YYYY hh:mm:ss A')} (${this.commonUtil.showUserTimeZoneReference()})`;
			}
		}

		device.rt_log_frequency_unit = this.rt_log_frequency_units.seconds;
		device.rt_log_frequency_fmt = device.rt_log_frequency;
		if([60, 120, 180, 240].includes(device.rt_log_frequency)) {
			device.rt_log_frequency_unit = this.rt_log_frequency_units.minutes;
			device.rt_log_frequency_fmt = device.rt_log_frequency / 60;
		}
	}

	formatWifiAuthMode(authMode) {
		let mode = '-';
		switch(authMode) {
			case 0: mode = this.wifiAuthMode.open; break;
			case 1: mode = this.wifiAuthMode.wep; break;
			case 2: mode = this.wifiAuthMode.psk; break;
			case 3: mode = this.wifiAuthMode.wpa2_psk; break;
			case 4: mode = this.wifiAuthMode.wpa_wpa2_psk; break;
			case 5: mode = this.wifiAuthMode.wpa2_enterprise; break;
		}
		return mode;
	}

	formatWifiSignalStrength(rssi) {
		let rssiMessage;
		if(rssi >= -60)
			rssiMessage = this.translate.instant('g.excellent');
		else if (rssi >= -72)
			rssiMessage = this.translate.instant('g.very_good');
		else if (rssi >= -75)
			rssiMessage = this.translate.instant('g.okay');
		else if (rssi >= -85)
			rssiMessage = this.translate.instant('g.weak');
		else
			rssiMessage = this.translate.instant('g.unusable');
		return rssiMessage;
	}

	formatWifiNetworks(networks) {
		networks = networks || [];
		networks.forEach((network) => {
			network.signalStrength = this.formatWifiSignalStrength(network.rssi) + '('+network.rssi+')';
			network.authMode = this.formatWifiAuthMode(network.auth_mode);
		});
		return networks;
	}

	isNewDevice(year) {
		return !year || year == 0 || year >= 2010;
	}

	calculateBilledKWHr(totalKWHr, device) {
		if(device.return_factor && device.charger_efficiency && device.battery_efficiency)
			return (totalKWHr * device.return_factor / (device.charger_efficiency*device.battery_efficiency));
		return;
	}

	calculateCO2(billedKwhr) {
		return this.commonUtil.lbToMt(billedKwhr * 7.03 * 2204.62 / 10000);
	}

	validateLastPMDate(lastPMDate, installationDate) {
		let invalidFields = this.commonUtil.validateDeviceSettings({last_pm_date: lastPMDate});
		return invalidFields.length == 0 && lastPMDate >= moment(installationDate*1000).startOf('day').unix() && lastPMDate <= moment().endOf('day').unix();
	}

	/**
	 * Check if day should be considered a working day
	 * @param time timestamp
	 * @param siteWD Site working day option
	 * @param isOpWD Is operation working day
	 */
	isWorkingDay(time, siteWD, isOpWD) {
		let day = moment(time * 1000).isoWeekday();
		let isWD = false;
		switch(siteWD) {
			case 'force_5': // Force 5 days/week [Mon - Fri (1-5)]
				isWD = (day < 6);
			break;
			case 'force_6': // Force 6 days/week [Mon - Sat]
				isWD = (day < 7);
			break;
			case 'force_7': // Force 7 days/week
				isWD = true;
			break;
			case 'operation_6': // Check operation but never less than 6 days/week
				isWD = (isOpWD || day < 7);
			break;
			case 'operation': // Check Operation
				isWD = !!isOpWD;
			break;
			default: // Check operation but never less than 5 days/week - Default-
				// case 'operation_5'
				isWD = (isOpWD || day < 6);
			break;
		}
		return isWD;
	}

	getDeviceInfo(mac_address, options?) {
		return this.httpClient.post(this.route + 'getDeviceInfo', {mac_address, options}, {
			observe: "body"
		});
	}

	getDevicesInfoBySerialNumber(serialsArray: string[]) {
		return this.httpClient.post(this.route + 'getDevicesInfoBySerialNumber', {serialsArray}, {
			observe: "body"
		});
	}
	getDisableExternalRtcDevices() {
		return this.httpClient.post(this.route + 'getDisableExternalRtcDevices', {}, {
			observe: "body"
		});
	}

	getDisableXTRAFileDevices() {
		return this.httpClient.post(this.route + 'getDisableXTRAFileDevices', {}, {
			observe: "body"
		});
	}

	enableExternalRTC(macAddresses) {
		return this.httpClient.post(this.route + 'enableExternalRTC', {macAddresses}, {
			observe: "body"
		});
	}

	enableExtraFile(macAddresses) {
		return this.httpClient.post(this.route + 'enableExtraFile', {macAddresses}, {
			observe: "body"
		});
	}

	getDisconnectedDevicesReport() {
		return this.httpClient.post(this.route + 'getDisconnectedDevicesReport', {}, {
			observe: "body"
		});
	}

	getDisconnectedSitesReport(filterOptions: {percentage: number, daysCount: number, siteStateFilter: string[], isSmartRebatesSitesFilter: boolean}) {
		return this.httpClient.post('/reporting/getDisconnectedSitesReport', { filterOptions }, {
			observe: "body"
		});
	}

	getDisconnectedSitesReportAsPDF(filterOptions: { percentage: number, daysCount: number, siteStateFilter: string[], isSmartRebatesSitesFilter: boolean }) {
		return this.httpClient.post('/reporting/getDisconnectedSitesReportAsPDF', { filterOptions }, {
			observe: "body"
		});
	}

	getMultiDevicesInfo(deviceIds) {
		return this.httpClient.post(this.route + 'getMultiDevicesInfo', {deviceIds}, {
			observe: "body"
		});
	}

	getWarnings(filter: any) {
		return this.httpClient.post(this.route + 'getWarnings', {filter}, {
			observe: "body"
		});
	}

	acknowledgeWarnings(warning_id: string, mac_address: string) {
		return this.httpClient.post(this.route + 'acknowledgeWarnings', {warning_id, mac_address}, {
			observe: "body"
		});
	}

	getQuickView(mac_address, noUiBlock) {
		return this.httpClient.post(this.route + 'getQuickView', {mac_address}, {
			observe: "body",
			params: new CustomHttpParams({noUIBlock: noUiBlock})
		});
	}

	getEvents(mac_address, fromTime, toTime, studyId=null) {
		return this.httpClient.post(this.route + 'getEvents', {mac_address, fromTime, toTime, studyId}, {
			observe: "body"
		});
	}

	getIncorrectEvents(options: { year: number, quarter: number, is_charglink: boolean }) {
		return this.httpClient.get(this.route + 'get-incorrect-events', {
			observe: "body",
			params: options,
		});
	}

	getDevicesUsageTime(deviceId: string[], siteId: number, startTime: number, endTime: number, {truckYear, truckType, truckTag, parentPage}) {
		return this.httpClient.post(this.route + 'get-devices-usage-time', {deviceId, siteId, startTime, endTime, truckYear, truckType, truckTag, parentPage}, {
			observe: "body",
		});
	}

	queueExportEventsRequest(selectedColumnsFields: string[], macAddressesList: number[], dateRange: {to: number, from: number}, siteId: number) {
		return this.httpClient.post(this.route + 'queueExportEventsRequest', {selectedColumnsFields, macAddressesList, dateRange, siteId}, {
			observe: "body"
		});
	}

	getDailyDetails(mac_address, fromTime, toTime, options?, studyId=0) {
		return this.httpClient.post(this.route + 'getDailyDetails', {mac_address, fromTime, toTime, studyId, options}, {
			observe: "body"
		});
	}

	getPerformanceAnalytics(mac_address, studyId = 0) {
		return this.httpClient.post(this.route + 'getPerformanceAnalytics', {mac_address, studyId}, {
			observe: "body"
		});
	}

	sendCommand(mac_address, command, options?, blockUI = true) {
		return this.httpClient.post(this.route + 'sendAction/' + command, {mac_address, options, wait: (['quickView', 'queryCellularInfo'].includes(command) ? 0 : this.waitTimeout)}, {
			observe: "body",
			params: new CustomHttpParams({noUIBlock: !blockUI})
		});
	}

	getRTs(mac_address, fromTime) {
		return this.httpClient.post(this.route + 'getRT', {mac_address, fromTime}, {
			observe: "body"
		});
	}

	fetchRtRecordsByRange(mac_address, range: {fromTime?: string, toTime?: string, fromId?: number, toId?: number}) {
		return this.httpClient.post(this.route + 'fetchRtRecordsByRange', {mac_address, range}, {
			observe: "body"
		});
	}

	getDeviceConfigTrack(id, options) {
		return this.httpClient.post(this.route + 'getDeviceConfigTrack', {id, options}, {
			observe: "body"
		});
	}

	getCellularConfigTrack(mac_address: string, options) {
		return this.httpClient.post(this.route + 'getCellularConfigTrack', {mac_address, options}, {
			observe: "body"
		});
	}

	getConnectivityTrack(mac_address, fromDate, toDate) {
		return this.httpClient.post(this.route + 'getConnectivityTrack', {mac_address, fromDate, toDate}, {
			observe: "body"
		});
	}
	getDeviceActions(mac_address, fromDate, toDate, options) {
		return this.httpClient.post(this.route + 'getDeviceActions', {mac_address, fromDate, toDate, options}, {
			observe: "body"
		});
	}

	getCellularInfo(mac_address) {
		return this.httpClient.post(this.route + 'getCellularInfo', {mac_address}, {
			observe: "body"
		});
	}

	getWarningsMabValues(warningKey: string) {
		const mapped = objectMappers['warnings'];
		const value = Object.keys(mapped).filter((key) => mapped[key] == warningKey);
		return value[0];
	}

	getWarningsList() {
		return this.getWarningsKeys().map(warning => {
			return {key: warning, value: this.translate.instant(`warnings.${warning}`)}
		});
	}

	getWarningsKeys() {
		return Object.keys(objectMappers['warnings']);
	}

	/**
	 * Format Device Warnings
	 * @param item
	 * @param options
	 * @param options.getAll To get (extract) all warnings and not just device warnings (device.device_warnings)
	 * @param options.includePassword To include password warning
	 * @param options.includeOffline To include offline warning
	 * @param options.siteZoneId Site Zone ID
	 * @param options.canIgnorePermission To ignore permissions check for device.device_warnings (for device listing)
	 * @param options.permission User site permissions
	 * @returns
	 */
	formatDeviceWarnings(item, options?) {
		let deviceWarnings = item.device_warnings,
			warningsArr = [],
			warningsRes = [];
		options = options || {};

		if(deviceWarnings) {
			if ((deviceWarnings & 0x0004) != 0)
				warningsArr.push('current_time');
			if ((deviceWarnings & 0x0008) != 0)
				warningsArr.push('current_sensor_open');
			if ((deviceWarnings & 0x0010) != 0)
				warningsArr.push('voltage_error_calibration');
			if ((deviceWarnings & 0x0020) != 0)
				warningsArr.push('voltage_error_value');
			if ((deviceWarnings & 0x0040) != 0)
				warningsArr.push('external_temperature_error');
			if ((deviceWarnings & 0x0100) != 0)
				warningsArr.push('current_error_calibration');
			if ((deviceWarnings & 0x0200) != 0)
				warningsArr.push('low_range_open_sensore');
			if ((deviceWarnings & 0x0400) != 0)
				warningsArr.push('full_low_range_big_diff')
			if ((deviceWarnings & 0x0800) != 0)
				warningsArr.push('full_low_shorted')
		}

		if(!options.getAll)
			return warningsArr;

		let qv = this.commonUtil.decompress(item.quick_view || {}, 'quick_view')

		if(qv.hw_status) {
			let qvWarnings = {
				1: 'plc',
				2: 'rtc',
				4: 'adc',
				8: 'flash_size',
				16: 'cellular_chip_hardware_failure'
			};
			for(let id in qvWarnings) {
				if((qv.hw_status & +id) != 0) {
					warningsArr.push(qvWarnings[id]);
				}
			}
		}

		if(item.lost_rtc)
			warningsArr.push('lost_rtc');

		if(item.has_rtc_time_drift_warning === true)
			warningsArr.push('rtc_time_drift');

		if(options.includePassword && !item.device_password)
			warningsArr.push('password');

		if(options.includeOffline) {
			let disconnectTime = moment().utc().unix() - options.deviceInfo.last_connect_time;
			if(disconnectTime > 3600*24*30) {
				warningsArr.push('disconnected_device');
			}
		}

		if(options.siteZoneId !== undefined && options.siteZoneId != options.deviceInfo.zone_id) {
			warningsArr.push('invalid_device_zone');
		}

		if(item.long_event_time && moment().utc().diff(moment(item.long_event_time).utc(), 'days') < 30) {
			warningsArr.push('long_event');
		}

		if(!item.is_charglink && item.mis_voltage_time && item.installation_date && item.mis_voltage_time > item.installation_date) {
			warningsArr.push('mis_voltage');
		}
		if(!item.is_charglink && item.mis_capacity_time && item.installation_date && item.mis_capacity_time > item.installation_date) {
			warningsArr.push('mis_capacity');
		}
		if(item.setup_done !== undefined && !item.setup_done){
			warningsArr.push('no_setup');
		}
		if(item.long_charge_event_time && moment().utc().diff(moment(item.long_charge_event_time).utc(), 'days') < 30) {
			warningsArr.push('charge_long_event');
		}

		if (item.over_lapping_event_time  && moment().utc().diff(moment(item.over_lapping_event_time ).utc(), 'days') < 30) {
			warningsArr.push('over_lapping_event');
		}

		//Filter warnings (permission)
		let ignorePermissionList = this.excludePermissionWarnings();
		for(let warning of warningsArr) {
			if(options.canIgnorePermission && (ignorePermissionList.includes(warning)) || this.usersService.hasAccessPermission({permission: options.permission}, 'warnings.'+warning))
				warningsRes.push(warning);
		}
		return warningsRes;
	}

	getWarningOptionsList() {
		return [
			{id: 'current_time', value: this.translate.instant('warnings.current_time')},
			{id: 'current_sensor_open', value: this.translate.instant('warnings.current_sensor_open')},
			{id: 'voltage_error_calibration', value: this.translate.instant('warnings.voltage_error_calibration')},
			{id: 'voltage_error_value', value: this.translate.instant('warnings.voltage_error_value')},
			{id: 'external_temperature_error', value: this.translate.instant('warnings.external_temperature_error')},
			{id: 'current_error_calibration', value: this.translate.instant('warnings.current_error_calibration')},
			{id: 'mis_voltage', value: this.translate.instant('warnings.mis_voltage')},
			{id: 'mis_capacity', value: this.translate.instant('warnings.mis_capacity')},
			{id: 'no_setup', value: this.translate.instant('warnings.no_setup')}
		]
	}

	/**
	 * Return warnings to exclude permission check for (Device warnings; device.device_warnings)
	 * Used in device listings
	 */
	excludePermissionWarnings() {
		return [
			'current_time',
			'current_sensor_open',
			'voltage_error_calibration',
			'voltage_error_value',
			'external_temperature_error',
			'current_error_calibration',
		];
	}

	formatQueuedChanges(device, changesStackOrigin, mode='normal', siteZoneId) {
		if(Object.keys(changesStackOrigin).length == 0)
			return changesStackOrigin;

		let changesStack = lodash.cloneDeep(changesStackOrigin);

		var daysMask			= []
		var dates				= ['last_pm_date', 'installation_date', 'setup_time'];
		var times				= [];
		var temperatureFields	= [];
		var booleans			= [];
		var noYesFields			= [];
		var invertedBooleans	= [];

		var daysOfTheWeek	= this.commonUtil.getDropDownListData('week');
		var disableEnable	= [this.translate.instant('g.disable'), this.translate.instant('g.enable')];
		var noYes			= [this.translate.instant('g.no'), this.translate.instant('g.yes')];
		var invalidOptionText = this.translate.instant('g.invalid_option')+'!';

		for (var field in changesStack) {

			if(mode == 'plainObject')
				changesStack[field] = [[changesStack[field]]];

			if(temperatureFields.indexOf(field) > -1) {

				if(device.temperatureformat) {
					changesStack[field].forEach((change) => {
						change[0] = Math.round(change[0] * 1.8 + 32);
					});
				}
			} else if(dates.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					let timeInSiteTimezone = this.commonUtil.getZoneTimestampFromUTC(siteZoneId, change[0]);
					change[0] = moment(timeInSiteTimezone * 1000).utc().format('MM/DD/YYYY');
				});
			} else if(times.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					let timeInSiteTimezone = this.commonUtil.getZoneTimestampFromUTC(siteZoneId, change[0]);
					change[0] = moment(timeInSiteTimezone * 1000).utc().format('MM/DD/YYYY hh:mm:ss a');
				});
			} else if(invertedBooleans.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					change[0] = +!change[0];
					change[0] = disableEnable[change[0]];
					if(!change[0])
						change[0] = invalidOptionText;
				});
			} else if(booleans.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					change[0] = +change[0];
					change[0] = disableEnable[change[0]];
					if(!change[0])
						change[0] = invalidOptionText;
				});
			} else if(daysMask.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					if(typeof change[0] == 'object' && change[0].length > 0) {
						var dayNames = [];
						change[0].forEach((day) => {
							if(daysOfTheWeek[day])
								dayNames.push(daysOfTheWeek[day].text);
							else
								dayNames.push(this.translate.instant('g.invalid_day')+'!');
						});
						change[0] = dayNames.join(', ');
					}
				});
			} else if(noYesFields.indexOf(field) > -1) {
				changesStack[field].forEach((change) => {
					change[0] = +change[0];
					change[0] = noYes[change[0]];
					if(!change[0])
						change[0] = invalidOptionText;
				});
			}
		}

		if(mode == 'plainObject') {

			let toReturn = {};
			for (var field in changesStack) {
				toReturn[field] = changesStack[field][0][0];
				switch (field) {
					case "last_saved_user_id":
						if(toReturn[field] == 0xFFFFFFFF)
							toReturn[field] = this.translate.instant('g.system');
					break;
					case "last_change_time":
						let timeInSiteTimezone = this.commonUtil.getZoneTimestampFromUTC(siteZoneId, toReturn[field]);
						toReturn[field] = moment(timeInSiteTimezone * 1000).utc().format('MM/DD/YYYY hh:mm:ss A');
					break;
				}
			}
			return toReturn;
		}

		return changesStack;
	}

	getDebugRecords(mac_address, options) {
		return this.httpClient.post(this.route + 'getDebugRecords', {mac_address, options}, {
			observe: "body"
		});
	}

	getDeviceFirmwareVersions() {
		return this.httpClient.post(this.route + 'getDeviceFirmwareVersions', {}, {
			observe: "body"
		});
	}

	pushFirmwareUpdate(deviceID, newFwVersion, type) {
		return this.httpClient.post(this.route + 'pushFirmwareUpdate', {deviceID, newFwVersion, type}, {
			observe: "body"
		});
	}

	saveDeviceSettings(mac_address, configs, stage, options?, wait = 0) {
		return this.httpClient.post(this.route + 'saveDeviceSettings', {mac_address, configs, stage, options, wait}, {
			observe: "body"
		});
	}

	saveMultiDevicesSettings(deviceIds, configs, eachDeviceConfig = null) {
		return this.httpClient.post(this.route + 'saveMultiDevicesSettings', {deviceIds, configs, eachDeviceConfig}, {
			observe: "body"
		});
	}

	getSiteDevicesListing(siteID, studyId = 0) {
		return this.httpClient.post(this.route + 'getSiteDevicesListing', {siteID, studyId}, {
			observe: "body"
		});
	}

	getAllSitesDevicesListing() {
		return this.httpClient.post(this.route + 'getAllSitesDevicesListing', {}, {
			observe: "body"
		});
	}

	getBlockedFWReport() {
		return this.httpClient.post(this.route + 'getBlockedFWReport', {}, {
			observe: "body"
		});
	}

	unBlockFWReport(devices: {mac_address: string, version: string}[]) {
		return this.httpClient.post(this.route + 'unBlockFWReport', {devices}, {
			observe: "body"
		});
	}

	setLastMaintenanceDate(macAddress, lastPMDate) {
		return this.httpClient.post(this.route + 'setLastMaintenanceDate', {macAddress, lastPMDate}, {
			observe: "body"
		});
	}

	addDeviceToWakeUpList(mac_address: string, iccid: string, remoter_server_port: number) {
		return this.httpClient.post(`cellular/addDeviceToWakeUpList`, {mac_address, iccid, remoter_server_port}, {observe:"body"});
	}

	setMultiLastMaintenanceDate(deviceIds, lastPMDate) {
		return this.httpClient.post(this.route + 'setMultiLastMaintenanceDate', {deviceIds, lastPMDate}, {
			observe: "body"
		});
	}

	addConnectivityClasses(nowTime, devices) {
		for (var item of devices) {
			const installatioDate = item.installation_date + this.oneDayDisconnect;
			item.tooltipMessages = item.tooltipMessages || [];
			const lastConnectTime = nowTime - item.last_connect_time;
			// WIFI
			let wifiLastConnectTime = lodash.max([item.non_metered_wifi_last_connect_time, item.metered_wifi_last_connect_time]);
			let wifiDisconnectTime = nowTime - wifiLastConnectTime;
			this.updateConnectivityFlag(lastConnectTime, item);
			let hasWifiImage = false;
			if (wifiLastConnectTime > installatioDate && wifiDisconnectTime < this.sixMonths) {
				hasWifiImage = true;
				this.showWifiImage(wifiDisconnectTime, item);
			}

			// LTE
			const lteLastConnectTime = lodash.max([item.non_metered_cellular_last_connect_time, item.metered_cellular_last_connect_time]);
			const lteDisconnectTime = nowTime - lteLastConnectTime;
			let hasLteImage = false;
			if (lteLastConnectTime > installatioDate && lteDisconnectTime < this.sixMonths) {
				hasLteImage = true;
				this.showLteImage(nowTime, lteDisconnectTime, item);
			}

			if (!hasWifiImage && !hasLteImage) {
				wifiLastConnectTime = item.last_connect_time;
				wifiDisconnectTime = nowTime - wifiLastConnectTime;
				if(lteDisconnectTime > wifiDisconnectTime) {
					this.showLteImage(nowTime, lteDisconnectTime, item)
				} else {
					this.showWifiImage(wifiDisconnectTime, item);
				}
			}

			if (lastConnectTime == null)
				continue;

			if (lastConnectTime < 60) // less than 1 min
				item.tooltipMessages.push(`${this.translate.instant('devices.last_connect')}: ${this.translate.instant('devices.less_than_a_min')}`);

			else if(this.commonUtil.secondsToElapsedTime(lastConnectTime))
				item.tooltipMessages.push(this.translate.instant('devices.last_connect') + ': ' + this.commonUtil.secondsToElapsedTime(lastConnectTime));
		}
		return devices;
	}

	getLastConnectionTimeMassage(device: any) {
		const nowTime = this.commonUtil.nowTime();
		let lastConnectTime = nowTime - device.last_connect_time;

		if (!lastConnectTime)
			return '';

		if (typeof lastConnectTime !== 'number')
			lastConnectTime = moment.utc(lastConnectTime).unix();

		if (lastConnectTime < 60) // less than 1 min
				return `${this.translate.instant('devices.last_connect')}: ${this.translate.instant('devices.less_than_a_min')}`;

		return this.translate.instant('devices.last_connect') + ': ' + this.commonUtil.secondsToElapsedTime(lastConnectTime) ;
	}

	showLteImage(nowTime, lastConnectTime, item) {
		// if a device cellular enabled  = false → hide the icon
		if (item.enable_cellular === false)
			return;

		// if the device doesn’t have ICCID and the user doesn’t have NOC permission → hide the icon
		if (!item.cellular_iccid) {
			if (!this.usersService.userHasNOCAccess())
				return;

			// if the user has NOC permission and the device doesn’t have ICCID show the lte_bad
			item.connectivityStatus = '/images/lte/lte_BAD.png';
			return;
		}

		if (lastConnectTime >= this.thirtyDayDisconnected) {  // not connected for 30 days
			item.connectivityStatus = '/images/lte/lte_disconnected_30d.png';
			return;
		}
		if (lastConnectTime >= this.sevenDayDisconnect) {  // not connected for 7 days
			item.connectivityStatus = '/images/lte/lte_disconnected_7d.png';
			return;
		}
		if (lastConnectTime >= this.threeDaysDisconnect) { // not connected for 3 days
			item.connectivityStatus = '/images/lte/lte_disconnected_3d.png';
			return;
		}
		if (lastConnectTime >= this.oneDayDisconnect) {  // connected for one day and lessthan 3 days check SIM time DB
			const lastSimTime = nowTime - item.last_sim_connect_time;
			if (lastSimTime < this.oneDayDisconnect) {
				item.connectivityStatus = '/images/lte/lte_connected.png';
				return;
			}
			item.connectivityStatus = '/images/lte/lte_disconnected_3d.png';
			return;
		}
		item.connectivityStatus = '/images/lte/lte_connected.png';
		return;
	}

	showWifiImage(disconnectTime, item) {
		let connectFrequency = item.reconnect_time;
		item.tooltipMessages = [];
		if (disconnectTime <= (2.5 * connectFrequency)) {
			//Less than 2.5 times of normal connectFrequency
			item.wifiConnectivityStatus = '/images/wifi_connected.png';
		} else {
			if(disconnectTime <= 8*24*3600) {
				//one day
				item.wifiConnectivityStatus = '/images/wifi_not_connected_1d.png';
			} else if(disconnectTime > 8*24*3600 && disconnectTime <= 30*24*3600) {
				//More than 8 days hrs and less than 30 days
				item.wifiConnectivityStatus = '/images/wifi_not_connected_7d_orange.png';
			} else {
				item.wifiConnectivityStatus = '/images/wifi_not_connected_red.png';
			}
		}
	}

	updateConnectivityFlag(disconnectTime, item ) {
		let connectFrequency = item.reconnect_time;
		item.connectivityFlag = 0;
		if (disconnectTime <= (2.5 * connectFrequency)) {
			//Less than 2.5 times of normal connectFrequency
			item.connectivityFlag	= 1;
		} else {
			if(disconnectTime <= 24*3600) {
				// one day
				item.connectivityFlag	= 2;
			} else if(disconnectTime > 24*3600 && disconnectTime <= 7*24*3600) {
				// one week
				item.connectivityFlag	= 3;
			} else {
				// more than one week
				item.connectivityFlag	= 4;
			}
		}
	}

	getImageRouterLink(type: string, customerId: number, device: any) {
		const baseImageUrl = ['/', customerId, device.site_id, device.mac_address];

		if (type == 'cellular' && this.usersService.hasAccessPermission(null, 'debug_data')) {
			return [...baseImageUrl, 'cellular-settings'];
		}

		if (type == 'wifi' && this.usersService.hasAccessPermission(null, 'networking_admin')
			|| this.usersService.hasAccessPermission(null, 'wifi_admin')
			|| this.usersService.hasAccessPermission(null, 'basic_setup')) {
			return [...baseImageUrl, 'settings'];
		}
		return null;
	}

	formatChangesStackField(value, type, options?) {
		switch(type) {
			case 'date':
				const zoneDiff = new Date().getTimezoneOffset();
				if(value !== undefined) {
					value += (zoneDiff * 60);
					value = moment(value*1000).format('MM/DD/YYYY');
				}
			break;
			case 'select':
				value = this.deviceFieldsLists[options.field][value];
			break;
			case 'zone':
				value = this.commonUtil.getZoneName(value);
			break;
		}
		return value;
	}

	formatChangesStack(stack, zoneId) {

		let formatBooleans = (value, trueFalse=false) => {
			if(trueFalse)
				value = +value;
			value = disableEnable[value];
			if(!value)
				value = this.translate.instant('g.invalid_input');
			return value;
		};

		let booleanFields = this.deviceBooleanFields.slice();

		let disableEnable = [
			this.translate.instant('g.disable'),
			this.translate.instant('g.enable')
		];

		for(let field in stack) {
			if(booleanFields.includes(field)) {
				stack[field].forEach((change) => {
					change[0] = formatBooleans(change[0]);
				});
			} else if(this.objectFields.includes(field)) {
				stack[field] = this.formatChangesStack(stack[field], zoneId);
			} else if(this.deviceDateFields.includes(field)) {
				stack[field].forEach((change) => {
					change[0] = this.formatChangesStackField(change[0], 'date', {zoneId});
				});
			} else if(this.deviceFieldsLists[field]) {
				stack[field].forEach((change) => {
					change[0] = this.formatChangesStackField(change[0], 'select', {field});
				});
			} else if(this.deviceZoneFields.includes(field)) {
				stack[field].forEach((change) => {
					change[0] = this.formatChangesStackField(change[0], 'zone');
				});
			} else if(field == 'rt_log_frequency') {
				stack.rt_log_frequency_fmt = stack.rt_log_frequency;
			}
		}

		return stack;
	}

	updateRTSync(macAddress, rtSync) {
		return this.httpClient.post(this.route + 'updateRTSync', {macAddress, rtSync}, {
			observe: "body"
		});
	}

	updateDeviceOnDB(mac_address: string, data: any) {
		return this.httpClient.post(this.route + 'updateDeviceOnDB', {mac_address, data}, {
			observe: "body"
		});
	}

	flushDeviceCache(macAddress) {
		return this.httpClient.post(this.route + 'flushDeviceCache', {macAddress}, {
			observe: "body"
		});
	}

	saveMaintenanceSchedule(macAddress, dirtyFields) {
		return this.httpClient.post(this.route + 'saveMaintenanceSchedule', {macAddress, dirtyFields}, {
			observe: "body"
		});
	}

	saveMultiMaintenanceSchedule(deviceIds, dirtyFields) {
		return this.httpClient.post(this.route + 'saveMultiMaintenanceSchedule', {deviceIds, dirtyFields}, {
			observe: "body"
		});
	}

	getTimeLostReport() {
		return this.httpClient.post(this.route + 'getTimeLostReport', {}, {
			observe: "body"
		});
	}

	resetRTCLostDetection(macAddress) {
		return this.httpClient.post(this.route + 'resetRTCLostDetection', {macAddress}, {
			observe: "body"
		});
	}

	deviceLastConnectTime(macAddress, studyId: number) {
		return this.httpClient.post(this.route + 'deviceLastConnectTime', { macAddress, studyId }, {
			observe: "body",
			params: new CustomHttpParams({noUIBlock: true})
		});
	}

	officialReporting(macAddress, data: { included_in_reports: boolean, defective_device: any }) {
		return this.httpClient.post(this.route + 'officialReporting', { macAddress, data }, {
			observe: "body"
		});
	}

	getDefectiveDevice(macAddress: string) {
		return this.httpClient.get(`${this.route}get-defective-device/${macAddress}`);
	}

	getChangesReportDevices() {
		return this.httpClient.post(this.route + 'getChangesReportDevices', {}, {
			observe: "body"
		});
	}

	commandCodeToName = {
		7:		'restart_device',
		d:		'indicator_light',
		9:		'set_serial_number',
		11:		'reset_data_history',
		15:		'write_adc_override',
		16:		'read_adc_override',
		18:		'update_password',
		17:		'factory_reset',
		19:		'scan_wifi',

		2707:	'set_last_maintenance_date',
		2708:	'reverse_rt',
		2709:	'reverse_calibration',
		'270a':	'reverse_events',
		'270b':	'reset_rtc_lost_detection',
		'1a': 'query_cellular_info',
		'1b': 'cmd_cell_test_mode',
		'1c': 'cmd_cell_test_status'
	}

	getCommandNameFromCode(commandCode) {

		const hexCode = commandCode.toString(16);
		return this.commandCodeToName[hexCode]?  this.translate.instant('command_name.'+ this.commandCodeToName[hexCode]) : '';
	}

	showManageDeviceOption(device: any, site?: any) {
		const deviceSiteId = device.site_id || site.id;
		const hasManagmentAccess = this.usersService.hasAccessFunction('device_management') || this.usersService.hasAccessFunction('sct_inventory_device_management');

		return this.usersService.hasAccessFunction('super_admin', 'write') ||
			(hasManagmentAccess && device?.is_study_device && site.name == this.commonUtil.powerStudiesDevicesSiteName) ||
			((hasManagmentAccess) && !site.is_special) ||
			(deviceSiteId === this.sitesService.sctInventorySiteId && this.usersService.hasAccessFunction('sct_inventory_device_management'));
	}

	getSimInfo(iccid: string, isLive?: boolean) {
		return this.httpClient.post(`cellular/getSimInfo`, { iccid, isLive}, {
			observe: "body"
		});
	}

	getSimDevicesData() {
		return this.httpClient.post(`cellular/getSimDevicesData`, {}, {
			observe: "body"
		});
	}

	getSimUsageHistory(mac_address: string, from, to) {
		return this.httpClient.post(`cellular/getSimUsageHistory`, {mac_address, from, to}, {
			observe: "body"
		});
	}

	getMissingSims() {
		return this.httpClient.post(`cellular/getMissingSims`, {}, {
			observe: "body"
		});
	}

	refreshMissingSims() {
		return this.httpClient.post(`cellular/refreshMissingSims`, {}, {
			observe: "body"
		});
	}

	updateSimConfig(iccids: string[], operation: 'enable' | 'disable' | 'lock' | 'unlock', reason: string) {
		return this.httpClient.post(`cellular/updateSimConfig`, {iccids, operation, reason}, {
			observe: "body"
		});
	}

	unblockSims(iccids: string[], reason: string) {
		return this.httpClient.post(`cellular/unblockSims`, {iccids, reason}, {
			observe: "body"
		});
	}

	resetCellularOEM(mac_address: string) {
		return this.httpClient.post(this.route + 'reset-oem', { mac_address}, {
			observe: "body"
		});
	}

	clearRTCTimeDriftWarning(mac_address: string) {
		return this.httpClient.post(this.route + 'clearRTCTimeDriftWarning', { mac_address}, {
			observe: "body"
		});
	}

	validateRmaNo(value: string) {
		return /^RMA\d{10}$/.test(value);
	}

	getShippingConnectivityData(macAddress: string) {
		return this.httpClient.get(`device/getDeviceShippingConnectivity/${macAddress}`);
	}
	updateShippingConnectivityData(macAddress: string, action: "extend" | "cancel" | "renew") {
		return this.httpClient.post(`device/updateDeviceShippingConnectivity`, { macAddress, action });
	}

	getAllQueueChanges() {
		return this.httpClient.get(`device/getAllQueueChanges`);
	}

	getNocMonitoringDevices() {
		return this.httpClient.get(this.route + 'getNocMonitoringDevices');
	}

	removeChangeStack(fieldName: string, commandId: number) {
		return this.httpClient.post(`device/removeChangeStack`, {fieldName, commandId});
	}

	generateSuggestedInstallationReport(siteId: number, date: number, gapPeriod: number) {
		return this.httpClient.get('device/generate-suggested-installation-report', {
			observe: "body",
			params: {
				siteId,
				installationDateByInstaller: date,
				gapPeriod,
			}
		});
	}

	setDevicesInstallationDate(data: any) {
		return this.httpClient.post(`device/set-installation-date`, { devicesWithInstallationDate: data });
	}

	getDevicesLocationMismatchReport() {
		return this.httpClient.get(`device/getDevicesLocationMismatchReport`);
	}

	getAllDefectiveDevices() {
		return this.httpClient.get(`device/get-defective-devices`);
	}

	getQuickViewHistory(macAddress: string, startDate: number, endDate: number) {
		return this.httpClient.get(`device/getQuickViewHistory`, {
			observe: 'body',
			params: {
				macAddress,
				startDate,
				endDate
			}
		});
	}

	getEventsFields (options: {all?: boolean, removeNotDisplayed?: boolean} = {removeNotDisplayed: true}) {
		let eventsFieldOnOrder = [...this.eventsDefaultFields];

		let eventsFields = Object.keys(objectMappers['events']).sort();
		eventsFields.push('event_duration', 'billed_kwh');
		eventsFields = eventsFields.map(field => this.eventCalculatedFieldsMapper[field] ? this.eventCalculatedFieldsMapper[field]: field);

		for(let field of eventsFields) {
			if (!eventsFieldOnOrder.includes(field))
				eventsFieldOnOrder.push(field);
		}

		if (options.all)
			return eventsFieldOnOrder;

		eventsFieldOnOrder = eventsFieldOnOrder.filter(field => !this.notDisplayedEventFields.includes(field));

		return eventsFieldOnOrder;
	}
}
