import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AdminService } from "../../admin.service";
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
		selector: 'app-connectivity-verification-statistics',
		templateUrl: './connectivity-verification-statistics.component.html',
		styleUrls: ['./connectivity-verification-statistics.component.css']
})

export class ConnectivityVerificationStatisticsComponent implements OnInit, OnDestroy {
	@ViewChild("devicesInfoModel") devicesInfoModel;
	@ViewChild("sitesInfoModel") sitesInfoModel;

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		draggable: true,
		fitScreen: true
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'month', name: this.translate.instant('connectivity_verification.month'), type: 'string'},
		{ key: 'total_check', name: this.translate.instant('connectivity_verification.total_check'), type: 'string'},
		{ key: 'total_number_unique_devices', name: this.translate.instant('connectivity_verification.total_number_unique_devices'), type: 'action_link'},
		{ key: 'total_number_unique_sites', name: this.translate.instant('connectivity_verification.total_number_unique_sites'), type: 'action_link'},
	];

	sitesInfo: any;
	devicesInfo: any;
	devicesData: any[] = [];
	sitesData: any[] = [];

	constructor(
		private adminService: AdminService,
		private usersService: UsersService,
		private router: Router,
		private translate: TranslateService,
	) {}

	ngOnInit() {
			if (!this.usersService.hasAccessPermission(null, 'noc'))
					this.router.navigate(['/unauthorized']);

			this.getConnectivityVerificationStatistics();
	}

	getConnectivityVerificationStatistics() {
		this.adminService.getConnectivityVerificationStatistics().subscribe((response: any) => {
			if (!Object.keys(response).length)
					return;

			this.sitesInfo = response.sitesInfo;
			this.devicesInfo = response.devicesInfo;

			this.tableData = [];
			for(const record of response.statisticsLogs) {
				this.tableData.push({
					month: {value: `${record.month} ${record.year}`},
					total_check: {value: record.total_checks},
					total_number_unique_devices: {value: record.mac_addresses .length, action: ()=> {this.showDevicesInfoModal(record.mac_addresses)}},
					total_number_unique_sites: {value: record.sites_ids.length, action: ()=> {this.showSitesInfoModal(record.sites_ids)}},
				})
			}
		});
	}

	showDevicesInfoModal(data) {
		this.devicesData = data;
		this.devicesInfoModel.show();
	}

	showSitesInfoModal(data) {
		this.sitesData = data;
		this.sitesInfoModel.show();
	}

	ngOnDestroy() {
		this.sitesInfoModel.hide();
		this.devicesInfoModel.hide();
	}
}
