import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import * as lodash from 'lodash-es';
import { DeviceService } from '../../device.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { SCTCustomTable } from 'src/app/shared/custom-table/custom-table.component';

@Component({
	selector: 'app-cellular-track',
	templateUrl: './cellular-track.component.html'
})
export class CellularTrackComponent implements OnInit, AfterViewInit {
	@Input() device: any = {};
	@Input() currentSite: any;
	@Input() dateRange: {
		fromDate: Date,
		toDate: Date
	} = {
		fromDate: new Date(),
		toDate: new Date()
	};
	@Input() invalidDateRange: boolean = false;
	
	configsToCompare: any;
	gridColumns: any;
	trackModalData: any = {};
	compareModalData: any = {};

	cellularRSSIfields = [
		'cellular_rssi'
	];

	@ViewChild("trackModal") trackModal;
	@ViewChild("compareModal") compareModal;
	
	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	firstTime = true;
	@ViewChild("sctCustomTable", { static: true }) sctCustomTable!: SCTCustomTable;
	columnConfig: ColumnsConfig[] = [];
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: true,
		fileName: 'cellular-track',
		hasSelectionColumn: true,
		fitScreen: true,
		hasPagination: true,
		pageSize: 100,
		isBackendPagination: true
	};

	constructor(
		private deviceService: DeviceService,
		private commonService: CommonService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.columnConfig = [
			{ key: 'id', name: this.translateService.instant('g.id'), type: 'number', hasFilter: true },
			{ key: 'show_configs', name: this.translateService.instant('config.configs'), type: "action_link", hasSort: false, hasFilter: false, hasExport: false },
			{ key: 'insertion_time', name: this.translateService.instant('g.insertion_time'), type: "date", hasFilter: true },
		];
	}

	ngAfterViewInit() {
		this.getCellularConfigTrack({currentPage: 1, isFirstTime: true});
	}

	ngOnChanges(changes) {
		if (
			(changes.device && changes.device.previousValue && changes.device.previousValue.mac_address != changes.device.currentValue.mac_address) ||
			changes.dateRange
		) {
			if (!this.firstTime)
				this.getCellularConfigTrack({currentPage: 1, isFirstTime: true});

			this.firstTime = false;
		}
	}

	onTrackCellClicked(cycle) {
		this.trackModalData = this.formatChanges(cycle.configs);
		this.trackModal.show();
	}

	formatChanges(configs) {
		let zoneID = (this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id);
		return this.deviceService.formatQueuedChanges(this.device, configs, 'plainObject', zoneID);
	}

	getCellularConfigTrack(paginationData) {
		if (!this.device.mac_address || this.invalidDateRange || !this.sctCustomTable)
			return;

		if (paginationData.currentPage == 1)
			this.sctCustomTable.backendPaginationInit();

		const limit = this.sctCustomTable.config.pageSize;

		let fromDateRange = this.dateRange.fromDate;
		let toDateRange = this.dateRange.toDate;
		fromDateRange = new Date(new Date(fromDateRange).setHours(0, 0, 0, 0));
		toDateRange = new Date(new Date(toDateRange).setHours(23, 59, 59, 999));

		let zoneDiff = new Date().getTimezoneOffset() * -1;
		let fromDate: any = new Date(new Date(fromDateRange).getTime() + (zoneDiff * 60 * 1000));
		let toDate: any = new Date(new Date(toDateRange).getTime() + (zoneDiff * 60 * 1000));
		fromDate = moment(fromDate).utc().startOf('day').unix();
		toDate = moment(toDate).utc().endOf('day').unix();

		const options = {
			currentPage: paginationData.currentPage,
			filters: paginationData.filters,
			sort: paginationData.sort,
			limit,
			getCount: paginationData.isFirstTime,
			fromDate,
			toDate
		};

		this.deviceService.getCellularConfigTrack(this.device.mac_address, options).subscribe((response: any) => {
			const tableData = [];
			response.data.forEach((record) => {
				record.configs.cellular_connected = this.translateService.instant('g.' + record.cellular_connected ? 'on' : 'off').toUpperCase();
				record.insertion_time = this.commonService.getZoneTimestampFromUTC((this.device.config_info.zone_id ? this.device.config_info.zone_id : this.currentSite.zone_id), moment(record.insertion_time).utc().unix());
				record.insertion_time = moment(record.insertion_time * 1000).format('MM/DD/YYYY HH:mm:ss');
				this.formatData(record.configs);
				
				tableData.push({
					id: { value: record.id },
					show_configs: { value: this.translateService.instant('config.show_configs'), action: () => { this.onTrackCellClicked(record) } },
					insertion_time: { value: record.insertion_time },
					configs: { value: record.configs },
					mac_address: { value: record.mac_address },
				});
			});

			this.tableData = tableData;
			this.sctCustomTable.updatePagination(response.totalDataCount, response.overallItemsCount);
		});
	}

	gridEvent(event: any) {
		if(event.length == 2) {
			this.configsToCompare = event.map((item: any) => {
				const newItem = {};
				for (const key in item) {
					newItem[key] = item[key].value || item[key];
				}
				return newItem;
			});
		} else {
			this.configsToCompare = null;
		}
	}

	formatData(recordConfigs) {
		for(let field in recordConfigs) {
			// format boolean fields
			if(this.deviceService.deviceBooleanFields.includes(field))
				recordConfigs[field] = !!recordConfigs[field];

			if (this.cellularRSSIfields.includes(field)) {
				recordConfigs[field] = this.commonService.mapCellularRssi(recordConfigs[field]);
			}
		}
	}
	
	compareConfigs() {
		let oldConfig		= {};
		let oldValsIdx		= 1;
		let newConfig		= lodash.cloneDeep(this.configsToCompare[0].configs);
		let ignoreFields	= ['id'];

		if(this.configsToCompare[1].configs.id > this.configsToCompare[0].configs.id) {

			newConfig	= lodash.cloneDeep(this.configsToCompare[1].configs);
			oldValsIdx	= 0;
		}

		for(let key in newConfig) {
			
			if(ignoreFields.includes(key))
				continue;
			
			let tempOld = this.configsToCompare[oldValsIdx].configs[key];
			if(tempOld != newConfig[key])
				oldConfig[key] = tempOld;
		}
		
		this.compareModalData = {
			'old': this.formatChanges(oldConfig),
			'new': this.formatChanges(newConfig)
		};
		
		this.compareModal.show();
	}

	isObject(value) {
		return typeof value == 'object' && value && Object.keys(value).length > 0;
	}
}