import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../users/users.service';
import { Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SitesService } from 'src/app/sites/sites.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { keyBy } from 'lodash-es'
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { Subscription } from 'rxjs';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { CommonDataService } from 'src/app/shared/services/common-data.service';

@Component({
	selector: 'app-suggested-installation-date-report',
	templateUrl: './suggested-installation-date-report.component.html'
})
export class SuggestedInstallationDateReportComponent implements OnInit {
	// table data
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 100,
		fitScreen: true,
		hasSelectionColumn: true,
		fileName: this.translate.instant('suggested_installation_date_report.title'),
		hasActionButtons: true,
		actionButtonsList: [
			{
				text: this.translate.instant('suggested_installation_date_report.use_suggested_installation_date'),
				action: ()=> { this.setInstallationDate() },
			}
		]
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: "id", hidden: true},
		{ key: 'serial_number', name: this.translate.instant('devices.serial_number'), type: "link"},
		{ key: 'mac_address', name: this.translate.instant('g.mac_address'), type: 'string' },
		{ key: 'suggested_installation_date', name: this.translate.instant('suggested_installation_date_report.suggested_installation_date'), type: "date" },
		{ key: 'actual_installation_date', name: this.translate.instant('suggested_installation_date_report.actual_installation_date'), type: "date" },
	];

	customersAndSites: any[] = [];
	customersList: any[] = [];
	sitesList: any[] = [];

	selectedCustomerId: number = null;
	selectedSiteId: number = null;
	selectedDate = 0;
	selectedDevices = {};
	selectedGapPeriod: number = 14;

	setInstallationSubscription!: Subscription;
	generateReportSubscription!: Subscription;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private sideMenuService: SideMenuService,
		private deviceService: DeviceService,
		private siteService: SitesService,
		private commonService: CommonService,
		public translate: TranslateService,
		private notificationMessage: NotificationMessageService,
		private commonData: CommonDataService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			return this.router.navigate(['/unauthorized']);

		if(window.location.hostname.includes(this.commonData.developmentDomain))
			this.tableConfig.pageSize = 5;

		this.sideMenuService.hide();

		this.getCustomersSiteList();
	}

	getCustomersSiteList() {
		this.siteService.getUserSitesInfo({ get_customers: true }).subscribe((res: any) => {
			this.customersList = res.customers;
			this.customersList = this.commonService.sortDataAlphabetically(this.customersList, 'customer_name');
			this.customersAndSites = keyBy(this.customersList || [], 'id');
		});
	}

	getCustomerSites(customerId: number) {
		this.sitesList = this.customersAndSites[customerId].sites || [];
		this.sitesList = this.commonService.sortDataAlphabetically(this.sitesList, 'name');
		this.selectedSiteId = this.sitesList[0]?.id;
	}

	disableEnableGenerateButton() {
		return  !this.selectedSiteId ||
				!this.selectedDate ||
				!Number.isInteger(this.selectedGapPeriod) ||
				this.selectedGapPeriod < 7 ||
				this.selectedGapPeriod > 60;
	}

	generateReport() {
		if (this.disableEnableGenerateButton())
			return;

		this.generateReportSubscription = this.deviceService.generateSuggestedInstallationReport(this.selectedSiteId, this.selectedDate, this.selectedGapPeriod).subscribe((res: any) => {
			this.selectedDevices = {};
			this.tableData = [];
			for(const row of (res || [])) {
				this.tableData.push({
					id: {value: row.mac_address, disable_select: !row.suggested_installation_date},
					not_formatted_suggested_installation_date: row.suggested_installation_date,
					serial_number: {value: row.serial_number, link: ['/', this.selectedCustomerId, this.selectedSiteId, row.mac_address,'performance']},
					mac_address: {value: row.mac_address},
					suggested_installation_date: {value: this.formateInstallationDate(row.suggested_installation_date)},
					actual_installation_date: {value: this.formateInstallationDate(row.actual_installation_date)},
				})
			}
		});
	}

	setInstallationDate(data = {}) {
		this.setInstallationSubscription = this.deviceService.setDevicesInstallationDate(this.selectedDevices).subscribe((res: any) => {
			switch (res?.api_status) {
				case 2:
					this.notificationMessage.setMessage(this.translate.instant('g.invalid_fields'));
					break;
				default:
					this.notificationMessage.setMessage('globalSuccessMsg', { clearOnXTimeNavigate: 1 });
					break;
			}
		});
	}

	formateInstallationDate(date: number) {
		if (!date)
			return 'N/A';

		return moment(date * 1000).utc().format('MM/DD/YYYY');
	}

	ngOnDestroy() {
		if (this.setInstallationSubscription)
			this.setInstallationSubscription.unsubscribe();

		if (this.generateReportSubscription)
			this.generateReportSubscription.unsubscribe();
	}

 	updateSelectedDevices(devices) {
		for (const device of devices) {
			this.selectedDevices[device.mac_address.value] = device.not_formatted_suggested_installation_date;
		}
	}
}
