<div class="my-3" *ngIf="usersService.hasAccessFunction('customer_management') && currentUser.isSCT">
	<button type="button" [routerLink]="['edit', 0]" class="btn btn-primary mb20">{{'g.add_new' | translate}} <fa-icon icon="plus"></fa-icon></button>
</div>
<div class="mt-2 mb-2 ml-2 mr-2">
	<div class="card">
		<div class="card-body">
			<div class="row">
				<label class="col-1" for="search_enterprise"><small><strong>{{'g.search' | translate}}</strong></small></label>
				<div class="col-4">
					<input type="text" class="form-control" name="search_enterprise" [(ngModel)]="filters.searchString"/>
				</div>
				<div class="col-4 mt-2">
					<input class="form-check-input" type="checkbox" name="isPartial" [(ngModel)]="filters.isPartial" id="defaultCheck1">
					<label class="form-check-label" for="defaultCheck1">
						<small><strong>{{'g.partial_search' | translate}}</strong></small>
					</label>
				</div>
			</div>
			<div class="mt-1">
				<button class="btn btn-primary" (click)="getCustomers()">{{'g.search'| translate}}</button>
			</div>
		</div>
	</div>
</div>
<div class="mt-2 mb-2 ml-2 mr-2">
	<sct-custom-table
		*ngIf="tableData.length"
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
	></sct-custom-table>
</div>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>
