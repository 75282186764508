<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<div class="my-4">
		<sct-custom-table *ngIf="schedFunctions.length;else noData"
			[data]="schedFunctions"
			[columnsConfig]="columnConfig"
			[config]="config">
		</sct-custom-table>
	</div>
</div>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>