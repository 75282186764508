<div class="white-box p-4 border-radius mt-2">
	<div *ngIf="tableData.length > 0">
		<sct-custom-table
			*ngIf="tableData.length"
			[columnsConfig]="columnConfig"
			[data]="tableData"
			[config]="tableConfig"
		></sct-custom-table>
	</div>
	<div class="text-danger" *ngIf="invalidDateRange; else noData"><b>{{'g.start_lg_end' | translate}}</b></div>
	<ng-template #noData>
		<div *ngIf="emptyResponse">
			<strong>No results found!</strong>
		</div>
	</ng-template>
</div>