<app-simple-widget 
	*ngIf="!hideWidget && data"
	[title]="title"
	[widgetId]="widgetId"
	[tooltipKey]="tooltip"
>
	<div class="panel-body" *ngIf="!isCollapsed">
		<div class="row noprint mb-4">
			<ng-container *ngFor="let dropDownGroup of dropDownGroupList; let i = index">
				<div class="col-sm-6 col-md-2 pb-1 d-inline-flex">
					<select
						*ngIf="dropDownGroup.name != 'tags'; else tagsDropDown"
						class="form-control input-sm"
						[(ngModel)]="selectedDropDown[dropDownGroup.name]"
						(change)="onSelectionChanged([dropDownGroup.name, $event.target.value])"
					>
						<option *ngFor="let option of dropDownGroup.options" [value]="option.value">
							{{option.label}}
						</option>
					</select>
				</div>
				<div class="d-inline-flex ml-auto" *ngIf="i == 4 || dropDownGroupList.length < 5 && i == dropDownGroupList.length-1">
					<a class="btn btn-xs" (click)="onPrint()" href="javascript:" *ngIf="options.hasPrint && !noDataForCurrentFilters">
						<i class="fa fa-print"></i>
					</a>
				</div>
			</ng-container>
			<ng-template #tagsDropDown>
				<ng-select
					[multiple]="true"
					[items]="tags"
					[addTag]="false"
					[clearable]="false"
					[hideSelected]="true"
					bindLabel="tag_name"
					bindValue="id"
					(add)="onSelectionChanged(['tags', $event])"
					(remove)="onSelectionChanged(['tags', $event])"
					[placeholder]="'tags.filter_by_tag' | translate"
					[(ngModel)]="selectedTags"
				></ng-select>
			</ng-template>
		</div>
		<div class="row noprint">
			<div class="col-lg-12">
				<label *ngFor="let checkbox of checkboxList">
					<input 
						type='checkbox'
						name='{{checkbox.name}}'
						class="form-control inline-form-control" 
						[(ngModel)]="selectedCheckbox[checkbox.name]"
						(ngModelChange)="onSelectionChanged([checkbox.name, $event])"
					/> {{checkbox.label}}
				</label>
			</div>
			<div *ngFor="let radioGroup of radioButtonGroupList" class="col-lg-12">
				<label
					*ngFor="let radio of radioGroup['options']">
					<input 
						type='radio'
						name='{{radioGroup.name}}'
						class="form-control" 
						[(ngModel)]="selectedRadioButton[radioGroup.name]"
						(change)="onSelectionChanged([radio.name, $event.target.value])"
						/> {{radio.label}}
				</label>
			</div>
		</div>

		<div class="margin-top-5 row" *ngIf="!noDataForCurrentFilters">
			<div class="col-md-7">
				<sct-charts
					[data]="chartData"
					[columnNames]="columnNames"
					[options]="config"
					[type]="chartType"
					[height]="500"
					[title]="title"
					(select)="onSelect($event)"
				></sct-charts>
			</div>
			<div *ngIf="tableItems.length > 0" class="col-md-5 mt-auto mb-4 noprint">

				<app-grid
					[id]="exportFileName"
					[columns]="tableColumns"
					[data]="tableItems"
					[sizeColumnsToFit]="sizeColumnsToFit"
					[settings]="{
						hasSelectColumn: false,
						hasReset: false,
						showSummary: false,
						height: '',
						enableFloatingFilter: false,
						pagination: true,
						paginationPageSize: 4,
						domLayout: 'autoHeight'
					}"
				></app-grid>

				<a *ngIf="tableColumns && tableItems.length > itemsPerPage" (click)="modal.show()" class="text-center pie-view-more noprint" style="margin-top: 10px" href="javascript:">{{'g.view_more' | translate}}</a>

				<app-modal #modal [title]="title" [hasConfirmButton]="false" [hasCloseButton]="true" [size]="'lg'">
					<sct-custom-table
						*ngIf="modal.render"
						[columnsConfig]="chartTableConfig.column"
						[data]="chartTableConfig.data"
						[config]="chartTableConfig.config"
					>
					</sct-custom-table>
				</app-modal>
			</div>
		</div>	
		
		<div class="margin-top-5 row d-flex justify-content-center" *ngIf="noDataForCurrentFilters">
			<h3 class="alert alert-primary p-5">
				{{'g.no_data_available' | translate}}
			</h3>
		</div>
	</div>
</app-simple-widget>