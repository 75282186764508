<div class="d-flex justify-content-between mb-2">
	<h2>{{'sim_management.missing_from_1nce' | translate}}</h2>
	<div class="refresh-container d-flex align-items-center">
		<div class="d-flex flex-column">
			<p *ngIf="lastUpdateSimsTime">{{'g.last_updated_at' | translate}}{{" " + formateDate(lastUpdateSimsTime * 1000)}} ({{commonService.showUserTimeZoneReference()}})</p>
			<p *ngIf="isUpdatingSims">{{'g.updating_the_list' | translate}}</p>
		</div>
		<button class="btn btn-primary" (click)="showRefreshPopup()">{{'g.update_list' | translate}}</button>
	</div>
</div>

<div class="table-container">
	<sct-custom-table
		*ngIf="tableData.length; else noData"
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
	></sct-custom-table>
</div>

<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<app-modal
	[hasCloseButton]="true"
	[hasConfirmButton]="true"
	#actionsConfirmationPopup title="{{'g.confirmation' | translate}}"
	size="md"
>
	<p>{{'sim.update_sims_confirm' | translate}}</p>
</app-modal>
