<div class="main-black border-radius-bottom white-box p-4">

	<div class="d-flex flex-row pb-4" *ngIf="selectedDevices.length > 0 && !hideSetMaintenanceDateButton">
		<button class="btn btn-primary ml-2" (click)="openLastPMModal()">
			{{'actions.set_last_maintenance_date' | translate}}
		</button>
	</div>

	<sct-custom-table
		*ngIf="tableData.length"
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
		(updateSelectedRows)="updateSelectedDevices($event)"
	></sct-custom-table>
</div>

<app-modal #lastPMModal title="{{'actions.set_last_maintenance_date' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="mb-5">
		<input
			class="form-control"
			[ngClass]="{'invalid-input' : !lastPMModalVars.isValid}"
			name="last_pm_date"
			placeholder="mm/dd/yyyy"
			[(ngModel)]="lastPMModalVars.lastPMDate"
			bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [minDate]="lastPMModalVars.min" [maxDate]="lastPMModalVars.max"
			(ngModelChange)="lastPMModalVars.isValid=true"
			check-permission="basic_setup"
			calender
		/>
		<p class="text-danger mt-2" *ngIf="!lastPMModalVars.isValid">{{'g.invalid_input' | translate}}!</p>
	</div>
	<div class="modal-footer">
		<button class="btn btn-default mr-2" (click)="lastPMModal.hide()">{{'g.cancel' | translate}}</button>
		<button class="btn btn-primary" (click)="setLastPMDate()" [disabled]="!lastPMModalVars.isValid">{{'g.ok' | translate}}</button>
	</div>
</app-modal>
