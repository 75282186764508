<div>
	<h1 class="h2 mb-4">{{'nav.iccid_changes_report' | translate}}</h1>
	<sct-custom-table
		*ngIf="tableData.length; else noData"
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
	></sct-custom-table>
</div>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>
