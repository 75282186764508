import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-sim-management',
	templateUrl: './sim-management.component.html',
})


export class SimManagementComponent implements OnInit {
	// table data
	tableData: TableData[] = [];

	tableConfig: TableConfig = {
		hideColumnControl: true,
		hasExport: false,
		hasPagination: true,
		pageSize: 100,
		fitScreen: true
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'iccid', name: this.translateService.instant('sim.iccid'), type: "string"},
		{ key: 'mac_address', name: this.translateService.instant('g.mac_address'), type: 'link' },
	];

	devices: any = {};
	sites: any = {};

	constructor(
		private adminServices: AdminService,
		private usersService: UsersService,
		private router: Router,
		private translateService: TranslateService,
	) { }

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('super_admin', 'write'))
			return this.router.navigate(['/unauthorized']);

		this.getAllSims();
	}

	getAllSims() {
		this.adminServices.getAllSims().subscribe((res: any) => {
			this.tableData = [];
			this.devices = res.devices;
			this.sites = res.sites;

			for (const iccid of res.sims) {
				const device = this.devices[iccid];
				const site = device ? this.sites[device?.site_id] : null;

				this.tableData.push({
					iccid: {value: iccid},
					mac_address: {
						value: device?.mac_address,
						link: device ? ['/', site?.customer_id, device.site_id, device.mac_address, 'performance'] : null
					}
				})
			}
		});
	}
}
