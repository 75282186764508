import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import * as moment from 'moment';
import { UsersService } from 'src/app/users/users.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { TranslateService } from '@ngx-translate/core';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import * as _ from 'lodash';

@Component({
	selector: 'sim-usage-management',
	templateUrl: './sim-usage-management.component.html',
	styleUrls: ['./sim-usage-management.component.css']
})
export class simUsageManagementComponent implements OnInit, OnDestroy {
	@ViewChild("tableDataPopup") tableDataPopup: ModalComponent;
	@ViewChild("exportFilePopup") exportFilePopup: ModalComponent;
	@ViewChild("disabledSimsPopup") disabledSimsPopup: ModalComponent;
	@ViewChild("blockedSimsPopup") blockedSimsPopup: ModalComponent;
	@ViewChild("blockedSimsConfirmationPopup") blockedSimsConfirmationPopup: ModalComponent;
	@ViewChild("actionsConfirmationPopup") actionsConfirmationPopup: ModalComponent;

	columnConfig: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: 'id', hidden: true},
		{ key: 'serial_number', name: this.translate.instant('devices.serial_number'), type: 'link' },
		{ key: 'mac_address', 	name: this.translate.instant('g.mac_address'), type: 'string' },
		{ key: 'customer_name', name: this.translate.instant('g.customer_name'), type: 'link' },
		{ key: 'site_name', name: this.translate.instant('g.site_name'), type: 'link' },
		{ key: 'cellular_iccid', name: this.translate.instant('qv.cellular_iccid'), type: 'link' },
		{ key: 'data_usage', name: this.translate.instant('sim_management.data_usage'), type: 'number' },
		{ key: 'remaining_usage', name: this.translate.instant('sim_management.remaining_usage'), type: 'number', hidden: true },
		{ key: 'weeklyUsage', name: this.translate.instant('sim_management.week_average'), type: 'number', hidden: true },
		{ key: 'monthlyUsage', name: this.translate.instant('sim_management.month_average'), type: 'number', hidden: true },
		{ key: 'updating_fw', name: this.translate.instant('sim_management.updating_fw'), type: 'boolean', filterTrueText: this.translate.instant('g.yes'), filterFalseText: this.translate.instant('g.no') },
		{ key: 'imei', name: this.translate.instant('sim.imei'), type: 'string', hidden: true },
		{ key: 'iccid_status', name: this.translate.instant('sim.iccid_status'), type: 'boolean', filterTrueText: this.translate.instant('sim.enable'), filterFalseText: this.translate.instant('sim.disable')},
		{ key: 'imei_lock', name: this.translate.instant('sim.imei_status'), type: 'boolean', filterTrueText: 'Locked', filterFalseText: 'Unlocked', hidden: true },
		{ key: 'fw_version', name: this.translate.instant('g.fw_version'), type: 'number' },
		{ key: 'debug_enabled', name: this.translate.instant('sim_management.debug_enabled'), type: 'boolean', filterTrueText: this.translate.instant('g.yes'), filterFalseText: this.translate.instant('g.no') },
		{ key: 'metered_device', name: this.translate.instant('sim_management.metered_device'), type: 'boolean', filterTrueText: this.translate.instant('g.yes'), filterFalseText: this.translate.instant('g.no') },
		{ key: 'last_connect_time', name: this.translate.instant('devices.last_connect_time'), type: 'date', hidden: true },
		{ key: 'usage_date', name: this.translate.instant('sim_management.usage_date'), type: 'date', hidden: true },
		{ key: 'expiry_date', name: this.translate.instant('sim_management.expiry_date'), type: 'date', hidden: true},
		{ key: 'reconnect_time', name: this.translate.instant('devices.cellular_reconnect_time'), type: 'number' },
		{ key: 'installation_date', name: this.translate.instant('devices.installation_date'), type: 'date', hidden: true },
		{ key: 'usage_history', name: this.translate.instant('sim_management.usage_history'), type: 'icon'}
	];

	historyColumnModal: ColumnsConfig[] = [
		{ key: 'data_usage', name: this.translate.instant('sim_management.data_usage'), type: 'number'},
		{ key: 'usage_date', name: this.translate.instant('sim_management.usage_date'), type: 'date'},
		{ key: 'updating_fw', name: this.translate.instant('sim_management.updating_fw'), type: 'boolean'}
	];

	disabledSimsColumnsModal: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: 'id', hidden: true},
		{ key: 'mac_address', name: this.translate.instant('g.mac_address'), type: 'link'},
		{ key: 'serial_number', name: this.translate.instant('devices.serial_number'), type: 'string'},
		{ key: 'customer_name', name: this.translate.instant('g.customer_name'), type: 'link'},
		{ key: 'site_name', name: this.translate.instant('g.site_name'), type: 'link'},
		{ key: 'cellular_iccid', name: this.translate.instant('qv.cellular_iccid'), type: 'link'},
		{ key: 'last_disabled_time', name: this.translate.instant('sim.last_disabled_time'), type: 'date_with_ref'},
		{ key: 'disabled_source', name: this.translate.instant('sim.disabled_source'), type: 'string'}
	];

	blockedSimsColumnModal: ColumnsConfig[] = [
		{ key: 'id', name: 'id', type: 'id', hidden: true},
		{ key: 'serial_number', name: this.translate.instant('devices.serial_number'), type: 'link'},
		{ key: 'mac_address', name: this.translate.instant('g.mac_address'), type: 'string'},
		{ key: 'customer_name', name: this.translate.instant('g.customer_name'), type: 'link'},
		{ key: 'site_name', name: this.translate.instant('g.site_name'), type: 'link'},
		{ key: 'cellular_iccid', name: this.translate.instant('qv.cellular_iccid'), type: 'link'},
		{ key: 'last_block_time', name: this.translate.instant('sim_management.blocked_since'), type: 'date_with_ref'}
	];

	tableConfig: TableConfig = {
		hasExport: true,
		hasPagination: true,
		pageSize: 100,
		draggable: true,
		hasSelectionColumn: true,
		fileName: this.translate.instant('sim.sim_usage_management')
	};

	historyTableConfig: TableConfig = {
		hasExport: true,
		hasPagination: false,
		draggable: true,
		fileName: this.translate.instant('sim.sim_usage_management')
	};

	disabledSimsTableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		draggable: true,
		hasSelectionColumn: true,
	};

	isHidePopupShown = false;
	draggedColumn = "";

	selectedDeviceData: any;
	toShowUsageHistory: any = [];
	devices: any = [];
	disabledSims: any = [];
	blockedSims: any = [];
	selectedICCIDs: number[] = [];
	selectedBlockedICCIDs: string[] = [];
	doneBlockICCIDs: string[] = [];
	blockActionDone: boolean = false;
	selectedDisabledICCIDs: string[] = [];
	sortResult: any[] = [];
	weeklyUsage: any[] = [];
	monthlyUsage: any[] = [];
	actionReason: string = '';

	pageSizeOptions: number[] = [100];
	pageSize: number = this.pageSizeOptions[0];
	operation: 'enable' | 'disable' | 'lock' | 'unlock' | undefined;
	ICCIDs = [];

	unblockReason = '';
	currentPage: number = 1;
	toShowDevices: any = [];

	fromDate: Date = null;
	toDate: Date = null;

	popupFromData: Date = new Date();
	popupToData: Date = new Date();

	notValidDate = false;
	notValidPopupDate = false;

	simOverUsageLimit140K = 140 * 1024;
	simOverUsageLimit1M = 1024 * 1024;
	simOverUsageLimit3M = 1024 * 1024 * 3;
	simOverUsageLimit5M = 1024 * 1024 * 5;

	usageConfig = ['5M', '3M', '1M', '140K'];

	disabledSources = {
		1: 'sim.disable_system',
		2: 'sim.disable_1nce'
	};

	chartObject: any = {
	formatterType: 'DateFormat',
	formatter: {pattern: "MM/dd"},
		columnNames: ['Date', 'Data Usage'],
	};

	boolFields = ['imei_lock', 'updating_fw', 'debug_enabled', 'metered_device'];
	timeFields = ['last_connect_time', 'usage_date', 'expiry_date', 'installation_date'];
	stringFields = ['cellular_iccid', 'mac_address', 'serial_number', 'customer_name', 'site_name', 'imei', 'iccid_status', 'fw_version'];
	numberFields = ['weeklyUsage', 'monthlyUsage', 'usage', 'data_usage', 'reconnect_time', 'remaining_usage'];

	constructor(
		private router: Router,
		private userService: UsersService,
		private deviceService: DeviceService,
		private notificationMessage: NotificationMessageService,
		private commonService: CommonService,
		private commonData: CommonDataService,
		public translate: TranslateService,
	) {}

	ngOnDestroy(): void {
		this.disabledSimsPopup.hide();
		this.blockedSimsPopup.hide();
		this.blockedSimsConfirmationPopup.hide();
		this.tableDataPopup.hide();
		this.exportFilePopup.hide();
	}

	ngOnInit() {
		if (!this.userService.hasAccessPermission(null, 'noc', 'write'))
			this.router.navigate(['/unauthorized']);

		const isDev = window.location.hostname.includes(this.commonData.developmentDomain);
		if (isDev)
			this.tableConfig.pageSize = 20;

		//one day shift because we show yesterday usage
		this.popupToData.setDate(this.popupToData.getDate() - 1);
		this.popupFromData.setDate(this.popupFromData.getDate() - 31);

		this.getSimDevicesData();
	}

	confirmUnblock() {
		this.unblockSims();
		return ;
	}

	ngAfterViewInit() {
		this.actionsConfirmationPopup.onClose.subscribe((ok) => {
			this.actionsConfirmationPopup.closeOnConfirm = true;
			if(!ok) {
				this.actionReason = '';
				return;
			}

			if(!this.actionReason) {
				this.actionsConfirmationPopup.closeOnConfirm = false;
				return;
			}

			this.updateSimConfig(this.operation);
			this.actionReason = '';
		});

		this.blockedSimsPopup.onClose.subscribe((ok) => {
			this.selectedBlockedICCIDs = [];
		});

		this.disabledSimsPopup.onClose.subscribe((ok) => {
			this.selectedDisabledICCIDs = [];
		});
	}

	getSimDevicesData() {
		this.toShowDevices = [];
		this.blockedSims = [];
		this.disabledSims = [];
		this.deviceService.getSimDevicesData().subscribe((response: any) => {
			this.weeklyUsage = response.weeklyUsage;
			this.monthlyUsage = response.monthlyUsage;
			const disabledSimsInfo = response.disabledSims;
			const blockedSimsInfo = response.blockedSims;

			this.devices = this.formateDeviceUsageData(response.sims);
			[...this.devices].forEach((row) => {
				let rowData: TableData = {
					row,
					id: {value: row.cellular_iccid},
					serial_number: {value: row.serial_number, link: ['/', row.customer_id, row.site_id, row.mac_address, 'performance']},
					mac_address: {value: row.mac_address},
					customer_name: {value: row.customer_name, link: ['/', row.customer_id]},
					site_name: {value: row.site_name, link: ['/', row.customer_id, row.site_id]},
					cellular_iccid: {value: row.cellular_iccid, link: ['/',row.customer_id, row.site_id, row.mac_address,'cellular-settings']},
					data_usage: {value: (row.data_usage / 1024).toFixed(2), backGroundColor: this.getClass(row)},
					remaining_usage: {value: (row.remaining_usage / 1024).toFixed(2)},
					weeklyUsage: {value: row.weeklyUsage},
					monthlyUsage: {value: row.monthlyUsage},
					updating_fw: {value: row.updating_fw, trueText: this.translate.instant('g.yes'), falseText: this.translate.instant('g.no')},
					imei: {value: row.imei},
					iccid_status: {value: row.iccid_status == 'Enabled', trueText: this.translate.instant('sim.enable'), falseText: this.translate.instant('sim.disable')},
					imei_lock: {value: row.imei_lock, trueText: this.translate.instant('sim.lock'), falseText: this.translate.instant('sim.unlock')},
					fw_version: {value: row.fw_version || '-'},
					debug_enabled: {value: row.debug_enabled, trueText: this.translate.instant('g.yes'), falseText: this.translate.instant('g.no')},
					metered_device: {value: row.metered_device, trueText: this.translate.instant('g.yes'), falseText: this.translate.instant('g.no')},
					last_connect_time: {value: this.formateDate(row.last_connect_time)},
					usage_date: {value: row.usage_date},
					expiry_date: {value: this.formateDate(row.expiry_date, 'MM/DD/YYYY')},
					reconnect_time: {value: row.reconnect_time || '-'},
					installation_date: {value: this.formateDate(row.installation_date, 'MM/DD/YYYY'), sortValue: row.installation_date},
					usage_history: {icon: 'history', action: () => this.showUsageHistory(row), iconColor: 'green'}
				}
				this.toShowDevices.push(rowData);
			});

			[...disabledSimsInfo].forEach((record) => {
				let records: TableData = {
					record,
					id: {value: record.mac_address},
					mac_address: {value: record.mac_address, link: ['/', record.customer_id, record.site_id, record.mac_address, 'performance']},
					serial_number: {value: record.serial_number},
					customer_name: {value: record.customer_name, link: ['/', record.customer_id]},
					site_name: {value: record.site_name, link: ['/', record.customer_id, record.site_id]},
					cellular_iccid: {value: record.cellular_iccid, link: ['/',record.customer_id, record.site_id, record.mac_address, 'cellular-settings']},
					last_disabled_time: {value: this.formateDate(record.last_disabled_time, 'MM/DD/YYYY hh:mm:ss A')},
					disabled_source: {value: this.translate.instant(this.disabledSources[record.disabled_source] || '---')}
				}
				this.disabledSims.push(records);
			});

			[...blockedSimsInfo].forEach((row) => {
				let rowData: TableData = {
					row,
					id: {value: row.mac_address},
					serial_number: {value: row.serial_number, link: ['/', row.customer_id, row.site_id, row.mac_address, 'performance']},
					mac_address: {value: row.mac_address},
					customer_name: {value: row.customer_name, link: ['/', row.customer_id]},
					site_name: {value: row.site_name, link: ['/', row.customer_id, row.site_id]},
					cellular_iccid: {value: row.cellular_iccid, link: ['/',row.customer_id, row.site_id, row.mac_address, 'cellular-settings']},
					last_block_time: {value: row.last_block_time != '---'? this.formateDate(row.last_block_time, 'MM/DD/YYYY hh:mm:ss A') : '---'}
				}
				this.blockedSims.push(rowData);
			});
		});
	}

	formateDeviceUsageData(response) {
		const formateDevicesData = {};

		if (!response)
			return formateDevicesData;

		const sortedResponse = this.sortByDataAndUsage(response);

		for(let row in sortedResponse) {
			const rowData : any = response[row];
			rowData.usage_date = this.formateUsageDate(rowData.usage_date);
			rowData.expiry_date = this.formateUsageDate(rowData.expiry_date);

			if (!Object.keys(formateDevicesData).includes(rowData.mac_address)) {
				formateDevicesData[rowData.mac_address] = rowData;
				formateDevicesData[rowData.mac_address]['weeklyUsage'] = ((this.weeklyUsage[rowData.mac_address]?.average || 0) / 1024 ).toFixed(2);
				formateDevicesData[rowData.mac_address]['monthlyUsage'] = ((this.monthlyUsage[rowData.mac_address]?.average || 0) / 1024).toFixed(2);
			}
		}

		return [...Object.values(formateDevicesData)];
	}

	sortByDataAndUsage(DataArray) {
		DataArray.sort((a, b) => {
			const aTime = moment(a.usage_date);
			const bTime = moment(b.usage_date);

			if (aTime.isSame(bTime, 'day')) {
				return b.data_usage - a.data_usage;
			}

			return bTime.unix() - aTime.unix();
		});

		return DataArray;
	}

	formateDate(date: moment.MomentInput, format = 'MM/DD/YYYY hh:mm:ss a') {
		return date && date != '---' ? moment(date).utc().format(format || 'MM/DD/YYYY hh:mm:ss a') : '---';
	}

	formateUsageDate(date: moment.MomentInput) {
		return date && date != '---' ? moment(date).utc().format('MM/DD/YYYY') : '---';
	}

	prepareChartData(usageHistory) {
		const data = [];

		if (!usageHistory.length)
			return [];

		usageHistory.forEach(dayData => {
			const usage = +(dayData.data_usage / 1024).toFixed(0);
			const formattedTime = moment(dayData.usage_date).utc().format('D MMM');
			data.push([formattedTime, usage]);
		})

		return data;
	}

	showUsageHistory(deviceData, openPopup = true) {
		this.toShowUsageHistory = [];
		let zoneDiff = new Date().getTimezoneOffset() * -1;

		let from:any = new Date(new Date(this.popupFromData).getTime() + (zoneDiff * 60 * 1000));
		let to:any = new Date(new Date(this.popupToData).getTime() + (zoneDiff * 60 * 1000));

		from = moment(from).utc().startOf('day').unix();
		to = moment(to).utc().endOf('day').unix();

		if (this.popupFromData && from > to)
			return this.notValidPopupDate = true;

		this.notValidPopupDate = false;

		this.deviceService.getSimUsageHistory(deviceData.mac_address, from, to).subscribe((response: any) => {
			this.selectedDeviceData = deviceData;
			const deviceUsageHistoryData = this.sortByDataAndUsage(response);
			const data = deviceUsageHistoryData.length ? this.prepareChartData(deviceUsageHistoryData) : [];

			this.chartObject.data = data.reverse();
			[...deviceUsageHistoryData].forEach((row) => {
				let rowData: TableData = {
					row,
					data_usage:  {value: (row.data_usage / 1024).toFixed(2), backGroundColor: this.getClass(row)},
					usage_date:  {value: this.formateUsageDate(row.usage_date)},
					updating_fw: {value: row.updating_fw, trueText: this.translate.instant('g.yes'), falseText: this.translate.instant('g.no')}
				}
				this.toShowUsageHistory.push(rowData);
			});

			if (openPopup)
				this.tableDataPopup.show();
		})
	}

	showUpdateConfigPopup(operation: 'enable' | 'disable' | 'lock' | 'unlock', fromPopup=false) {
		this.operation = operation;
		this.ICCIDs = fromPopup ? this.selectedDisabledICCIDs : this.selectedICCIDs;
		this.actionsConfirmationPopup.show();
	}

	updateSimConfig(operation: 'enable' | 'disable' | 'lock' | 'unlock') {
		this.deviceService.updateSimConfig(this.ICCIDs, operation, this.actionReason).subscribe((res: any) => {
			if (res.api_status == 2)
				return this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});

			return this.notificationMessage.setMessage('translate|sim_management.action_will_applied_tomorrow', {clearOnXTimeNavigate: 1, type: 'success'});
		});
	}

	unblockSims() {
		let ICCIDs = this.selectedBlockedICCIDs;

		if (!ICCIDs.length)
			return;

		this.deviceService.unblockSims(ICCIDs, this.unblockReason).subscribe((res: any) => {
			if (res.api_status == 2)
				return this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});

			this.blockActionDone = true;
			this.doneBlockICCIDs = res;

			return this.notificationMessage.setMessage('translate|sim_management.action_will_applied_tomorrow', {clearOnXTimeNavigate: 1, type: 'success'});
		});
	}

	get shouldShowSimActions() {
		return this.userService.hasAccessPermission(null, 'noc', 'write');
	}

	selectICCID(rows) {
		this.selectedICCIDs = _.uniq(rows.map(item => item.cellular_iccid.value));
	}

	selectBlockedICCID(rows) {
		this.selectedBlockedICCIDs = _.uniq(rows.map(item => item.cellular_iccid.value));
	}

	selectDisabledICCID(rows) {
		this.selectedDisabledICCIDs = _.uniq(rows.map(item => item.cellular_iccid.value));
	}

	showDisabledSims() {
		this.disabledSimsPopup.show();
	}

	showBlockedSims() {
		this.selectedBlockedICCIDs = [];
		this.blockedSimsPopup.show();
	}

	showBlockedSimsConfirmationPopup() {
		this.unblockReason = '';
		this.blockActionDone = false;
		this.blockedSimsConfirmationPopup.show();
	}

	checkDateValidation() {
		if (this.fromDate && this.toDate && moment(this.fromDate).unix() > moment(this.toDate).unix())
			this.notValidDate = true;
		else
			this.notValidDate = false;
	}

	getClass(row) {
		const colorLevel = {
			1: '#ffff00',
			3: '#ff9900',
			5: '#ff0000',
			50: '#e20aea',
			140: '#2783ca'
		}
		return colorLevel[row.log_level];
	}
}
