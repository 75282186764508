
<div class="main-black border-radius-bottom white-box">
	<div class="row m-0 px-4 py-5">
		<div class="col-6">
			<div class="row m-0 mb-5">
				<h3 class="font-weight-bold" *ngIf="siteInfo.name">
					{{siteInfo.name}}
					<app-help-tooltip *ngIf="tooltips.site_info" [field]="tooltips.site_info" ></app-help-tooltip>
				</h3>
				<div class="ml-auto my-auto">
					<a
						class="btn main-blue-bg text-white px-2 rounded-lg mr-2"
						[routerLink]="['download-status']">
						{{'site.site_download_status' | translate}}
					</a>
					<a *ngIf="usersService.hasAccessFunction('site_management') && currentUser.isSCT"
						class="btn main-green-bg text-white px-2 rounded-lg"
						[routerLink]="['/sites', siteInfo.customer_id, 'edit', siteInfo.id]">
						{{'site.edit_site' | translate}}
					</a>
				</div>
				<div class="w-100" *ngIf="currentUser.isSCT && siteInfo.original_enterprise && siteInfo.is_auto_generated">
					<p class="badge text-white bg-main-black-color">
						{{'site.this_site_is_generated' | translate}}
						<span *ngIf="!usersService.hasAccessFunction('enterprise_management')">
							{{siteInfo.original_enterprise_name}}
						</span>
						<a *ngIf="usersService.hasAccessFunction('enterprise_management')" routerLink="/enterprise/edit/{{siteInfo.original_enterprise}}"> {{siteInfo.original_enterprise_name}} </a>
					</p>

				</div>
			</div>

			<!-- <div class="mb-4" *ngIf="0 && currentUser['is_admin']">
				<div *ngFor="let enterprise of enterprises">
					<i><b>{{enterprise.name}}</b></i>
				</div>
			</div> -->
			<span *ngIf="usersService.hasAccessFunction('site_management')">
				<label><input type="checkbox" [(ngModel)]="showOnlyConnected" class="mr-2" (ngModelChange)="showOnlyConnectedIPs(showOnlyConnected)">{{'site.only_connected' | translate}}</label>
				<div class="row m-0 mb-3 px-3 py-2 rounded light-grey-border">
					<span class="light-grey">{{'site.unique_ip_addresses' | translate}}</span>
					<b class="ml-auto">{{uniqueIPaddresses.length > 0 ? uniqueIPaddresses.join(', ') : 'No IP'}}</b>
				</div>
			</span>

			<ng-container *ngIf="contactInfo.length">
				<div class="row mb-4">
					<div class="col-1"><i class="fa fa-envelope-o fa-2x main-blue"></i></div>
					<div class="col-11">
						<h5 class="font-weight-bold">{{'g.contact_info' | translate}}</h5>
						<div *ngFor="let field of contactInfo">
							{{field}}
						</div>
					</div>
				</div>
			</ng-container>
			<div class="row mb-4">
				<div class="col-1"><i class="fa fa-clock-o fa-2x main-blue"></i></div>
				<div class="col-11">
					<h5 class="font-weight-bold">{{'devices.zone_id' | translate}}</h5>
					<div>
						{{liveSiteTime | date:'MM/dd/yyyy hh:mm:ss a'}} - {{zoneName}}
					</div>
				</div>
			</div>
			<div class="row mb-4">
				<div class="col-1"><i class="fa fa-calendar-check-o fa-2x main-blue"></i></div>
				<div class="col-11">
					<h5 class="font-weight-bold">{{'site_edit.installation_date' | translate}}</h5>
					<div>
						{{siteInfo['installation_date'] | date:'MM/dd/yyyy'}}
					</div>
				</div>
			</div>
			<div class="row mb-4">
				<div class="col-1"><i class="fa fa-map-marker fa-2x main-blue"></i></div>
				<div class="col-11">
					<h5 class="font-weight-bold">{{'site_edit.address' | translate}}</h5>
					<div>
						{{siteInfo['address']}}
					</div>
					<div *ngIf="siteInfo['address_line2']">
						{{siteInfo['address_line2']}}
					</div>
				</div>
			</div>
			<div class="row mb-4" *ngIf="siteInfo.electrician_phone_num || siteInfo.electrician_contact_name || siteInfo.electrician_email">
				<div class="col-1"><img src="/sctportal/images/electrician.png" width="45"></div>
				<div class="col-11">
					<h5 class="font-weight-bold mb-2">
						{{'site.electrician_contact_info' | translate}}
					</h5>
					<div *ngIf="siteInfo.electrician_contact_name" class="mb-2">
						<h6 class="font-weight-bold">
							{{'g.contact_name' | translate}}
						</h6>
						<span>
							{{siteInfo.electrician_contact_name}}
						</span>
					</div>
					<div *ngIf="siteInfo.electrician_phone_num" class="mb-2">
						<h6 class="font-weight-bold">
							{{'g.phone_num' | translate}}
						</h6>
						<span>
							{{siteInfo.electrician_phone_num}}
						</span>
					</div>
					<div *ngIf="siteInfo.electrician_email">
						<h6 class="font-weight-bold">
							{{'g.email' | translate}}
						</h6>
						<span>
							{{siteInfo.electrician_email}}
						</span>
					</div>
				</div>
			</div>
			<div class="row mb-4" *ngIf="siteInfo.sales_person_name || siteInfo.sales_person_phone_num || siteInfo.sales_person_email">
				<div class="col-1"><img src="/sctportal/images/salesperson.png" width="45"></div>
				<div class="col-11">
					<h5 class="font-weight-bold mb-2">
						{{'site.sales_person_info' | translate}}
					</h5>
					<div *ngIf="siteInfo.sales_person_name" class="mb-2">
						<h6 class="font-weight-bold">
							{{'g.contact_name' | translate}}
						</h6>
						<span>
							{{siteInfo.sales_person_name}}
						</span>
					</div>
					<div *ngIf="siteInfo.sales_person_phone_num" class="mb-2">
						<h6 class="font-weight-bold">
							{{'g.phone_num' | translate}}
						</h6>
						<span>
							{{siteInfo.sales_person_phone_num}}
						</span>
					</div>
					<div *ngIf="siteInfo.sales_person_email">
						<h6 class="font-weight-bold">
							{{'g.email' | translate}}
						</h6>
						<span>
							{{siteInfo.sales_person_email}}
						</span>
					</div>
				</div>
			</div>
			<div *ngIf="infoBoxSiteAnalytics?.deviceCount">
				<select class="custom-select custom-select-lg mb-3" [(ngModel)]="selectedDataOption" (ngModelChange)="setInfoBoxSiteAnalyticsData()">
					<option selected value="since_installation">{{'durations.since_installation' | translate }}</option>
					<option value="last_quarter">{{'durations.last_quarter' | translate }}</option>
				</select>
			</div>
		</div>
		<div class="col-6">
			<app-mapbox
				*ngIf="siteInfo.id && sideMenuService.showMap"
				class="border-radius main-blue-border"
				dotsType="mix"
				[latitude]="siteInfo.latitude || sitesService.defaultMapLocation.latitude"
				[longitude]="siteInfo.longitude || sitesService.defaultMapLocation.longitude"
				zoom="2.8"
				[dots]="dots"
			></app-mapbox>
		</div>
	</div>
	<div class="row m-0 px-4 py-5">
		<div class="col-12">
			<app-info-box *ngIf="infoBoxSiteAnalytics"
				[ioTAhDevices]="infoBoxSiteAnalytics.ioTAh_devices"
				[chargLinkDevices]="infoBoxSiteAnalytics.chargLink_devices"
			></app-info-box>
		</div>
	</div>
</div>

<!-- quarterly_site_report ( IoTAh devices ) -->
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="selectedMonthData">
	<div class="row">
		<div class="col">
			<h4>
				{{'site.quarterly_site_report_title' | translate}}
				<app-help-tooltip *ngIf="tooltips.quarterly_site_report" [field]="tooltips.quarterly_site_report"></app-help-tooltip>
			</h4>
		</div>
	</div>
	<div class="d-flex flex-wrap justify-content-between py-4">
		<div class="d-flex justify-content-start flex-wrap">

			<!-- select year -->
			<div class="mx-2 quarterly-filter">
				<ng-select class="mr-auto w-100" [items]="yearsOption" bindLabel="label" placeholder="{{'g.select_year' | translate}}" name="year" [(ngModel)]="selectedYearQ" (ngModelChange)="loadQuarterData(false)" [clearable]="false">
				</ng-select>
			</div>
			 <!-- select quarter -->
			<div class="mx-2 quarterly-filter">
				<ng-select class="mr-auto w-100" [items]="monthsOption" bindLabel="label" bindValue="value" placeholder="{{'g.select_month' | translate}}" name="year" [(ngModel)]="selectedMonth" (ngModelChange)="loadQuarterData(false)" [clearable]="false">
				</ng-select>
			</div>
			<div class="mx-2 quarterly-filter">
				<ng-select class="mr-auto w-100" [items]="truckYearsList" bindLabel="label" bindValue="value" placeholder="{{'devices.truck_manufacturing_year' | translate}}" name="truckYear" [(ngModel)]="selectedTruckYear" (ngModelChange)="loadQuarterData(false)" [clearable]="false">
				</ng-select>
			</div>
		</div>
		<div class="dropdown ml-auto" appDropdown>
			<button class="py-2 fa fa-upload btn pointer dropdown btn-primary dropdown-toggle"  title="{{'g.export_to_csv' | translate}}" type="button" id="quartersExports"></button>
			<ul class="dropdown-menu grid-dropdown-menu dropdown-menu-custom dropdown-menu-right" role="menu">
				<li>
					<a class="pointer" (click)="exportMonthlyData(true)"><small><i class="fa fa-upload mr-1"></i>{{'g.all_months' | translate}}</small></a>
				</li>
				<li>
					<a class="pointer" (click)="exportMonthlyData()"><small><i class="fa fa-upload mr-1"></i>{{'g.selected_month' | translate}}</small></a>
				</li>
			</ul>
		</div>
	</div>
	<div>
		<sct-custom-table
			*ngIf="selectedMonthData.length"
			[data]="truckUsageReportTableConfig.data"
			[columnsConfig]="truckUsageReportTableConfig.column"
			[config]="truckUsageReportTableConfig.config"
			>
		</sct-custom-table>
	</div>
</div>

<app-usage-time-widget
	[devices]="devices"
	[site]="siteInfo"
	[tags]="tags"
	[options]="{
		hasTruckTypeFilter: true,
		hasYearFilter: true
	}"
	 [parentPage]="'site'"
></app-usage-time-widget>

<!-- quarterly_site_report ( ChargLink devices) -->
<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="charglinkSelectedQuarterData">
	<div class="row">
		<div class="col">
			<h4>
				{{'site.chargLinkeQuarterly_site_report_title' | translate}}
				<app-help-tooltip *ngIf="tooltips.chargLink_quarterly_site_report" [field]="tooltips.chargLink_quarterly_site_report"></app-help-tooltip>
			</h4>
		</div>
	</div>
	<div class="d-flex flex-wrap justify-content-between py-4">
		<div class="d-flex justify-content-start col-4 align-items-center">
			<!-- select year -->
			<label class="mr-2">{{'g.select_year' | translate}}</label>
			<ng-select class="w-25" [items]="chargLinkYearsOption" bindLabel="label" placeholder="{{'g.select_year' | translate}}" name="year" [(ngModel)]="chargLinkSelectedYear" (ngModelChange)="loadQuarterData(true)" [clearable]="false"></ng-select>

			<!-- select quarter -->
			<label class="mx-2">{{'g.select_quarter' | translate}}</label>
			<ng-select class="w-25" [items]="chargLinkQuartersOption" bindLabel="label" placeholder="{{'g.select_quarter' | translate}}" name="year" [(ngModel)]="chargLinkSelectedQuarter" (ngModelChange)="loadQuarterData(true)" [clearable]="false"></ng-select>
		</div>
		<div class="dropdown ml-auto" appDropdown>
			<button class="py-2 fa fa-upload btn pointer dropdown btn-primary dropdown-toggle"  title="{{'g.export_to_csv' | translate}}" type="button" id="quartersExports"></button>
			<ul class="dropdown-menu grid-dropdown-menu dropdown-menu-custom dropdown-menu-right" role="menu">
				<li>
					<a class="pointer" (click)="exportChargeLinkQuarterData(true)"><small><i class="fa fa-upload mr-1"></i>{{'g.all_quarters' | translate}}</small></a>
				</li>
				<li>
					<a class="pointer" (click)="exportChargeLinkQuarterData()"><small><i class="fa fa-upload mr-1"></i>{{'g.selected_quarter' | translate}}</small></a>
				</li>
			</ul>
		</div>
	</div>
	<div>
		<sct-custom-table
			[data]="chargLinkQuarterlyTableConfig.data"
			[columnsConfig]="chargLinkQuarterlyTableConfig.column"
			[config]="chargLinkQuarterlyTableConfig.config"
		>
		</sct-custom-table>
	</div>
</div>

<!-- Installed Devices ( IoTAh ) -->
<div *ngIf="IoTAhDevices.length > 0 && installedIotahDeviceTableConfig.column.length > 0 && usersService.hasAccessPermission({permission: permissions}, 'noc')" class="white-box main-black mt-4 p-4 border-radius">
	<div class="col-sm-6 col-md-6">
		<h4>
			{{'site.site_devices' | translate}}
			<app-help-tooltip *ngIf="tooltips.site_devices" [field]="tooltips.site_devices"></app-help-tooltip>
		</h4>
	</div>
	<sct-custom-table
		[columnsConfig]="installedIotahDeviceTableConfig.column"
		[data]="installedIotahDeviceTableConfig.data"
		[config]="installedIotahDeviceTableConfig.config"
	>
	</sct-custom-table>
</div>

<!-- Installed Devices ( ChargLink ) -->
<div *ngIf="chargeLinkDevices.length > 0 && this.installedChargerDeviceTableConfig.column.length > 0 && usersService.hasAccessPermission({permission: permissions}, 'noc')" class="white-box main-black mt-4 p-4 border-radius">
	<div class="col-sm-6 col-md-6">
		<h4>
			{{'chargLinkDevice.site.site_devices' | translate}}
			<app-help-tooltip *ngIf="tooltips.chargLink_site_devices" [field]="tooltips.chargLink_site_devices"></app-help-tooltip>
		</h4>
	</div>
	<sct-custom-table
		[columnsConfig]="installedChargerDeviceTableConfig.column"
		[data]="installedChargerDeviceTableConfig.data"
		[config]="installedChargerDeviceTableConfig.config"
	>
	</sct-custom-table>
</div>

<!-- ( IoTAh ) Devices Usage Data -->
<div *ngIf="IoTAhDevices.length">
<app-usage-data
		[data]="siteDevicesAnalytics.usageData.recent.iotah"
		[columns]="siteDevicesAnalytics.usageData.recent.iotah[0]"
		[devices]="IoTAhDevices"
		[isChargeLinkDevices]="false"
		[options]="{hasTruckTypeFilter: true, hasYearFilter: true, hasStackedColumn: true}"
		parentPage="site"
		[tags]="tags"
		[siteInfo]="{id: siteInfo.id, name: siteInfo.name}"
	></app-usage-data>
</div>

<!-- ( ChargLink ) Devices Usage Data -->
<div *ngIf="chargeLinkDevices.length">
	<app-usage-data
		[data]="siteDevicesAnalytics.usageData.recent.chargLink"
		[columns]="siteDevicesAnalytics.usageData.recent.chargLink[0]"
		[devices]="chargeLinkDevices"
		[isChargeLinkDevices]="true"
		[options]="{hasTruckTypeFilter: true, hasYearFilter: true, hasStackedColumn: true}"
		parentPage="site"
		[tags]="tags"
		[siteInfo]="{id: siteInfo.id, name: siteInfo.name}"
	></app-usage-data>
</div>

<!-- ( IoTAh ) Devices Quarter Usage Data -->
<div *ngIf="IoTAhDevices.length">
	<app-quarterly-usage-data
		[data]="siteDevicesAnalytics.usageData.months.iotah"
		[devices]="IoTAhDevices"
		[isChargeLinkDevices]="false"
		[options]="{hasTruckTypeFilter: true, hasYearFilter: true, hasStackedColumn: true}"
		parentPage="site"
		[tags]="tags"
		[siteInfo]="{id: siteInfo.id, name: siteInfo.name}"
		[truckTypes]="siteDevicesAnalytics.trucksIndexes.iotah"
	></app-quarterly-usage-data>
</div>

<!-- ( ChargLink ) Devices Quarter Usage Data -->
<div *ngIf="chargeLinkDevices.length">
	<app-quarterly-usage-data
		[data]="siteDevicesAnalytics.usageData.quarter.chargLink"
		[devices]="chargeLinkDevices"
		[isChargeLinkDevices]="true"
		[options]="{hasTruckTypeFilter: true, hasYearFilter: true, hasStackedColumn: true}"
		parentPage="site"
		[tags]="tags"
		[siteInfo]="{id: siteInfo.id, name: siteInfo.name}"
		[truckTypes]="siteDevicesAnalytics.trucksIndexes.chargLink"
	></app-quarterly-usage-data>
</div>
