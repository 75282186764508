<div class="my-4">
	<button *ngIf="userService.hasAccessFunction('add_device')" class="btn btn-primary mb20" type="button" (click)="goToAddDevice()">{{'g.add_new' | translate}} <span class="glyphicon glyphicon-plus"></span></button>
	<button [disabled]="selectedDevices.length == 0" style="margin-left: 10px;" class="btn btn-primary mb20" type="button"
			*ngIf="this.userService.hasAccessFunction('super_admin', 'write') || (siteid === sitesService.sctInventorySiteId && userService.hasAccessFunction('sct_inventory_device_management')) || (!isCMdevicesMode && !siteSpecial && userService.hasAccessFunction('device_management'))"
			[routerLink]="['/device', 'management', selectedDevices.join(',')]">{{'devices.manage_device' | translate}}</button>

</div>
<div class="my-4">
	<sct-custom-table
		*ngIf="tableData.length"
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
		(updateSelectedRows)="updateSelectedDevices($event)"
	></sct-custom-table>
</div>
<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>
