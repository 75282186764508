<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<h3>{{'suggested_installation_date_report.title' | translate}}</h3>
	<hr/>

	<b class="text-warning">* {{'suggested_installation_date_report.note' | translate}}</b>

	<div class="row mb-3 mt-4">
		<div class="col">
			<h6>{{ 'g.customer_name' | translate }}</h6>
			<ng-select
				[items]="customersList"
				bindLabel="customer_name"
				bindValue="id"
				placeholder="{{'customer.select_customer' | translate}}"
				name="customer_id"
				[clearable]="false"
				[(ngModel)]="selectedCustomerId"
				(ngModelChange)="getCustomerSites($event)"
				required
			></ng-select>
		</div>
		<div class="col">
			<h6>{{ 'g.site_name' | translate }}</h6>
			<ng-select
				[items]="sitesList"
				bindLabel="name"
				bindValue="id"
				placeholder="{{'site.select_site' | translate}}"
				name="site_id"
				[clearable]="false"
				[(ngModel)]="selectedSiteId"
				required
			></ng-select>
		</div>
		<div class="col">
			<h6>{{ 'suggested_installation_date_report.installation_date_provided_by_the_installer' | translate }}</h6>
			<input type="date" name="date" [(ngModel)]="selectedDate" placeholder="{{'g.date' | translate}}" class="form-control" required />
		</div>
		<div class="col">
			<h6>{{ 'suggested_installation_date_report.gap_period' | translate }} ({{ 'time.days' | translate }})</h6>
			<input type="number" name="gap_period" min="0" [(ngModel)]="selectedGapPeriod" placeholder="{{'suggested_installation_date_report.gap_period' | translate}}" class="form-control" required />
			<small class="text-danger">{{ 'suggested_installation_date_report.gap_period_restrictions' | translate }}</small>
		</div>
	</div>
	<div class="row">
		<div class="col-2">
			<button class="btn btn-primary w-75" type="button" (click)="generateReport()" [disabled]="disableEnableGenerateButton()">{{'registration_purposes.generate_report' | translate}}</button>
		</div>
	</div>
	<div class="table-container mt-3">
		<sct-custom-table
			*ngIf="tableData.length; else noData"
			[columnsConfig]="columnConfig"
			[data]="tableData"
			[config]="tableConfig"
			(updateSelectedRows)="updateSelectedDevices($event)"
		></sct-custom-table>
		</div>
	<ng-template #noData>
		<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
	</ng-template>
</div>
