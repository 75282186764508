<div>
	<div class="white-box p-4">
		<h2>{{'nav.devices_location_mismatch' | translate}}</h2>
		<sct-custom-table
			*ngIf="tableData.length; else noData"
			[columnsConfig]="columnConfig"
			[data]="tableData"
			[config]="tableConfig"
		></sct-custom-table>
	</div>
</div>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>
