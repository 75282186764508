<div class="d-flex justify-content-between mb-2 px-2">
	<h2>{{'connectivity_verification.title' | translate}}</h2>
</div>
<sct-custom-table
	*ngIf="tableData.length; else noData"
	[columnsConfig]="columnConfig"
	[data]="tableData"
	[config]="tableConfig"
></sct-custom-table>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<ng-template #noWarning>
	{{'---'}}
</ng-template>
