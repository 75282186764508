<div class="row mt-4 ml-1">
	<div class="col">
		<h4>
			{{siteName}} {{'site.download_status' | translate}}
		</h4>
	</div>
	<div class="align-self-center left-panel-item mr-5" (click)="goBack()">
		<i class="fa pointer" [ngClass]="{'fa-arrow-left': true}"></i>
	</div>
</div>
<div class="p-4 gray-bg row">
	{{'site.filter_by_status' | translate}}:
	<div class="form-check form-check-inline ml-2" *ngFor="let item of statusList | keys">
		<input class="form-check-input" type="radio" name="statusFilter" [(ngModel)]="statusFilter" id="status_{{item}}" value="{{item}}" (change)="applyFilter(); fillTableData();">
		<label class="form-check-label" for="status_{{item}}">{{statusList[item]}}</label>
	</div>
</div>
<div class="p-4 bg-white border-radius-bottom">
	<sct-custom-table
		#sctCustomTable
		*ngIf="tableData.length"
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
	></sct-custom-table>
	<ng-template #noDevices>
		{{'g.no_data_to_show' | translate}}
	</ng-template>
</div>
