import { NavigationService } from './../../shared/services/navigation.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UsersService } from 'src/app/users/users.service';
import { EnterpriseService } from "../enterprise.service";
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { PermissionsService } from 'src/app/permissions/permissions.service';
import { SitesService } from 'src/app/sites/sites.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';

@Component({
  selector: 'app-enterprise-edit',
  templateUrl: './enterprise-edit.component.html',
  styleUrls: ['./enterprise-edit.component.css']
})
export class EnterpriseEditComponent implements OnInit {

	originalPermissionId: number;
	groupTypes = [];
	adminUsersList = [];
	permissionList: any = [];
	map: any = {};
	timeZonesMenu: any = [];
	showMapBox: boolean = false;
	currentUser: any;
	@Input() enterprise: any = {
		id: 0,
		name: '',
		admins:[],
		group_type: null,
		default_permission_id: null,
		update_linked: false,
		site: {}
	};
	@Input() isModal: boolean = false;
	@Output() formSubmitted = new EventEmitter<Object>();
	@Output() formCancelled = new EventEmitter<void>();

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private chRef: ChangeDetectorRef,
		private usersService: UsersService,
		private enterpriseService: EnterpriseService,
		private permissionsService: PermissionsService,
		private sitesService: SitesService,
		private notificationMessage: NotificationMessageService,
		private sideMenuService: SideMenuService,
		private commonData: CommonDataService,
		private navigation: NavigationService,
	) {
		this.map = {...this.sitesService.defaultMapLocation};
	}

	ngOnInit() {
		this.sideMenuService.hide();
		this.currentUser = this.usersService.getCurrentUser();
		if (!this.currentUser.isSCT || !this.usersService.hasAccessFunction('enterprise_management')){
			this.notificationMessage.setMessage('globalAuthMsg');
			this.router.navigate(['/'], {});
		}
		this.route.params.subscribe(
			params => {
				this.enterprise['id'] = Number(params['enterpriseId']);
			
				if(this.enterprise['id'])
					this.getEnterprise(this.enterprise['id']);
			}
		);
		this.groupTypes = this.enterpriseService.getGroupTypes();
		this.getAdmins();
		this.getPermissionsList();

		if(!this.enterprise.id) {
			this.timeZonesMenu = this.commonData['TimeZonesMenu'];
			this.showMapBox = true;
		}
		
	}

	getEnterprise(id) {
		this.enterpriseService.getEnterprise(id).subscribe(
			(data: any) => {
				this.enterprise = data;
				this.originalPermissionId = data.default_permission_id;
			}
		);
	}

	saveEnterprise() {
		this.enterpriseService.saveEnterprise(this.enterprise).subscribe(
			(data: any)=> {
				if(this.isModal)
					return this.formSubmitted.emit({enterpriseId: data});
				if(data) {
					if (data?.api_status) {
						switch(data.api_status){
							case 2:
								return this.notificationMessage.setMessage('translate|customer.customer_name_already_exist');
								break;
							case 3:
								return this.notificationMessage.setMessage('translate|site.site_name_already_exist');
								break;
						}
					}
					this.notificationMessage.setMessage('globalSuccessMsg');
					this.goBack();
				} else {
					this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
				}
			}
		);
	}

	getAdmins() {
		this.enterpriseService.getAdminUsers().subscribe(
			(data: any) => {
				this.adminUsersList = data;
				this.enterprise.admins = this.enterprise.admins.filter(id => this.adminUsersList.some(admin => admin.id == id));
				this.adminUsersList.forEach(item=> {
					item.name = item.first_name + " " + item.last_name;
				})
			});
	}

	getPermissionsList() {
		this.permissionsService.getPermissionsList('enterprise').subscribe((data) => {
			this.permissionList = data;
		});
	}

	updateSiteCoords(coords) {
		this.enterprise.site['latitude'] = coords['lat'];
		this.enterprise.site['longitude'] = coords['lng'];
		this.enterprise.site['address'] = coords['address'];
		this.enterprise.site['country_id'] = coords['country'];
		this.enterprise.site['state_id'] = coords['state'];
		this.chRef.detectChanges();
	}

	goBack() {
		if(this.isModal)
			return this.formCancelled.emit();
		
		this.navigation.goBack('/enterprise');
	}
}
