<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<div class="row mt-4">
		<form class="row col-md-12" #rejectionLog="ngForm">
			<div class="col-md-3">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{'g.get_by' | translate}}</span>
					</div>
					<select [(ngModel)]="logOptions.getBy" class="form-control inline-form-control" (change)="resetSelects()" name="getBy">
						<option *ngFor="let option of filterOptions" [value]="option.value">{{option.label | translate}}</option>
					</select>
				</div>
			</div>
			<div *ngIf="logOptions.getBy != 'date' && logOptions.getBy != 'rejection_reason'" [ngClass]="{'col-md-6': (logOptions.getBy != 'ip_address'), 'col-md-4': (logOptions.getBy == 'ip_address')}">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{'g.value' | translate}}</span>
					</div>
					<input type="text" class="form-control" [(ngModel)]="logOptions.value" name="value" *ngIf="logOptions.getBy != 'ip_address'"/>
					<input
						type="text"
						class="form-control inline-form-control"
						[(ngModel)]="logOptions.ipAddress"
						name="ipAddress"
						pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
						placeholder='0.0.0.0'
						#ipAddress="ngModel"
						*ngIf="logOptions.getBy == 'ip_address'"
					/>
				</div>
				<div class="row col-md-12 mt-2" *ngIf="logOptions.getBy == 'ip_address'">
					<span class="text-danger" *ngIf="logOptions.ipAddress && ipAddress.invalid && (ipAddress.dirty || ipAddress.touched)">* {{'g.invalid_ip' | translate}}</span>
				</div>
			</div>

			<div class="col-md-6" *ngIf="logOptions.getBy == 'rejection_reason'">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{'g.reason' | translate}}</span>
					</div>
					<select [(ngModel)]="logOptions.reason" class="form-control" name="reason">
						<option *ngFor="let key of getKeys(rejectionReasons)" [value]="key">{{rejectionReasons[key] | translate}}</option>
					</select>
				</div>
			</div>

			<div class="col-md-2" *ngIf="logOptions.getBy == 'ip_address'">
				<input type="checkbox" [(ngModel)]="logOptions.execlude" name="execlude" /> {{'g.exclude' | translate}}
			</div>
			
			<div class="col-md-1" *ngIf="logOptions.getBy != 'date'">
			</div>
			
			<div class="row col-md-7" *ngIf="logOptions.getBy == 'date'">
				<div class="col-md-6">
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text">{{'g.start_date' | translate}}</span>
						</div>
						<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp1" [(ngModel)]="fromDateFmt" bsDatepicker #d1="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}">
						<div class="input-group-append pointer" (click)="d1.toggle()">
							<span class="input-group-text"><i class="fa fa-calendar"></i></span>
						</div>
					</div>
				</div>

				<div class="col-md-6">
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text">{{'g.end_date' | translate}}</span>
						</div>
						<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp2" [(ngModel)]="toDateFmt" bsDatepicker #d2="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}">
						<div class="input-group-append pointer" (click)="d2.toggle()">
							<span class="input-group-text"><i class="fa fa-calendar"></i></span>
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-2">
				<button class="btn btn-success mr-1" (click)="getRejectedDeviceConnections()" [disabled]="checkDisableSubmitButton()">{{'g.get_logs' | translate}}</button>
			</div>
		</form>

	</div>

	<div class="my-4">
		 <sct-custom-table
			*ngIf="logInfo.length; else noData"
			[data]="logInfo"
			[config]="tableConfig"
			[columnsConfig]="columnConfig">
		 </sct-custom-table>
	</div>
</div>

<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>