import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../users/users.service';
import { NotificationMessageService } from '../shared/notification-message/notification-message.service';
import { SideMenuService } from '../shared/side-menu/side-menu.service';
import { PermissionsService } from './permissions.service';
import { ColumnsConfig, TableConfig, TableData } from '../shared/custom-table/custom-table-interface';

@Component({
	selector: 'app-permissions',
	templateUrl: './permissions.component.html',
	styleUrls: ['./permissions.component.css']
})
export class PermissionsComponent implements OnInit, AfterViewInit {
	@ViewChild('addPermissionModal') addPermissionModal;
	@ViewChild('linkedItemsModal') linkedItemsModal;
	@ViewChild("deleteModal") deleteModal;

	// table data
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: false,
		draggable: false,
		fitScreen: true
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'name', name: this.translateService.instant('g.name'), type: "string"},
		{ key: 'actions', name: this.translateService.instant('g.actions'), type: 'dropdown'},
	];

	permissions: any[] = [];
	permissionType: any;
	permissionTypeArr: any = [
		'user',
		'enterprise'
	];
	linkedItems: any = [];
	newPermission: any;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private translateService: TranslateService,
		private usersService: UsersService,
		private sideMenuService: SideMenuService,
		private notificationMessageService: NotificationMessageService,
		private permissionsService: PermissionsService
	) { }

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('permissions_management')){
			return this.router.navigate(['/'], {}).then(() => {
				this.notificationMessageService.setMessage('globalAuthMsg', {clearOnXTimeNavigate: 1});
			});
		}
		this.sideMenuService.hide();
		this.route.params.subscribe((params: any) => {
			if(!this.permissionTypeArr.includes(params.permissionType)) {
				return this.router.navigateByUrl('/404');
			}
			this.permissionType = params.permissionType;
			this.getPermissions();
		});
	}
	ngAfterViewInit() {
		this.deleteModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			this.deletePermission(result[1]);
		});
	}

	getPermissions() {
		this.permissionsService.getPermissionsList(this.permissionType).subscribe((data: any) => {
			this.permissions = data;
			this.tableData = [];

			for(const permission of data) {
				this.tableData.push({
					name: {value: permission.name},
					actions: {value: null, options: [
						{text: this.translateService.instant('g.edit'), action: ()=> {this.editPermission(permission.id)}},
						{text: this.translateService.instant('g.delete'), action: ()=> {this.deleteModal.passData = permission.id; this.deleteModal.show();}}
					]}
				})
			}
		});
	}

	editPermission(permissionId) {
		this.router.navigate(['edit', permissionId], {relativeTo: this.route});
	}

	deletePermission(permissionId) {
		this.permissionsService.deletePermission({permissionId, type: this.permissionType}).subscribe((response: any) => {
			if(!response.status) {
				return this.notificationMessageService.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
			}
			if(!response.linkedItems) {
				this.notificationMessageService.setMessage('globalSuccessMsg', {numberOfNavigate: 1});
				return this.getPermissions();
			}
			this.linkedItems = response.linkedItems;
			this.linkedItemsModal.show();
		});
	}

	showNewPermissionModal() {
		this.newPermission = {
			name: '',
			id: 1
		};
		this.addPermissionModal.show();
	}

	closeAddPermissionModal() {
		this.addPermissionModal.hide();
	}

	addNewPermission() {
		this.permissionsService.addPermission({
			id: this.newPermission.id,
			name: this.newPermission.name,
			type: this.permissionType
		}).subscribe((response: any) => {
			this.closeAddPermissionModal();
			if(!response.newId) {
				return this.notificationMessageService.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
			}
			this.editPermission(response.newId);
		});
	}
}
